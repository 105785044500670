import React, { useEffect, useState } from "react";
import Style from "./addStaffForm.module.scss";
import { SelectWithIcons } from "@wac-ui-dashboard/wac_component_library";

const AddStaffForm = ({ formik, optionsWithIcons, staffList }) => {
  const [staffListFormated, setStaffListFormated] = useState(false);
  useEffect(() => {
    var temp = [];
    staffList?.data?.map((element) => {
      temp.push({
        value: element._id,
        label: element.value.name,
        icon: (
          <img
            src={element.value.image_url}
            alt={""}
            width={"500"}
            height={"600"}
          ></img>
        ),
      });
      return element;
    });
    setStaffListFormated(temp);
  }, [staffList]);

  return (
    <div className={Style.root}>
      <div className="col-12 pro-mb-4">
        <div className="row">
          <div className="input-wrap pro-mb-4">
            <SelectWithIcons
              multiSelect={true}
              extraClassName={`pro-input lg ${
                formik.errors.selectedStaff &&
                formik.touched.selectedStaff &&
                " error"
              }`}
              options={staffListFormated}
              name={"selectedStaff"}
              onChange={(data) => {
                let staffIDs = [];
                data.map((data) => {
                  staffIDs.push(data.value);
                  return data;
                });
                formik.setFieldValue("selectedStaff", staffIDs?.join(","));
              }}
            ></SelectWithIcons>

            {formik.errors.selectedStaff && (
              <span className="error-text">{formik.errors.selectedStaff}</span>
            )}
          </div>

          {/* <div className="col-12 pro-mb-4">
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Branch Admin
              </label>
              <input
                type="text"
                className={`pro-input lg ${
                  formik.errors.branch_admin && formik.touched.branch_admin && " error"
                }`}
                id="branch_admin"
                name="branch_admin"
                {...formik.getFieldProps("branch_admin")}
              />
              {formik.touched.branch_admin && formik.errors.branch_admin && (
                <span className="error-text">{formik.errors.branch_admin}</span>
              )}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AddStaffForm;
