import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showEditOffcanvas: false,
  profileOverviewData: [],
  showShareModal:false,
  prevTab: {},
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    saveUserTab: (state, action) => {
      state.prevTab[action.payload.id] = action.payload.path;
    },
  },
});

export const { updateConfig, saveUserTab } = profileSlice.actions;

export default profileSlice.reducer;
