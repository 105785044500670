import React from "react";
import Style from "./createPackage.module.scss";
import { IconText, Image } from "@wac-ui-dashboard/wac_component_library";
import { useSelector } from "react-redux";

const CreatePackage = ({
  imageFileInputRef,
  imagePreview,

  handlePackageImage,
  formik,
}) => {
  const packageState = useSelector((state) => state.package);
  const adonsState = useSelector((state) => state.adons);

  const limitStrLength = (text, max_length) => {
    if (text.length > max_length - 3) {
      return text.substring(0, max_length).trimEnd() + "...";
    } else {
      return text;
    }
  };

  return (
    <div className={Style.root}>
      <div className="col-12 pro-mb-4">
        <div className="row">
          <div className={`${
              packageState?.formType === "package"
                ? "col-lg-4 pro-mb-4"
                : "col-lg-6 pro-mb-4"
            }`}>
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Name
              </label>
              <input
                disabled={
                  packageState?.selectedItemsDetails !== null
                    ? true
                    : adonsState?.selectedItemsDetails != null
                    ? true
                    : false
                }
                type="text"
                className={`pro-input lg ${
                  formik.errors.name && formik.touched.name && " error"
                }`}
                placeholder="Name"
                id="name"
                name="name"
                {...formik.getFieldProps("name")}
              />
              {formik.touched.name && formik.errors.name && (
                <span className="error-text">{formik.errors.name}</span>
              )}
            </div>
          </div>
          <div
            className={`${
              packageState?.formType === "package"
                ? "col-lg-4 pro-mb-4"
                : "col-lg-6 pro-mb-4"
            }`}
          >
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Price
              </label>
              <input
                placeholder="Price"
                type="number"
                className={`pro-input lg ${
                  formik.errors.price && formik.touched.price && " error"
                }`}
                id="price"
                name="price"
                {...formik.getFieldProps("price")}
              />
              {formik.touched.price && formik.errors.price && (
                <span className="error-text">{formik.errors.price}</span>
              )}
            </div>
          </div>
          {packageState?.formType === "package" ? (
            <div className="col-lg-4 pro-mb-4">
              <div className="input-wrap">
                <label
                  htmlFor=""
                  className="pro-font-sm pro-mb-1 pro-fw-medium"
                >
                  Duration (Months)
                </label>
                <input
                  disabled={
                    packageState?.selectedItemsDetails !== null
                      ? true
                      : adonsState?.selectedItemsDetails != null
                      ? true
                      : false
                  }
                  min={0}
                  type="number"
                  placeholder="In Months"
                  className={`pro-input lg ${
                    formik.errors.duration &&
                    formik.touched.duration &&
                    " error"
                  }`}
                  id="duration"
                  name="duration"
                  {...formik.getFieldProps("duration")}
                />
                {formik.touched.duration && formik.errors.duration && (
                  <span className="error-text">{formik.errors.duration}</span>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className={`col-12`}>
            <div className="input-wrap pro-mb-4">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Offer Price
              </label>
              <input
                // disabled={
                //   packageState?.selectedItemsDetails !== null ? true : false
                // }
                type="number"
                placeholder={
                  formik.values.price === ""
                    ? "Enter an offer price"
                    : `Enter an offer price below ${formik.values.price}`
                }
                className={`pro-input lg ${
                  formik.errors.offer_price &&
                  formik.touched.offer_price &&
                  " error"
                }`}
                id="offer_price"
                name="offer_price"
                {...formik.getFieldProps("offer_price")}
              />
              {formik.touched.offer_price && formik.errors.offer_price && (
                <span className="error-text">{formik.errors.offer_price}</span>
              )}
            </div>

            <div className="input-wrap pro-mb-4">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Description
              </label>
              <textarea
                rows={6}
                type="text"
                className={`pro-input lg ${
                  formik.errors.description &&
                  formik.touched.description &&
                  " error"
                }`}
                id="description"
                name="description"
                {...formik.getFieldProps("description")}
              />
              {formik.touched.description && formik.errors.description && (
                <span className="error-text">{formik.errors.description}</span>
              )}
            </div>
            {packageState?.formType === "package" ? (
              <div className="input-wrap pro-mb-4">
                <div className="pro-check-box">
                  <input
                    type="checkbox"
                    className={"pro-check"}
                    name="isChecked"
                    checked={Boolean(formik.values?.isChecked) ?? false}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "isChecked",
                        !Boolean(formik.values?.isChecked) ?? false
                      )
                    }
                  />
                  <label htmlFor="checkbox" className="pro-check-label">
                    Recommend this package
                  </label>
                  {formik.touched.isChecked && formik.errors.isChecked && (
                    <span className="error-text">
                      {formik.errors.isChecked}
                    </span>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="input-wrap">
            <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
              Image
            </label>
            <div className="input-drag">
              <input
                type="file"
                accept=".jpeg, .jpg , .png"
                ref={imageFileInputRef}
                className={`pro-input ${
                  formik.errors.image && formik.touched.image && " error"
                }`}
                id="image"
                onBlur={formik.handleBlur("image")}
                onChange={(e) => handlePackageImage(e)}
              />
              <span className="input-drag-box">
                <IconText
                  title={
                    formik?.values?.image?.name !== undefined
                      ? limitStrLength(formik?.values?.image?.name, 30)
                      : packageState?.selectedItemsDetails !== null
                      ? packageState?.selectedItemsDetails?.thumbnail
                      : adonsState.selectedItemsDetails != null
                      ? adonsState?.selectedItemsDetails?.thumbnail
                      : `Drop files to attach or browse`
                  }
                />
              </span>
              {formik.touched.image && formik.errors.image && (
                <span className="error-text">{formik.errors.image}</span>
              )}
            </div>
            <div className={`col-2 pro-my-2`}>
              <div className={`${Style.root_image_inner}`}>
                <Image
                  width={100}
                  height={100}
                  src={imagePreview}
                  alt={`image - 01`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePackage;
