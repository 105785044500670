import { useFormik } from "formik";
import * as Yup from "yup";
import { useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateConfig } from "../../../../store/slices/Settings/staffSlice";
import { emailRegExp, phoneRegExp } from "../../../../utils/functions/table";
import {
  useGetCreateStaffFormDataQuery,
  useCreateStaffMutation,
  useUpdateStaffDataByIDMutation,
} from "../../../../store/queries/settings";
import countryData from "../../../../utils/components/countryCode";
import { useSelector } from "react-redux";
import { useGetMemberCreationFormDataQuery } from "../../../../store/queries/members";

const useStaffCreationForm = ({ refetch }) => {
  const dispatch = useDispatch();

  const profilefileInputRef = useRef(null);

  const { selectedItemsDetails } = useSelector((state) => state.staff);
  const [imagePreview, setImagePreview] = useState(
    selectedItemsDetails !== "" ? selectedItemsDetails?.image_url : ""
  );
  const { data: genderOptionsData = {} } = useGetMemberCreationFormDataQuery(
    {}
  );
  const { data: optionsData = {}, isSuccess: isOptionDataSuccess } =
    useGetCreateStaffFormDataQuery({});

  const { data: branchOptionsData = {} } = useGetMemberCreationFormDataQuery(
    {}
  );

  const [createStaff, { isLoading }] = useCreateStaffMutation();
  const [updateStaff] = useUpdateStaffDataByIDMutation();

  const cuntryCodeOptions = useMemo(
    () =>
      countryData?.map((opt) => {
        return { value: opt.value, label: opt.label, code: opt.code };
      }),
    []
  );

  let YupValidationObject = {
    first_name: Yup.string()
      .min(2, "The first name must be at least 2 characters")
      .max(20, "max 20 characters allowed")
      .matches(/^[a-zA-Z\s]+$/, "Invalid name Format")
      .required("*Required"),
    last_name: Yup.string()
      .max(20, "max 20 characters allowed")
      .required("*Required"),
    gender: Yup.string().required("*Required"),
    phone_number: Yup.string()
      .matches(phoneRegExp, "Please enter a valid phone number")
      .min(10, "Phone number must be 10 digits")
      .max(10, "Phone number must be 10 digits")
      .required("*Required"),
    email: Yup.string()
      .matches(emailRegExp, "Please enter a valid email")
      .required("*Required"),
    role_id: Yup.object().required("*Required"),
  };

  if (selectedItemsDetails === "") {
    YupValidationObject.profile = Yup.string().required("*Required");
  }

  let createStaffVal = Yup.object(YupValidationObject);

  const formik = useFormik({
    initialValues: {
      first_name:
        selectedItemsDetails !== "" ? selectedItemsDetails?.first_name : "",

      last_name:
        selectedItemsDetails !== "" ? selectedItemsDetails?.last_name : "",
      gender: selectedItemsDetails !== "" ? selectedItemsDetails?.gender : "",
      phone_number:
        selectedItemsDetails !== "" ? selectedItemsDetails?.phone_number : "",

      email: selectedItemsDetails !== "" ? selectedItemsDetails?.email : "",

      role_id:
        selectedItemsDetails !== ""
          ? {
              _id: selectedItemsDetails?.role_id,
              name: selectedItemsDetails?.role,
            }
          : "",

      profile:
        selectedItemsDetails !== "" ? selectedItemsDetails?.image_url : "",

      branch: selectedItemsDetails !== "" ? selectedItemsDetails?.branch : "",
      country_code:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.country_code
          : "+91",
    },

    validationSchema: createStaffVal,

    onSubmit: (values, { resetForm }) => {
      try {
        let obj = {
          first_name: values.first_name,
          last_name: values.last_name,
          phone_number: values.phone_number,
          email: values.email,
          role_id: values.role_id?._id,
          gender: values.gender,
          country_code: values.country_code,
        };

        if (values.profile?.name) {
          obj.photo = values.profile;
        }

        if (values.role_id?.name !== "CMS Manager" && values.role_id) {
          obj.branch_id = values.branch?._id;
        }
        if (values.branch?._id === null || values.branch?._id === undefined) {
          delete obj.branch_id;
        }

        let formData = new FormData();

        Object.keys(obj).forEach((key) => {
          return formData.append(key, obj[key]);
        });

        if (selectedItemsDetails !== "") {
          formData.append("_method", "PUT");
          formData.append("user_id", selectedItemsDetails?._id);
        }

        if (selectedItemsDetails !== "") {
          updateStaff(formData).then((response) => {
            if (response?.data?.status_code === 200) {
              resetForm();
              refetch?.();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              toast.success("Staff details has been updated successfully.");
            } else if (response?.data?.status_code === 400) {
              formik.setErrors(response?.data?.message);
            } else toast.error(response?.data?.message);
          });
        } else {
          createStaff(formData).then((response) => {
            if (response?.data?.status_code === 200) {
              resetForm();
              refetch?.();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );

              toast.success("Successfully submitted");
            } else if (response?.data?.status_code === 400) {
              formik.setErrors(response?.data?.message);
            } else toast.error(response?.data?.message);
          });
        }
      } catch (error) {
        toast.error("Something went wrong");
      }
    },
  });

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  const handleProfileImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik.setFieldValue("profile", e?.target?.files?.[0]);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setImagePreview(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
      formik.setFieldError(
        "profile",
        "The image must be less than 5MB in size."
      );
    }
  };

  return {
    formik,
    optionsData,
    branchOptionsData,
    genderOptionsData,
    isLoading,
    imagePreview,
    isOptionDataSuccess,
    selectedItemsDetails,
    cuntryCodeOptions,
    profilefileInputRef,
    handleProfileImage,
    handleCloseModal,
  };
};

export default useStaffCreationForm;
