import React, { useState } from "react";
import Style from "./simpleImageSlider.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Image } from "@wac-ui-dashboard/wac_component_library";

/**import "swiper/css" when using this component */
export const SimpleImageSlider = ({
  data,
  extraClassName,
  width,
  height,
  propStyle,
  isPremium,
  isLocked,
  status,
  handleClick,
}) => {
  const [counter, setCounter] = useState(1);
  return (
    <div className={`${Style.root} ${extraClassName ?? ""}`}>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        onSlideChange={(swiper) => {
          setCounter(swiper?.activeIndex + 1);
        }}
        modules={[Navigation]}
        navigation={!isLocked}
      >
        {data &&
          data.map((item) => {
            return (
              <SwiperSlide key={item?.id} onClick={handleClick}>
                {item?.isPrimary && !item?.isRejected ? (
                  <span
                    className={`${Style.isPrimary} pro-py-1 pro-px-3 pro-font-sm pro-fw-bold`}
                  >
                    Primary Image
                  </span>
                ) : item?.isRejected ? (
                  <span
                    className={`${Style.isRejected} pro-py-1 pro-px-3 pro-font-sm pro-fw-bold`}
                  >
                    Rejected Image
                  </span>
                ) : (
                  <></>
                )}

                <Image
                  src={item?.image || ""}
                  width={width || ""}
                  height={height || ""}
                />
              </SwiperSlide>
            );
          })}
      </Swiper>
      <div className={Style.package_container}>
        {isPremium && (
          <span
            className={`${Style.isPremium} ${
              isPremium === "Platinum" ? Style.large : ""
            } pro-py-1 pro-px-2 pro-font-sm`}
            style={{ fontVariationSettings: ` 'FILL' 1` }}
          >
            <span className="material-symbols-outlined">star</span>
            <span>{isPremium}</span>
          </span>
        )}

        {status === 2 && (
          <span
            className={`${Style.isBlocked} pro-py-1 pro-px-3 pro-font-sm`}
            style={{ fontVariationSettings: ` 'FILL' 1` }}
          >
            <span>Blocked</span>
          </span>
        )}

        {status === 4 && (
          <span
            className={`${Style.isInactive} pro-py-1 pro-px-3 pro-font-sm`}
            style={{ fontVariationSettings: ` 'FILL' 1` }}
          >
            <span>Inactive</span>
          </span>
        )}

        {status === 1 && (
          <span
            className={`${Style.isActive} pro-py-1 pro-px-3 pro-font-sm`}
            style={{ fontVariationSettings: ` 'FILL' 1` }}
          >
            <span>Active</span>
          </span>
        )}

        {status === 3 && (
          <span
            className={`${Style.isDeleted} pro-py-1 pro-px-3 pro-font-sm`}
            style={{ fontVariationSettings: ` 'FILL' 1` }}
          >
            <span>Deleted</span>
          </span>
        )}

        {status === 0 && (
          <span
            className={`${Style.isPending} pro-py-1 pro-px-3 pro-font-sm`}
            style={{ fontVariationSettings: ` 'FILL' 1` }}
          >
            <span>Pending</span>
          </span>
        )}
        {status === 5 && (
          <span
            className={`${Style.isExpired} pro-py-1 pro-px-3 pro-font-sm`}
            style={{ fontVariationSettings: ` 'FILL' 1` }}
          >
            <span>Expired</span>
          </span>
        )}
      </div>
      {!isLocked && data && (
        <div className={Style.counter}>
          <span>
            {counter}/{data?.length}
          </span>
        </div>
      )}
    </div>
  );
};
