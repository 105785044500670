import Assets from "../../../assets/Assets";
import Style from "./adminProfileCard.module.scss";

const AdminProfileCard = ({
  userData,
  profilefileInputRef,
  handleProfileImage,
  imagePreview,
}) => {
  const handleClick = () => {
    profilefileInputRef.current.click();
  };

  return (
    <div className={Style.root}>
      <div className={Style.img_wrap}>
        <figure className="pro-mb-0">
          {imagePreview ? (
            <img src={imagePreview} alt="staff_image" />
          ) : imagePreview === null && userData?.gender === null ? (
            <img src={Assets.ACCOUNT_CIRCLE} alt="staff_image" />
          ) : (
            <img
              src={
                userData?.gender === 2
                  ? Assets.FEMALEPROFILE
                  : Assets.MALEPROFILE
              }
              alt="staff_image"
            />
          )}
        </figure>
        {imagePreview !== null  ? (
          <span className={Style.editProfile} onClick={handleClick}>
            {" "}
            <span class="material-symbols-outlined">edit</span>
          </span>
        ) : (
          <></>
        )}
      </div>
      <div className="pro-ps-4 pro-pt-1 pro-pb-2 pro-d-flex pro-flex-column">
        {userData?.first_name && (
          <h4 className="pro-ttl h4 pro-mb-1">
            {(userData?.first_name ?? " ") + " " + (userData?.last_name ?? "")}
          </h4>
        )}
        {<p className="pro-mb-2">{userData?.role}</p>}
        <ul
          className={`pro-d-flex pro-align-center ${
            userData?.branch?.name ? Style.list : ""
          }`}
        >
          <li>{userData?.profile_id ?? ""}</li>
          <li>{userData?.branch?.name ?? ""}</li>
        </ul>
      </div>

      <div className="input-wrap pro-mb-4">
        <div className="input-drag">
          <input
            type="file"
            accept=".jpeg, .jpg , .png"
            ref={profilefileInputRef}
            className={`pro-input ${" error"}`}
            style={{ display: "none" }}
            id="profile"
            name="profile"
            onChange={(e) => handleProfileImage(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminProfileCard;
