import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import CreatePackage from "./Components/CreatePackage";
import PackageRule from "./Components/PackageRule";
import usePackageCreationForm from "./usePackageCreationForm";
import Style from "./packageCreationForm.module.scss";

const PackageCreationForm = ({ refetch,hasUpdatePermission }) => {
  const {
    rules,
    formik,
    imagePreview,
    handlePackageImage,
    handleCloseModal,
    imageFileInputRef,
    fieldsSetCount,
    packageState,
    adonsState,
    setFieldsSetCount,
  } = usePackageCreationForm(refetch);

  return (
    <div className={`pro-py-4`}>
      <div className={`${Style.root} pro-px-4`}>
        <CreatePackage
          imagePreview={imagePreview}
          handlePackageImage={handlePackageImage}
          formik={formik}
          imageFileInputRef={imageFileInputRef}
        />

        <PackageRule
          formik={formik}
          ruleOptions={rules}
          fieldsSetCount={fieldsSetCount}
          setFieldsSetCount={setFieldsSetCount}
        />

        <div
          className={`col-12 pro-mt-4 pro-pt-2 pro-d-flex pro-justify-end ${Style.footer}`}
        >
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={() => handleCloseModal()}
          >
            Cancel
          </Button>

          <Button
            onClick={formik.handleSubmit}
            className={"pro-btn-primary lg pro-ms-3"}
            disabled={hasUpdatePermission === 1 ? false : true}
            type="submit"
          >
            {packageState.selectedItemsDetails !== null
              ? "Update"
              : adonsState?.selectedItemsDetails !== null
              ? "Update"
              : "Create"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PackageCreationForm;
