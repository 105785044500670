import { useSelector } from "react-redux";

const useChartData = ({ chartType, type }) => {
  const state = useSelector((state) => state.global);

  const chartData = [
    {
      title: type === 1 ? "User Count" : "Payment Received",
      labels: state?.dashboardChartData[chartType]?.data?.branches,
      datasets: [
        {
          label: type === 1 ? "User Count" : "Payment Received",
          data: state?.dashboardChartData[chartType]?.data?.users_data?.map(
            (user) => user?.user_count
          ),
          backgroundColor: "#660047",
        },
      ],
    },
  ];

  return {
    chartData,
    state,
  };
};

export default useChartData;
