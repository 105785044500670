import { getAxiosInstance } from "../../../api";

export const updateStatus = async (body) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/admin/temporary-user/status-change`, body, {
        headers: {
          'X-HTTP-Method-Override': 'PUT'
        }
      })
      return response;
    } catch (error) {
      return error.response.data;
    }
  };