import React from "react";
import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  OffCanvasLayout,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import useMagazine from "./useMagazine";
import OrderColumn from "../../Global/OrderColumn";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../Global/EmptyData";
import Style from "./magazine.module.scss";
import { ProfileCard } from "../UsersList/ProfileCard";
import MagazineForm from "./MagazineForm";
import DatePickerRsuite from "../../Global/CustomDateRangePicker";

const Magazine = ({ dashboard = false }) => {
  const {
    magazine,
    magazineViewData,
    handleEditAction,
    showCreateModal,
    hasCreatePermission,
    isFetching,
    isLoading,
    showEditModal,
    tableFields,
    magazineState,
    currentPage,
    paginationOptions,
    imageData,
    actionOptions,
    hasExportPermission,
    showImageModal,
    hasEditPermission,
    hasUpdatePermission,
    optionsData,
    handleDateRangeChange,
    closeImageModal,
    handleDashboardRedirect,
    handleActionChange,
    refetch,
    handleSort,
    handleSearch,
    handleCreateClick,
    closeModal,
    getRow,
    handleEditClick,
    handlePagination,
    closeEditModal,
    handleDateChange,
    handlePageSize,
    updateMagazineFields,
    handleClearClick,
    date,
  } = useMagazine(dashboard);

  return (
    <>
      {!dashboard ? (
        <>
          <HeadingGroup
            title={"Magazine"}
            buttonTitle={hasCreatePermission === 1 ? "Add new" : ""}
            className={`pro-mb-4`}
            handleClick={handleCreateClick}
          />
        </>
      ) : (
        <></>
      )}

      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        {!dashboard ? (
          <SearchFilters
            //showDateRange
            dropDownFilter={
              <div className="col-auto">
                <DatePickerRsuite
                  defaultDateRange={date}
                  handleDateRangeChange={handleDateRangeChange}
                />
              </div>
            }
            onDateChange={handleDateChange}
            onSearchInput={handleSearch}
            showActions={false}
            handleActionClick={handleEditClick}
            loading={isLoading}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
            //showClearFilters
            handleClear={handleClearClick}
            initialDateRange={date}
            searchInputProps={{ value: magazineState?.search }}
            actionOptions={actionOptions?.filter((item) =>
              hasExportPermission !== 1 ? item.value === 0 : true
            )}
            onActionOptionChange={handleActionChange}
          />
        ) : (
          <></>
        )}

        {magazine?.data?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <div
            onClick={dashboard ? () => handleDashboardRedirect() : () => {}}
            className={!dashboard ? "pro-pt-3" : Style.dashboardTable}
          >
            <Table
              data={magazine?.data?.data?.data || []}
              uniqueID={"_id"}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              showCheckBox={hasEditPermission === 1 ? true : false}
              //fields={adons?.data?.fields}
              deletable={false}
              handleEdit={handleEditAction}
              clear={magazineState.clearSelection}
              multiSelect={false}
              assignable={false}
              fields={tableFields}
              SortIcon={<FaSort />}
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={magazineState?.currentPageSize}
              extraClasssName={"dashboard_no_padding"}
            />
          </div>
        )}

        {magazine?.data?.data?.data?.length > 0 && !dashboard && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={magazine?.data?.data?.last_page}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === magazineState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <OffCanvasLayout
          show={showCreateModal}
          handleClose={closeModal}
          title={"Add Magazine"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <MagazineForm
            refetch={refetch}
            isStickyFooter
            optionsData={optionsData}
            closeModal={closeModal}
            magazineViewData={magazineViewData}
            hasUpdatePermission={hasUpdatePermission}
          />
        </OffCanvasLayout>
        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={magazine?.data?.fields}
              moduleId={magazine?.data?.module_id}
              updateData={updateMagazineFields}
            />
          </div>
        </ModalLayout>
        <ModalLayout show={showImageModal} handleClose={closeImageModal}>
          <ProfileCard data={imageData} handleClose={closeImageModal} />
        </ModalLayout>
      </div>
    </>
  );
};

export default Magazine;
