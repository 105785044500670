import { getAxiosInstance } from "../../../../api";

export const blockUser = async (formData) => {
  const api = await getAxiosInstance();

  try {
    const response = await api.post("admin/block-user/create", formData);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const unBlockUser = async (formData) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("admin/block-user/update", formData);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getUserDetails = async ({ search }) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`admin/payments/users?search=${search}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
