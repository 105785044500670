import {
    HeadingGroup,
    ModalLayout,
    OffCanvasLayout,
    Pagination,
    SearchFilters,
    Table,
  } from "@wac-ui-dashboard/wac_component_library";
  import { FaSort } from "react-icons/fa";
  import OrderColumn from "../../Global/OrderColumn";
  import MemberCreationForm from "../Members/MemberCreationForm";
  import AvatarCardList from "../../Global/AvatarCardList";
  import EmptyData from "../../Global/EmptyData";
  import Style from "./incompletereg.module.scss";
  import { useEffect } from "react";
  import { useRef } from "react";
  import { useState } from "react";
  import DatePickerRsuite from "../../Global/CustomDateRangePicker";
import useIncompletereg from "./useIncompletereg";
  
  const IncompleteRegistration = ({ dashboard = false }) => {
    const {
      filters,
      membersList,
      membersState,
      offCanvasRef,
      currentPage,
      activeFilter,
      showCreateModal,
      showEditModal,
      paginationOptions,
      isFetching,
      isLoading,
      selectedUser,
      staffData,
      isUpdateProfile,
      actionOptions,
      isUpdateDataSuccess,
      handleDateRangeChange,
      handleActionChange,
      handleDashboardRedirect,
      handleEditClick,
      handleEditModal,
      handlePagination,
      handlePageSize,
      handleFilter,
      handleDateChange,
      handleSort,
      handleSearch,
      closeModal,
      closeEditModal,
      getRow,
      updateMemberFields,
      refetch,
      handleClearClick,
      date,
      showTooltip,
      tooltipPosition,
    } = useIncompletereg({ dashboard });
  
    const avatarListRef = useRef(null);
    const [avatarListHeight, setAvatarListHeight] = useState();
    useEffect(() => {
      const height = avatarListRef?.current?.getBoundingClientRect()?.height;
      setAvatarListHeight(height);
    }, [showTooltip]);
    return (
      <>
        {!dashboard ? (
          <HeadingGroup
            title={"Incomplete Registrations"}
            className={`pro-mb-4`}
          />
        ) : (
          <></>
        )}
        <div className={`col-auto pro-pt-3`}>
          {!dashboard ? (
            <SearchFilters
              data={filters}
              //showDateRange
              dropDownFilter={
                <>
                  <div className="col-auto">
                    <DatePickerRsuite
                      defaultDateRange={date}
                      handleDateRangeChange={handleDateRangeChange}
                    />
                  </div>
                </>
              }
              activeFilter={activeFilter}
              onDateChange={handleDateChange}
              handleButtonGroupChange={handleFilter}
              onSearchInput={handleSearch}
              loading={isLoading}
              showActions={false}
              handleActionClick={handleEditModal}
              // //showClearFilters
              handleClear={handleClearClick}
              initialDateRange={date}
              searchInputProps={{ value: membersState?.search }}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              actionOptions={actionOptions?.filter((item) => item.value === 0)}
              onActionOptionChange={handleActionChange}
            />
          ) : (
            <></>
          )}
  
          {membersList?.data?.data?.data?.length === 0 ? (
            <EmptyData />
          ) : (
            <div
              onClick={
                dashboard ? () => handleDashboardRedirect(activeFilter) : () => {}
              }
              className={
                !dashboard ? "pro-pt-3 pro-pb-6" : `${Style.dashboardTable}`
              }
            >
              <Table
                data={membersList?.data?.data?.data || []}
                isSelectBox={dashboard ? false : true}
                uniqueID={"temporary_user_id"}
                fields={membersList?.data?.fields}
                multiSelect={false}
                showCheckBox={ !dashboard ? true : false}
                clear={membersState.clearSelection}
                SortIcon={<FaSort />}
                editIcon={<span className="material-symbols-outlined">edit</span>}
                deleteIcon={
                  <span className="material-symbols-outlined">delete</span>
                }
                handleSort={handleSort}
                getRow={getRow}
                loading={isFetching}
                perpage={membersState?.currentPageSize}
                assignable={true}
                editable={false}
                assignText={"Create"}
                handleAssign={handleEditClick}
                deletable={false}
                extraClasssName={"dashboard_no_padding"}
                propStyle={{
                  selectBox_table: Style.selectBox_table,
                }}
              />
            </div>
          )}
  
          {membersList?.data?.data?.data?.length > 0 && !dashboard && (
            <Pagination
              currentPage={currentPage}
              totalPageCount={Math.ceil(
                membersList.data.data.total / membersState.currentPageSize
              )}
              //  totalPageCount={membersList?.data?.data?.last_page}
              onPageChange={handlePagination}
              defaultValue={paginationOptions?.filter(
                (item) => item.value === membersState.currentPageSize
              )}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          )}
  
          {!dashboard ? (
            <>
              {" "}
              <OffCanvasLayout
                show={showCreateModal}
                handleClose={closeModal}
                title={`${
                    isUpdateProfile ? "Update Member Details" : "Member registration"
                }`}
                closeIcon={
                  <span className="material-symbols-outlined"> close </span>
                }
                ref={offCanvasRef}
              >
                <MemberCreationForm
                  selectedUser={selectedUser}
                  isUpdateDataSuccess={isUpdateDataSuccess}
                  refetchincom={refetch}
                />
              </OffCanvasLayout>
              <ModalLayout show={showEditModal} handleClose={closeEditModal}>
                <div className="pro-m-5">
                  <OrderColumn
                    title={"Choose which columns you see"}
                    refetch={refetch}
                    tableFields={membersList?.data?.fields}
                    moduleId={membersList?.data?.module_id}
                    updateData={updateMemberFields}
                  />
                </div>
              </ModalLayout>
              <div
                className={`
                  ${Style.avatar_list_container}  
                  ${showTooltip ? Style.active : ""} 
                  ${
                    tooltipPosition?.placementY === "top"
                      ? Style.top
                      : Style.bottom
                  }
                `}
                ref={avatarListRef}
                style={{
                  "--top": tooltipPosition.y,
                  "--left": tooltipPosition.x,
                  "--card-height": avatarListHeight,
                }}
              >
                <AvatarCardList staffData={staffData} />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  };
  
  export default IncompleteRegistration;
  