import Style from "./avatarCardList.module.scss";
import AvatarCard from "../AvatarCard";

const AvatarCardList = ({ staffData }) => {
  return (
    <div className={`pro-d-flex pro-flex-column pro-p-5 ${Style.root}`}>
      <AvatarCard
        name={staffData?.name}
        designation={staffData?.role?.name}
        image={staffData?.image_url}
      />
      <div className={Style.list_wrap}>
        <p>{staffData?.branch?.name}</p>
        <p>
          <a href={`tel:${staffData?.phone_number}`}>
            {staffData?.phone_number}
          </a>
        </p>
        <p>
          <a href={`mailto:${staffData?.email}`}>{staffData?.email}</a>
        </p>
      </div>
    </div>
  );
};

export default AvatarCardList;
