import { getAxiosInstance } from "../../../../api";

export const deleteMember = async (obj) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("admin/members/profile/settings/delete", obj);
    return response;
  } catch (error) {
    return error.response.data;
  }
};