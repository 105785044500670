import Style from "./ProfileCard.module.scss";
import useProfileCard from "./useProfileCard";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import { useState } from "react";
import Select from "react-select";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";


export function ProfileCard({
  data,
  activeID,
  refetch,
  verificationState,
  hasUploadViewPermission,
  setShowImageVerificationModal,
}) {

  const [imageLoaded, setImageLoaded] = useState(false);

  const {
    showReasonSelect,
    activeImageIndex,
    activeReason,
    reasons,
    reasonError,
    sortedArray,
    handleReject,
    cancelRejectClick,
    handleThumbClick,
    handleReasonChange,
    handleApprove,
    handleRejectSubmit,
  } = useProfileCard({
    data,
    activeID,
    refetch,
    setShowImageVerificationModal,
  });

 

  return (
    <div className={`${Style.root} pro-p-2`}>
      <TransformWrapper>
        {({ zoomIn, zoomOut }) => (
          <>
            <div className={Style.action_icon}>
              <button
                className={`${Style.icon} pro-btn pro-p-0 pro-mb-1`}
                onClick={() => zoomIn()}
              >
                <span className="material-symbols-outlined">zoom_in</span>
              </button>
              <button
                className={`${Style.icon} pro-btn pro-p-0`}
                onClick={() => zoomOut()}
              >
                <span className="material-symbols-outlined">zoom_out</span>
              </button>
            </div>
            <TransformComponent>
              <div className={`${Style.root_image} pro-mb-1`}>
                
                  <div className={Style.root_figure}>
                    {/* {verificationState?.profileDataStatus !== "success" && !sortedArray[activeImageIndex]?.url ? (
                      <div className={`shimmer pro-w-100 ${Style.blink}`}></div>
                    ) : ( */}
                      <figure className={`${Style.image}`}>
                        <img
                          src={sortedArray[activeImageIndex]?.url || ""}
                          alt={"profile"}
                          heigh={`100%`}
                          width={`100%`}
                          onLoad={() => setImageLoaded(true)}
                        />
                      </figure>
                    {/* )} */}
                  </div>
                  {!imageLoaded && (
                    <div className={Style.root_figure}>
                      <div className={`shine ${Style.thumb}`} />
                    </div>
                  )}
              </div>
            </TransformComponent>
          </>
        )}
      </TransformWrapper>

      <div className={`${Style.root_image_lists} col-12 pro-mb-3 pro-pb-3`}>
        <div className={`row`}>
          {sortedArray
            ? sortedArray?.map((profile, index) => (
                <div
                  className={`col-2`}
                  key={index}
                  onClick={() => handleThumbClick(index)}
                >
                  <div
                    className={`${Style.root_image_inner} ${
                      activeImageIndex === index ? Style.active : ""
                    } ${profile.status === 1 ? Style.checked : ""}  ${
                      profile.status === 2 ? Style.rejected : ""
                    }`}
                  >
                    <Image
                      width={100}
                      height={100}
                      src={profile.url}
                      alt={`image - 01`}
                    />
                  </div>
                </div>
              ))
            : [...Array(5)].map((_, i) => (
                <div className={`col-2`} key={i}>
                  <div className={Style.root_image_inner}>
                    <div className={`shine ${Style.thumb}`} />
                  </div>
                </div>
              ))}
        </div>
      </div>

      {showReasonSelect ? (
        <div className={`${Style.root_input} pro-m-2 $`}>
          <div className={"input-wrap disabled"}>
            <Select
              placeholder={`Reason for rejection`}
              value={activeReason?.label ? activeReason : ""}
              options={reasons}
              className={`pro-input ${reasonError ? "error" : ""} `}
              classNamePrefix="pro-input"
              onChange={handleReasonChange}
            />
            {reasonError && (
              <span className="error-text">Enter a reason for rejection</span>
            )}
          </div>
          <div
            className={`${Style.buttons} pro-d-flex pro-mt-2 pro-justify-end`}
          >
            <button
              onClick={cancelRejectClick}
              className="pro-btn pro-btn-outline"
            >
              Cancel
            </button>
            <button
              onClick={handleRejectSubmit}
              className={`pro-btn pro-btn-primary ${
                data.updateProfileStatus === "pending" ? "loading" : ""
              }`}
            >
              Submit
            </button>
          </div>
        </div>
      ) : data.profileData?.[activeID]?.[activeImageIndex] ? (
        <div
          className={`${Style.buttons} pro-d-flex pro-mt-2 pro-m-1 pro-justify-end`}
        >
          {!sortedArray[activeImageIndex].status && (
            <button
              onClick={handleReject}
              className="pro-btn pro-btn-outline full"
            >
              Reject
            </button>
          )}
          <button
            onClick={() =>
              !sortedArray[activeImageIndex].status && handleApprove()
            }
            className={`pro-btn pro-btn-primary full ${
              sortedArray[activeImageIndex].status === 1 ||
              sortedArray[activeImageIndex].status === 2
                ? "pro-no-point"
                : ""
            }  ${data.updateProfileStatus === "pending" ? "loading" : ""}`}
          >
            {sortedArray[activeImageIndex].status === 1
              ? "Approved"
              : sortedArray[activeImageIndex].status === 2
              ? "Rejected"
              : "Approve"}
          </button>
        </div>
      ) : (
        <div
          className={`${Style.buttons} pro-d-flex pro-mt-2 pro-m-1 pro-justify-end `}
        >
          {/** #FIXME Aneesh, haddle shine for buttons(border radius is high)   */}
          <button className={`pro-btn pro-btn-primary full shine`} disabled>
            Reject
          </button>
          <button className={`pro-btn pro-btn-primary full shine`} disabled>
            Approve
          </button>
        </div>
      )}
    </div>
  );
}
