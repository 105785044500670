import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const useCMS = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);

  const drawerMenu = [
    {
      title: "FAQ",
      label: "FAQ",
      link: "/cms/faq",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4">live_help</span>,
      active: checkIfActiveRoute("/cms/faq", true),
    },
    {
      title: "Testimonial",
      label: "Testimonial",
      link: "/cms/testimonials",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4">3p</span>,
      active: checkIfActiveRoute("/cms/testimonials", true),
    },
    {
      title: "User List",
      label: "User List",
      link: "/cms/user-list",
      iconAsset: "DrawerIcon3",
      icon: <span className="material-symbols-outlined x4">group</span>,
      active: checkIfActiveRoute("/cms/user-list"),
    },
    {
      title: "Highlighted Profiles",
      label: "Highlighted Profiles",
      link: "/cms/highlighted-profiles",
      iconAsset: "DrawerIcon3",
      icon: <span className="material-symbols-outlined x4">magic_button</span>,
      active: checkIfActiveRoute("/cms/highlighted-profiles"),
    },
    {
      title: "Newsletter Subscriptions",
      label: "Newsletter Subscriptions",
      link: "/cms/newsletter-subscribers",
      iconAsset: "DrawerIcon3",
      icon: <span className="material-symbols-outlined x4">forum</span>,
      active: checkIfActiveRoute("/cms/newsletter-subscribers"),
    },
    {
      title: "Magazine",
      label: "List Digital Mgazine",
      link: "/cms/magazine",
      iconAsset: "DrawerIcon3",
      icon: <span className="material-symbols-outlined x4">newsmode</span>,
      active: checkIfActiveRoute("/cms/magazine"),
    },
  ];

  const drawerMenuPermission = useMemo(() => {
    let menus = globalState.dashboard_permissions?.flatMap?.((menu) => [
      ...menu.permissions.map((sub_menu) => sub_menu.menu_name),
    ]);
    return drawerMenu
      .filter((menu) => menus?.includes?.(menu.label))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  return {
    drawerMenu: drawerMenuPermission,
  };
};

export default useCMS;
