import Style from "./addParishForm.module.scss";

const AddParishForm = ({ formik, optionsWithIcons, staffList }) => {
  return (
    <div className={Style.root}>
      <div className="col-12 pro-mb-4">
        <div className="row">
          <div className="col-12 pro-mb-4">
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Name
              </label>
              <input
                type="text"
                className={`pro-input lg ${
                  formik.errors.parishName &&
                  formik.touched.parishName &&
                  " error"
                }`}
                id="parishName"
                name="parishName"
                {...formik.getFieldProps("parishName")}
              />
              {formik.touched.parishName && formik.errors.parishName && (
                <span className="error-text">{formik.errors.parishName}</span>
              )}
            </div>
          </div>

          <div className="col-12 pro-mb-4">
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Location
              </label>
              <input
                type="text"
                className={`pro-input lg ${
                  formik.errors.parishLocation &&
                  formik.touched.parishLocation &&
                  " error"
                }`}
                id="parishLocation"
                name="parishLocation"
                {...formik.getFieldProps("parishLocation")}
              />
              {formik.touched.parishLocation &&
                formik.errors.parishLocation && (
                  <span className="error-text">
                    {formik.errors.parishLocation}
                  </span>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddParishForm;
