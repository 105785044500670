import { useEffect, useState } from "react";
import { useGetDashboardDataQuery } from "../../../store/queries/Dashboard";
import Members from "../../Users/Members";
import Deleted from "../../Users/Deleted";
import { updateConfig as updateMemberConfig } from "../../../store/slices/Users/membersSlice";
import { useDispatch } from "react-redux";
import DataUpdation from "../../Support/DataUpdation";
import Transactions from "../../Payment/Transactions";
import Messages from "../../Support/Complaints";
import FollowUp from "../../Users/FollowUp";
import Verification from "../../Users/Verification";
import { updateConfig as updateFollowUpConfig } from "../../../store/slices/payment/followUpSlice";
import { updateConfig as updateVerificationConfig } from "../../../store/slices/Users/verificationSlice";
import IncompleteRegistration from "../../Users/IncompleteRegistration";

// import { useSearchParams } from "react-router-dom";

const useDashboardListing = () => {
  const dispatch = useDispatch();
  const {
    data: dashboardData = {},
    isFetching,
    isSuccess,
  } = useGetDashboardDataQuery({});

  const [listingType, setListingType] = useState();

  useEffect(() => {
    if (isSuccess) {
      setListingType(Object.keys(dashboardData?.data || {})?.[0] ?? "");
    }

    //eslint-disable-next-line
  }, [isSuccess]);

  const handlePaidUser = () => {
    setListingType("paid_member");
    dispatch(
      updateMemberConfig((state) => {
        state.currentFilter = "Paid";
        state.currentPage = 1;
      })
    );
  };
  const handleActiveUser = () => {
    setListingType("active_users");
    dispatch(
      updateMemberConfig((state) => {
        state.currentFilter = "Active";
        state.currentPage = 1;
      })
    );
  };
  const handleActiveComplaints = () => {
    setListingType("active_complaints");
  };
  const handleDeletedUsers = () => {
    setListingType("deleted_users");
  };
  const handleInactiveUsers = () => {
    setListingType("inactive_users");
    dispatch(
      updateMemberConfig((state) => {
        state.currentFilter = "Inactive";
        state.currentPage = 1;
      })
    );
  };
  const handleMasterDataRequest = () => {
    setListingType("master_data_request");
  };
  const handleMonthlyCollected = () => {
    setListingType("monthly_collected");
  };
  const handleNewRegistration = () => {
    setListingType("new_resgistration");
    dispatch(
      updateMemberConfig((state) => {
        state.currentFilter = "New";
        state.currentPage = 1;
      })
    );
  };
  const handleProfileExpired = () => {
    setListingType("profile_expired");
    dispatch(
      updateFollowUpConfig((state) => {
        state.currentFilter = "expired";
        state.currentPage = 1;
      })
    );
  };
  const handleTotalUsers = () => {
    setListingType("total_users");
    dispatch(
      updateMemberConfig((state) => {
        state.currentFilter = null;
        state.currentPage = 1;
      })
    );
  };
  const handlePendingFollowUp = () => {
    setListingType("pending_follow_up");
  };
  const handleProfileSoonToExpire = () => {
    setListingType("profile_soon_to_expire");
    dispatch(
      updateFollowUpConfig((state) => {
        state.currentFilter = "profile_soon_to_expire";
        state.currentPage = 1;
      })
    );
  };
  const handleTotalFollowUp = () => {
    setListingType("total_follow_up");
  };
  const handleExpiredFreeMembers = () => {
    setListingType("expired_free_members");
    dispatch(
      updateFollowUpConfig((state) => {
        state.currentFilter = "expired";
        state.currentPage = 1;
      })
    );
  };
  const handleFreeMembers = () => {
    setListingType("free_member");
    dispatch(
      updateFollowUpConfig((state) => {
        state.currentFilter = "free_members";
        state.currentPage = 1;
      })
    );
  };
  const handleIdVerification = () => {
    setListingType("id_verification");
    dispatch(
      updateVerificationConfig((state) => {
        state.currentFilter = "id_proof";
        state.currentPage = 1;
      })
    );
  };
  const handlePhotoVerification = () => {
    setListingType("photo_verification");
    dispatch(
      updateVerificationConfig((state) => {
        state.currentFilter = "photo";
        state.currentPage = 1;
      })
    );
  };
  const handleTotalAssignedUsers = () => {
    setListingType("total_assigned_users");
    dispatch(
      updateMemberConfig((state) => {
        state.currentFilter = null;
        state.currentPage = 1;
      })
    );
  };
  const handleIncompleteReg = () => {
    setListingType("temporary_registration");
    // dispatch(
    //   updateMemberConfig((state) => {
    //     state.currentFilter = null;
    //     state.currentPage = 1;
    //   })
    // );
  };

  let object = {
    paid_member: handlePaidUser,
    active_users: handleActiveUser,
    active_complaints: handleActiveComplaints,
    deleted_users: handleDeletedUsers,
    inactive_users: handleInactiveUsers,
    master_data_request: handleMasterDataRequest,
    monthly_collected: handleMonthlyCollected,
    new_resgistration: handleNewRegistration,
    profile_expired: handleProfileExpired,
    total_users: handleTotalUsers,
    pending_follow_up: handlePendingFollowUp,
    profile_soon_to_expire: handleProfileSoonToExpire,
    total_follow_up: handleTotalFollowUp,
    expired_free_members: handleExpiredFreeMembers,
    free_member: handleFreeMembers,
    id_verification: handleIdVerification,
    photo_verification: handlePhotoVerification,
    total_assigned_users: handleTotalAssignedUsers,
    temporary_registration:handleIncompleteReg,
  };

  const handleClick = (label) => {
    object[label]();
  };

  let dashboardItems = {
    active_complaints: {
      label: "active_complaints",
      title: "Active Complaints",
      icon: "assignment",
      handleClick,
      component: <Messages dashboard={true} />,
    },
    active_users: {
      label: "active_users",
      title: "Active Users",
      icon: "group_add",
      handleClick,
      component: <Members dashboard={true} />,
    },
    deleted_users: {
      label: "deleted_users",
      title: "Deleted Users",
      icon: "delete",
      handleClick,
      component: <Deleted dashboard={true} />,
    },
    inactive_users: {
      label: "inactive_users",
      title: "Inactive Users",
      icon: "group_remove",
      handleClick,
      component: <Members dashboard={true} />,
    },
    master_data_request: {
      label: "master_data_request",
      title: "Master Data Requests",
      icon: "assistant",
      handleClick,
      component: <DataUpdation dashboard={true} />,
    },
    monthly_collected: {
      label: "monthly_collected",
      title: "Money Collected",
      icon: "savings",
      handleClick,
      component: <Transactions dashboard={true} />,
    },
    new_resgistration: {
      label: "new_resgistration",
      title: "New Registrations",
      icon: "how_to_reg",
      handleClick,
      component: <Members dashboard={true} />,
    },
    paid_member: {
      label: "paid_member",
      title: "Paid Members",
      icon: "account_balance_wallet",
      handleClick,
      component: <Members dashboard={true} />,
    },
    profile_expired: {
      label: "profile_expired",
      title: "Profiles Expired",
      icon: "auto_delete",
      handleClick,
      component: <FollowUp dashboard={true} />,
    },
    total_users: {
      label: "total_users",
      title: "Total Users",
      icon: "group",
      handleClick,
      component: <Members dashboard={true} />,
    },
    pending_follow_up: {
      label: "pending_follow_up",
      title: "Pending to Follow Up",
      icon: "group",
      handleClick,
      component: <FollowUp dashboard={true} />,
    },
    profile_soon_to_expire: {
      label: "profile_soon_to_expire",
      title: "Profiles soon to Expire",
      icon: "group",
      handleClick,
      component: <FollowUp dashboard={true} />,
    },
    total_follow_up: {
      label: "total_follow_up",
      title: "Total Follow Up",
      icon: "group",
      handleClick,
      component: <FollowUp dashboard={true} />,
    },
    expired_free_members: {
      label: "expired_free_members",
      title: "Expired Free Members",
      icon: "group",
      handleClick,
      component: <FollowUp dashboard={true} />,
    },
    free_member: {
      label: "free_member",
      title: "Free Members",
      icon: "group",
      handleClick,
      component: <FollowUp dashboard={true} />,
    },
    id_verification: {
      label: "id_verification",
      title: "ID Verifications",
      icon: "group",
      handleClick,
      component: <Verification dashboard={true} />,
    },
    photo_verification: {
      label: "photo_verification",
      title: "Photo Verifications",
      icon: "group",
      handleClick,
      component: <Verification dashboard={true} />,
    },

    total_assigned_users: {
      label: "total_assigned_users",
      title: "Total Assigned",
      icon: "group",
      handleClick,
      component: <Members dashboard={true} />,
    },

    temporary_registration: {
      label: "temporary_registration",
      title: "Incomplete Registrations",
      icon: "group",
      handleClick,
      component: <IncompleteRegistration dashboard={true} />,
    },
  };

  return {
    dashboardItems,
    dashboardData,
    isFetching,
    children: dashboardItems[listingType]?.component,
    listingType,
  };
};

export default useDashboardListing;
