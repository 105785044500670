import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useSelector } from "react-redux";
import {
  useCreateMagazineMutation,
  useUpdateMagazineMutation,
} from "../../../../store/queries/cms";
import { updateConfig } from "../../../../store/slices/CMS/magazineSlice";

const useMagazineForm = ({ refetch, closeModal }) => {
  const dispatch = useDispatch();

  const profilefileInputRef = useRef(null);

  const { selectedItemsDetails } = useSelector((state) => state.magazine);

  const [imagePreview, setImagePreview] = useState(
    selectedItemsDetails !== "" ? selectedItemsDetails?.magazine?.url : ""
  );

  const [imageCoverPreview, setImageCoverPreview] = useState(
    selectedItemsDetails !== "" ? selectedItemsDetails?.cover_photo?.url : ""
  );

  const [createMagazine, { isLoading }] = useCreateMagazineMutation();
  const [updateMagazine] = useUpdateMagazineMutation();

  useEffect(() => {
    if (selectedItemsDetails !== "") {
      setImageCoverPreview(selectedItemsDetails?.cover_photo?.url ?? "");
      setImagePreview(selectedItemsDetails?.magazine?.url ?? "");
    }
  }, [selectedItemsDetails]);

  let YupValidationObject = {
    year: Yup.string().required("*Required"),
    month: Yup.string().required("*Required"),
    cover_photo: Yup.string().required("*Required"),
    magazine: Yup.string().required("*Required"),
  };

  let createMagazineVal = Yup.object(YupValidationObject);

  const formik = useFormik({
    initialValues: {
      year: new Date().getFullYear(),

      month: selectedItemsDetails !== "" ? selectedItemsDetails?.month : "",

      cover_photo:
        selectedItemsDetails !== "" ? selectedItemsDetails?.cover_photo : "",

      magazine:
        selectedItemsDetails !== "" ? selectedItemsDetails?.magazine : "",
    },
    validationSchema: selectedItemsDetails === "" ? createMagazineVal : "",
    enableReinitialize: true,

    onSubmit: (values, { resetForm }) => {
      try {
        let obj = {
          year: values.year,
          month: values.month,
        };

        if (values.cover_photo?.[0]?.name || values.cover_photo?.length === 0) {
          obj.cover_photo = values.cover_photo?.[0];
        }

        if (values.magazine?.[0]?.name || values.magazine?.length === 0) {
          obj.magazine = values.magazine?.[0];
        }

        let formData = new FormData();

        Object.keys(obj).forEach((key) => {
          return formData.append(key, obj[key]);
        });

        if (selectedItemsDetails !== "") {
          formData.append("_method", "PUT");
          formData.append("digital_magazine_id", selectedItemsDetails?._id);
        }

        if (selectedItemsDetails !== "") {
          updateMagazine(formData).then((response) => {
            if (response?.data?.status_code === 200) {
              resetForm();
              closeModal?.();
              refetch();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );

              toast.success(response?.data?.message);
            } else if (response?.data?.status_code === 400) {
              formik.setErrors(response?.data?.message);
            } else toast.error(response?.data?.message);
          });
        } else {
          createMagazine(formData).then((response) => {
            if (response?.data?.status_code === 200) {
              resetForm();
              refetch();
              closeModal?.();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );

              toast.success(response?.data?.message);
            } else if (response?.data?.status_code === 400) {
              formik.setErrors(response?.data?.message);
            } else toast.error(response?.data?.message);
          });
        }
      } catch (error) {
        toast.error("Something went wrong");
      }
    },
  });

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  const handleCoverImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik.setFieldValue("cover_photo", e?.target?.files);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setImageCoverPreview(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
      formik.setFieldError(
        "cover_photo",
        "The image must be less than 5MB in size."
      );
    }
  };
  const handleUploadImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik.setFieldValue("magazine", e?.target?.files);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setImagePreview(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else {
      toast.error("The file must be less than 5MB in size.");
      formik.setFieldError(
        "profile",
        "The file must be less than 5MB in size."
      );
    }
  };

  return {
    formik,
    isLoading,
    imageCoverPreview,
    imagePreview,
    selectedItemsDetails,
    profilefileInputRef,
    handleCoverImage,
    handleUploadImage,
    handleCloseModal,
  };
};

export default useMagazineForm;
