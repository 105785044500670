import { useFormik } from "formik";
import * as Yup from "yup";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Settings/faqsSlice";
import { saveParish } from "../../../../store/slices/Settings/dioceseSinglePageSlice";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const useAddParishFormContainer = (setShowform, refetch) => {
  const imageFileInputRef = useRef(null);

  const dispatch = useDispatch();
  const { dioceseID } = useParams();

  let validationSchema = Yup.object({
    parishName: Yup.string().required("Required"),
    parishLocation: Yup.string().required("Required"),
  });

  const optionsWithIcons = [];

  const formik = useFormik({
    initialValues: {
      parishName: "",
      parishLocation: "",
    },

    validationSchema: validationSchema,

    onSubmit: (values, { resetForm, setFieldError }) => {
      handleFormSubmit(values, resetForm, setFieldError);
    },
  });

  const handleFormSubmit = async (values, resetForm, setFieldError) => {
    try {
      try {
        let obj = {
          diocese_id: dioceseID,
          name: values.parishName,
          location: values.parishLocation,
        };

        dispatch(saveParish(obj))
          .unwrap()
          .then((res) => {
            if (res?.data?.status_code === 200) {
              refetch();
              setShowform(false);
              toast.success(res?.data?.message);
            }
            else{
              toast.error("Failed to Add parish!");
            }
          });
      } catch (error) {}
    } catch (error) {
      toast.error("Failed to Add parish!");
    }
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  return {
    formik,

    handleCloseModal,
    imageFileInputRef,
    optionsWithIcons,
  };
};

export default useAddParishFormContainer;
