import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useEducation from "./useEducation";

const Education = ({
  optionsData,
  isOptionDataSuccess,
  isEditProfile,
  className,
  profileRefetch,
  isStickyFooter,
}) => {
  const { formik, educationRef,isLoading, handleCloseModal, getFieldError } =
    useEducation({ isOptionDataSuccess, isEditProfile, profileRefetch });

  return (
    <div className={className}>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Education Category
        </label>
        <Select
          id="education_category"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("education_category") && " error"
          }`}
          classNamePrefix="pro-input"
          options={optionsData?.education_category}
          getOptionValue={(option) => option}
          getOptionLabel={(option) => option?.name}
          value={optionsData?.education_category?.filter(
            (m) => formik.values.education_category?._id === m?._id
          )}
          onBlur={formik.handleBlur("education_category")}
          onChange={(value) => {
            formik.setFieldValue("education_category", value || null);
            educationRef.current.setValue([], "clear");
          }}
        ></Select>
        {getFieldError("education_category") && (
          <span className="error-text">
            {getFieldError("education_category")}
          </span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Education
        </label>
        <Select
          ref={educationRef}
          id="education"
          isDisabled={formik.values?.education_category?._id ? false : true}
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("education") && " error"}`}
          classNamePrefix="pro-input"
          options={optionsData?.education?.filter(
            (p) =>
              p?.education_category_id ===
              formik.values.education_category?.category_id
          )}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={optionsData?.education?.filter(
            (m) => formik.values.education?._id === m?._id
          )}
          onBlur={formik.handleBlur("education")}
          onChange={(value) => {
            formik.setFieldValue("education", value);
          }}
        ></Select>
        {getFieldError("education") && (
          <span className="error-text">{getFieldError("education")}</span>
        )}
      </div>

      <Input
        label={"Education Details"}
        id="education_detail"
        type="text"
        name="education_detail"
        className={`pro-input lg ${
          getFieldError("education_detail") && " error"
        }`}
        {...formik.getFieldProps("education_detail")}
        error={getFieldError("education_detail")}
        errorMessage={getFieldError("education_detail")}
      />
      <Input
        label={"Institution Name"}
        id="institution_name"
        type="text"
        name="institution_name"
        className={`pro-input lg ${
          getFieldError("institution_name") && " error"
        }`}
        {...formik.getFieldProps("institution_name")}
        error={getFieldError("institution_name")}
        errorMessage={getFieldError("institution_name")}
      />

      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleCloseModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${isLoading ? "loading" : ""}`}
          type="submit"
          disabled={isLoading}
          onClick={formik.handleSubmit}
        >
          {isEditProfile ? "Update" : "Next"}
        </Button>
      </div>
    </div>
  );
};

export default Education;
