import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useGetOverviewDataQuery,
  useHighlightProfileMutation,
  useUnHighlightProfileMutation,
  useUpdateProfileDetailsMutation,
} from "../../../store/queries/Profile";
import Assets from "../../../assets/Assets";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { updateConfig as updateMemberConfig } from "../../../store/slices/Users/membersSlice";
import { updateFormData } from "../../../store/slices/Users/createMemberSlice";
import { updateConfig } from "../../../store/slices/Profile";

const useProfileLayout = () => {
  const [params] = useSearchParams();
  const dispatch = useDispatch();
  const printRef = useRef();
  const [showDeleteImageModal, setShowDeleteImageModal] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [show, setShow] = useState(false);
  const [copied, setCopied] = useState(false);
  const ProfileState = useSelector((state) => state.profile);
  const { showCreateModal } = useSelector((state) => state.members);
  const { isEditProfile, selectedUser } = useSelector(
    (state) => state.createMembers
  );
  const [updateProfile] = useUpdateProfileDetailsMutation();
  const navigate = useNavigate();
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  const ids = params.get("id")
    ? [...params.get("id").split(","), activeProfile]
    : [activeProfile];

  const allProfiles = JSON.parse(
    sessionStorage.getItem("all_profiles") || "[]"
  );

  const [profiles, setProfiles] = useState(
    Array(ids.length).fill({
      name: "",
      image: "",
    })
  );

  const {
    data: profileData,
    isFetching,
    isSuccess,
    isError,
    refetch,
  } = useGetOverviewDataQuery({
    user_id: activeProfile,
  });

  const hasHighlightPermission = useMemo(() => {
    let permission = profileData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_highlight")
    );

    return permission?.[0]?.can_highlight;
  }, [profileData]);

  const hasUpdatePermission = useMemo(() => {
    let permission = profileData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_update")
    );

    return permission?.[0]?.can_update ?? 0;
  }, [profileData]);

  useEffect(() => {
    if (profileData?.status_code === 400 || profileData === "") {
      toast.error(`Something went wrong!`);
      navigate("/");
    }
    dispatch(
      updateConfig((state) => {
        state.profileOverviewData = profileData?.data?.data;
      })
    );
    // eslint-disable-next-line
  }, [profileData]);

  const [highlightRequest] = useHighlightProfileMutation();
  const [unHighlightRequest] = useUnHighlightProfileMutation();

  const handleClick = (item) => {
    const newIds = ids.filter((id) => id !== item?.id).join(",");
    navigate({
      pathname: "/profile",
      search: `?active=${item?.id}&id=${newIds}`,
    });
    sessionStorage.setItem("active", `${item?.id}`);
  };

  const handleProfileClick = () => {
    setShow((state) => (state = true));
  };

  const closeModal = () => {
    setShow((state) => (state = false));
    setTimeout(() => {
      setActiveImageIndex(() => 0);
    }, 300);
  };

  //FIXME pass index from TabHeading to handleClose and use it to move to prev tab

  const handleClose = (item) => {
    const filteredAllProfiles = allProfiles?.filter(
      (profile) =>
        ids.includes(profile?.id.toString()) && profile.id !== item.id
    );

    setProfiles(filteredAllProfiles);
    sessionStorage.setItem("all_profiles", JSON.stringify(filteredAllProfiles));
    if (filteredAllProfiles.length === 0) {
      window.close();
    } else {
      navigate({
        pathname: "/profile",
        search: `?active=${
          filteredAllProfiles[filteredAllProfiles.length - 1].id
        }&id=${filteredAllProfiles.map((p) => p.id).join(",")}`,
      });
    }
  };

  const imageSlider = useMemo(() => {
    if (isSuccess) {
      if (
        profileData?.data?.data?.profile_images &&
        profileData?.data?.data?.profile_images?.length !== 0
      ) {
        return profileData?.data?.data?.profile_images?.map((img, i) => {
          return {
            image: img?.url,
            id: img?._id,
            image_name: img?.name,
            isPrimary: img?.is_primary ?? 0,
            isRejected: img?.status === 2 ? true : false,
            reject_reason: img?.reject_reason,
          };
        });
      } else {
        return [
          {
            image:
              profileData?.data?.data?.gender?.name === "Female"
                ? Assets.FEMALEPROFILE
                : Assets.MALEPROFILE,
            id: 1,
            image_name: "gender_image.jpg",
            isPrimary: 0,
            isDefault: true,
          },
        ];
      }
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [profileData, isFetching]);

  useEffect(() => {
    if (copied) {
      toast.success("Link Copied!");
    }
  }, [copied]);

  const handleDownloadClick = () => {
    const element = printRef.current;

    html2pdf(element, {
      margin: [0, 0, 10, 0],
      filename: `${
        profileData?.data?.data?.profile_id +
        "_" +
        moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
      }.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        width: 1150,
        height: 1560,
        dpi: 100,
        letterRendering: true,
        useCORS: true,
        logging: true,
        scale: 4,
        scrollY: 0,
      },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
      DisablePdfCompression: true,
    });
  };

  const handleShareClick = () => {
    const el = document.createElement("input");
    el.value = `https://www.syromalabarmatrimony.org/profile/active=${activeProfile}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(() => true);
    setTimeout(() => {
      setCopied(() => false);
    }, 3000);
  };

  const handlePrintClick = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    if (isEditProfile && isSuccess) {
      dispatch(
        updateFormData((state) => {
          state.currentFormData = {
            ...profileData?.data?.data,
            user_id: selectedUser,
          };
        })
      );
    }
    // eslint-disable-next-line
  }, [isEditProfile, isSuccess]);

  useEffect(() => {
    if (isSuccess) {
      if (
        allProfiles.findIndex((profile) => profile.id === activeProfile) === -1
      ) {
        const getImage = () => {
          if (
            profileData?.data?.data?.profile_images === undefined ||
            profileData?.data?.data?.profile_images === null
          ) {
            return profileData?.data?.data?.gender?.name === "Female"
              ? Assets.FEMALEPROFILE
              : Assets.MALEPROFILE;
          } else {
            return (
              profileData?.data?.data?.profile_images?.filter(
                (image) => image?.is_primary === 1
              )[0]?.url || profileData?.data?.data?.profile_images?.[0]?.url
            );
          }
        };

        let profile = {
          id: activeProfile,
          name: profileData?.data?.data?.first_name,
          image: getImage(),
        };

        sessionStorage.setItem("active", activeProfile);
        sessionStorage.setItem(
          "all_profiles",
          JSON.stringify([...allProfiles, profile])
        );

        setProfiles(
          [...allProfiles, profile].filter((p) =>
            ids.includes(p?.id?.toString())
          )
        );
      } else {
        setProfiles(allProfiles.filter((p) => ids.includes(p?.id?.toString())));
      }
    }
    // eslint-disable-next-line
  }, [profileData]);

  useEffect(() => {
    if (isError) {
      if (allProfiles.length === 0) {
        toast.error(`Oops, Something went wrong!`);
        navigate("/");
      } else {
        toast.error(`Oops, Something went wrong!`);
        navigate({
          pathname: "/profile",
          search: `active=${allProfiles[0].id}`,
        });
      }
    }
    // eslint-disable-next-line
  }, [isError]);

  const actions = [
    {
      label: "Share",
      handleClick: handleShareClick,
      icon: <span className="material-symbols-outlined"> share </span>,
    },
    {
      label: "Download",
      handleClick: handleDownloadClick,
      icon: <span className="material-symbols-outlined"> cloud_download </span>,
    },
    {
      label: "Print",
      handleClick: handlePrintClick,
      icon: <span className="material-symbols-outlined"> print </span>,
    },
  ];

  const numberOrdinals = { 1: "", 0: "", all: "s" };

  const getOrdinalNumbers = (no) => {
    return `${numberOrdinals?.[no] ?? numberOrdinals["all"]}`;
  };

  const label = {
    marital_status: "Marital Status",
    gender: "Gender",
    community: "Community",
    diocese: "Diocese",
    parish: "Parish",
    country: "Country",
    state: "State",
    district: "District",
    phone: "Mobile",
    email: "Email",
    age_and_dob: "Age & Date Of Birth",
    no_of_children: `No. of Children `,
    weight: "Weight",
    height: "Height",
    body_type: "Body Type",
    physical_status: "Physical Status",
    complexion: "Complexion",
    profile_created: "Profile Created By",
    profile_created_at: "Profile Created At",
  };

  const value = {
    phone: `${profileData?.data?.data?.phone_number ?? "Not specified"}`,
    email: `${profileData?.data?.data?.email ?? "Not specified"}`,
    gender: `${profileData?.data?.data?.gender?.name ?? "Not specified"}`,
    marital_status: `${
      profileData?.data?.data?.marital_status?.name ?? "Not specified"
    }`,
    no_of_children: "",
    age_and_dob: `${profileData?.data?.data?.age ?? "Not specified"}, ${
      profileData?.data?.data?.dob ?? ""
    }`,
    community: `${
      profileData?.data?.data?.community_id?.name ?? "Not specified"
    }`,
    diocese: `${profileData?.data?.data?.diocese_id?.name ?? "Not specified"}`,
    parish: `${
      profileData?.data?.data?.parish_id?.full_name ?? "Not specified"
    }`,
    country: `${profileData?.data?.data?.country?.name ?? "Not specified"}`,
    state: `${profileData?.data?.data?.state?.name ?? "Not specified"}`,
    district: `${profileData?.data?.data?.district?.name ?? "Not specified"}`,
    weight: profileData?.data?.data?.weight ?? "Not specified",
    height: profileData?.data?.data?.height ?? "Not specified",
    body_type: `${profileData?.data?.data?.body_type?.name ?? "Not specified"}`,
    physical_status:
      profileData?.data?.data?.physical_status ?? "Not specified",
    complexion: `${
      profileData?.data?.data?.body_complexion?.name ?? "Not specified"
    }`,
    profile_created:
      profileData?.data?.data?.profile_created_by ?? "Not specified",
    profile_created_at:
      profileData?.data?.data?.created_date ?? "Not specified",
  };

  if (
    profileData?.data?.data?.marital_status !== "" &&
    profileData?.data?.data?.marital_status &&
    profileData?.data?.data?.marital_status?.name !== "Unmarried"
  ) {
    value.no_of_children = `${
      profileData?.data?.data?.no_of_children ?? "Not specified"
    }`;
  } else {
    delete value.no_of_children;
  }

  const basicDetails = Object.keys(value).map((key) => {
    return {
      label: label[key],
      value:
        typeof value[key] === "string"
          ? value?.[key]
          : typeof value[key] === "number"
          ? value?.[key]
          : value?.[key]?.name,
    };
  });

  const handleEditClick = () => {
    dispatch(
      updateMemberConfig((state) => {
        state.showCreateModal = true;
      })
    );
    dispatch(
      updateFormData((state) => {
        state.activeTab = "Basic";
        state.isEditProfile = true;
        state.selectedUser = activeProfile;
      })
    );
  };

  const closeEditOffcanvas = () => {
    dispatch(
      updateMemberConfig((state) => {
        state.showCreateModal = false;
      })
    );
    dispatch(
      updateFormData((state) => {
        state.activeTab = "Basic";
        state.isEditProfile = false;
        state.currentFormData = {};
      })
    );
  };

  const onProfileUpdateClick = () => {
    dispatch(
      updateMemberConfig((state) => {
        state.showCreateModal = true;
      })
    );
    dispatch(
      updateFormData((state) => {
        state.activeTab = "Basic";
        state.isEditProfile = true;
        state.selectedUser = activeProfile;
      })
    );
  };

  const handleHighlightProfile = (status_id) => {
    highlightRequest({ user_id: activeProfile, status: status_id })
      .then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Profile  Highlighted Successfully!");
        } else if (response?.error?.data?.status_code === 422) {
          toast.error(response?.error?.data?.errors);
        } else {
          toast.error("Failed to Highlight Profile!");
        }
      })
      .catch(() => {
        toast.error("Failed to Highlight Profile!");
      });
  };

  const handleUnHighlightProfile = () => {
    let formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("user_id", activeProfile);

    unHighlightRequest(formData)
      .then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Profile  UnHighlighted Successfully!");
        } else if (response?.error?.data?.status_code === 422) {
          toast.error(response?.error?.data?.errors);
        } else {
          toast.error("Failed to UnHighlight Profile!");
        }
      })
      .catch(() => {
        toast.error("Failed to UnHighlight Profile!");
      });
  };
  const handleDeleteImage = () => {
    setShow(() => false);
    setShowDeleteImageModal(() => true);
  };

  const closeDeleteImageModal = () => {
    setActiveImageIndex(0);
    setShowDeleteImageModal(() => false);
    setShow(() => true);
  };
  const handleActionDeleteImage = () => {
    let obj = {
      user_id: activeProfile,
      first_name: profileData?.data?.data?.first_name,
      last_name: profileData?.data?.data?.last_name,
      country_code: profileData?.data?.data?.country_code,
      phone_number: profileData?.data?.data?.phone_number,
      gender: profileData?.data?.data?.gender?.id,
      dob: profileData?.data?.data?.dob,
      community_id: profileData?.data?.data?.community_id?._id,
      diocese_id: profileData?.data?.data?.diocese_id?._id,
    };
    try {
      const formData = new FormData();

      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });
      formData.append("_method", "PUT");
      if (imageSlider?.length === 2) {
        let activeId =
          activeImageIndex === 0 ? activeImageIndex + 1 : activeImageIndex - 1;
        formData.append(
          `profile_images[${activeId}][_id]`,
          imageSlider?.sort((a, b) => b.isPrimary - a.isPrimary)[activeId]
            ?.id ?? ""
        );
        formData.append(`profile_images[${activeId}][is_primary]`, 1);
      }

      formData.append(
        "profile_images_deleted[]",
        `${
          imageSlider?.sort((a, b) => b.isPrimary - a.isPrimary)[
            activeImageIndex
          ]?.id
        }`
      );

      updateProfile(formData)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            toast.success("Image Deleted Successfully!");
            refetch();
            closeDeleteImageModal();
          } else {
            toast.error("Failed to Delete image!");
          }
        })
        .catch(() => {
          toast.error("Failed to Delete image!");
        });
    } catch (e) {
      toast.error("Failed to Delete Image!");
    }
  };

  return {
    actions,
    show,
    activeProfile,

    profiles,
    isFetching,
    imageSlider,
    printRef,
    profileData: profileData?.data,
    basicDetails,
    ProfileState,
    showCreateModal,
    showDeleteImageModal,
    hasUpdatePermission,
    activeImageIndex,
    hasHighlightPermission,
    setActiveImageIndex,
    handleActionDeleteImage,
    handleDeleteImage,
    closeDeleteImageModal,
    setShowDeleteImageModal,
    setShow,
    handleHighlightProfile,
    handleUnHighlightProfile,
    refetch,
    closeModal,
    handleEditClick,
    closeEditOffcanvas,
    handleClick,
    handleClose,
    handleProfileClick,
    onProfileUpdateClick,
  };
};

export default useProfileLayout;
