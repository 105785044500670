import { useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetPackagesListDataQuery,
  useUpdatePackagesListDataMutation,
} from "../../../store/queries/settings";
import {
  editItemFromTableByID,
  updateConfig,
} from "../../../store/slices/Settings/packageSlice";

import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig as packageUpdateConfig } from "../../../store/slices/Settings/packageSlice";
import StatusButton from "../Adons/StatusButton";

const usePackages = () => {
  const dispatch = useDispatch();
  const packageState = useSelector((state) => state.package);

  const { currentPage, currentPageSize, selectedItemsDetails } = useSelector(
    (state) => state.package
  );

  const { showCreateModal } = useSelector((state) => state.package);
  const { tableFields, showEditModal } = useSelector((state) => state.global);

  const [updatePackagesFields] = useUpdatePackagesListDataMutation();

  useEffect(() => {
    if (showCreateModal === false && selectedItemsDetails != null) {
      dispatch(
        updateConfig((state) => {
          state.selectedItemsDetails = null;
        })
      );
    }
    // eslint-disable-next-line
  }, [showCreateModal]);

  useEffect(() => {
    if (packageState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [packageState.clearSelection]);

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  useEffect(() => {
    dispatch(
      packageUpdateConfig((state) => {
        state.formType = "package";
      })
    );
    return () => {
      dispatch(
        packageUpdateConfig((state) => {
          state.formType = null;
        })
      );
    };
    // eslint-disable-next-line
  }, []);

  const {
    data: packages = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetPackagesListDataQuery({
    sort_by: packageState.sortBy,
    sort_order: packageState.sortOrder,
    search: packageState.search,
    page_size: packageState.currentPageSize,
    page: packageState.currentPage,
  });
  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = packages?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [packages]);

  const hasCreatePermission = useMemo(() => {
    let permission = packages?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permission?.[0]?.can_create ?? 0;

    // eslint-disable-next-line
  }, [packages]);

  const hasEditPermission = useMemo(() => {
    let permission = packages?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );

    return permission?.[0]?.can_edit ?? 0;

    // eslint-disable-next-line
  }, [packages]);

  const hasUpdatePermission = useMemo(() => {
    let permission = packages?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_update")
    );

    return permission?.[0]?.can_update ?? 0;

    // eslint-disable-next-line
  }, [packages]);

  const hasExportPermission = useMemo(() => {
    let permission = packages?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_export")
    );

    return permission?.[0]?.can_export ?? 0;
  }, [packages]);

  const hasStatusUpdatePermission = useMemo(() => {
    let permission = packages?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_change_status")
    );

    return permission?.[0]?.can_change_status ?? 0;
  }, [packages]);


  const handleEditPackage = (data) => {
    let ID = Array.isArray(data) ? data[0] : data;
    dispatch(editItemFromTableByID({ package_id: ID }))
      .unwrap()
      .then((result) => {
        dispatch(
          updateConfig((state) => {
            state.showCreateModal = true;
          })
        );
      })
      .catch((err) => {});
  };

  const handleSort = (label) => {
    if (packageState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = packageState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };


  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      status: (feild, data) =>
      
      hasStatusUpdatePermission === 1 ? (
        <StatusButton data={data} refetch={refetch} />
      ) : (
        <p className="pro-mb-0">{data[feild] ?? ""}</p>
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    } else {
    }
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.selectedItemsDetails = null;
        state.rules = null;
        state.formType = null;
      })
    );
  };

  return {
    handleClearClick,
    packages,
    showCreateModal,
    hasCreatePermission,
    isFetching,
    isLoading,
    actionOptions,
    hasExportPermission,
    hasEditPermission,
    hasUpdatePermission,
    handlePageSize,
    handleActionChange,
    handleSort,
    handleSearch,
    handleCreateClick,
    closeModal,
    getRow,
    handleEditClick,
    handleEditPackage,
    closeEditModal,
    showEditModal,
    tableFields,
    handlePagination,
    currentPage,
    currentPageSize,
    paginationOptions,
    updatePackagesFields,
    refetch,
    packageState,
  };
};

export default usePackages;
