import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentFormData: [],
  backendErrors: "",
  imagePreview:[],
  activeTab: "Basic",
  completedTabs: {
    Basic: false,
    Family: false,
    Job: false,
    Edu: false,
    Contact: false,
  },
  CompletedPercentage: {
    Basic: 0,
    Family: 0,
    Job: 0,
    Edu: 0,
    Contact: 0,
  },
  phoneVerified: {
    verified: false,
    status: false,
  },
  isEditProfile: false,
  isUpdateProfile: false,
  selectedUser: "",
};

const updateIncompletememberSlice = createSlice({
  name: "updateIncompletemember",
  initialState,
  reducers: {
    updateFormData: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateFormData } = updateIncompletememberSlice.actions;

export default updateIncompletememberSlice.reducer;
