import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  selectedRowField: null,
  selectedItemsDetails: null,
  rules: null,
  formType: null,
  clearSelection: false,
};

export const editItemFromTableByID = createAsyncThunk(
  "adons/editItemFromTableByID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/admin/add-on/edit?${new URLSearchParams(params).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const adonsSlice = createSlice({
  name: "adons",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    [editItemFromTableByID.pending]: (state, action) => {},
    [editItemFromTableByID.fulfilled]: (state, action) => {
      state.selectedItemsDetails = action.payload.data.data;
    },
    [editItemFromTableByID.rejected]: (state, action) => {},
  },
});

export const { updateConfig } = adonsSlice.actions;

export default adonsSlice.reducer;
