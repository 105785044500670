import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useObserver from "../../../utils/hooks/useObserver";
import { useDispatch, useSelector } from "react-redux";
import {
  getInterestedCount,
  getInterestedDetails,
  updateConfig,
} from "../../../store/slices/Profile/Interested";
import { toast } from "react-toastify";

const useInterested = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageReceived, setPageReceived] = useState(1);
  const [activeFilter, setActiveFilter] = useState();
  const [showScroll, setShowScroll] = useState(false);
  const [params] = useSearchParams();
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");
  const state = useSelector((state) => state.interested);
  const interestedState = state?.interestedData?.[activeProfile];
  const globalState = useSelector((state) => state.global);
  const filters = [
    {
      type: "buttonGroup",
      buttons: [
        { name: "Interests Sent", value: 0 },
        { name: "Interests Received", value: 1 },
        { name: "Interests Accepted", value: 2 },
      ],
    },
  ];
  const reportData = [
    {
      label: "Today",
      value: `${state.interestedCount[activeProfile]?.daily_count ?? 0}`,
    },
    {
      label: "This week",
      value: `${state.interestedCount[activeProfile]?.weekly_count ?? 0}`,
    },
    {
      label: "This Month",
      value: `${state.interestedCount[activeProfile]?.monthly_count ?? 0}`,
    },
    {
      label: "All time",
      value: `${state.interestedCount[activeProfile]?.total_count ?? 0}`,
    },
  ];

  const hasInterestSentPermission = useMemo(() => {
    let permission = state?.interestedPermissions?.filter((p) =>
      Object.keys(p).includes("interest_sent")
    );

    return permission?.[0]?.interest_sent ?? 0;
  }, [state]);
  const hasInterestAcceptedPermission = useMemo(() => {
    let permission = state?.interestedPermissions?.filter((p) =>
      Object.keys(p).includes("interest_accepted")
    );

    return permission?.[0]?.interest_accepted ?? 0;
  }, [state]);
  const hasInterestReceivedPermission = useMemo(() => {
    let permission = state?.interestedPermissions?.filter((p) =>
      Object.keys(p).includes("interest_received")
    );

    return permission?.[0]?.interest_received ?? 0;
  }, [state]);

  if (hasInterestSentPermission !== 1) {
    filters[0].buttons = filters[0].buttons.filter(
      (button) => button.value !== 0
    );
  }
  if (hasInterestReceivedPermission !== 1) {
    filters[0].buttons = filters[0].buttons.filter(
      (button) => button.value !== 1
    );
  }
  if (hasInterestAcceptedPermission !== 1) {
    filters[0].buttons = filters[0].buttons.filter(
      (button) => button.value !== 2
    );
  }
  
  const handleActiveFilter = () => {
    const newvalue=globalState?.profile_permissions?.find(
      (item) => item?.label === "Interests")
    const initialValue = activeFilter ?? (
      newvalue?.front_end_url.includes("sent") ? 0 :
      newvalue?.front_end_url.includes("received") ? 1 : 2
    );
    return initialValue;
  };
  useEffect(() => {
    if (Object.keys(state.interestedData[activeProfile] ?? {}).length === 0) {
      if (activeFilter === 0) {
        dispatch(
          getInterestedDetails({
            user_id: activeProfile,
            activeFilter: handleActiveFilter(),
            // page: page,
            page: state?.currentPage,
            hasInterestSentPermission: hasInterestSentPermission,
            type: 1,
          })
        );
        dispatch(
          getInterestedCount({
            user_id: activeProfile,
            activeFilter: handleActiveFilter(),
            // page: page,
            page: state?.currentPage,
            hasInterestSentPermission: hasInterestSentPermission,
            type: 2,
          })
        );
      } else {
        dispatch(
          getInterestedDetails({
            user_id: activeProfile,
            activeFilter: handleActiveFilter(),
            // page: pageReceived,
            page: state?.currentPage,
            hasInterestSentPermission: hasInterestSentPermission,
            type: 1,
          })
        );
        dispatch(
          getInterestedCount({
            user_id: activeProfile,
            activeFilter: handleActiveFilter(),
            // page: pageReceived,
            page: state?.currentPage,
            hasInterestSentPermission: hasInterestSentPermission,
            type: 2,
          })
        );
      }
    } else {
      window.scrollTo(0, document.body.scrollHeight);
    }

    if (
      state?.selectedProfiles?.length ===
      interestedState?.data
        ?.filter((item) => Boolean(item.short_listed))
        ?.map((item) => item?.member_id)?.length
    ) {
      dispatch(
        updateConfig((state) => {
          state.selectedProfiles = interestedState?.data
            ?.filter((item) => Boolean(item.short_listed))
            ?.map((item) => item?.member_id);
        })
      );
    }
    // return () => {
    //   dispatch(
    //     updateConfig((state) => {
    //       state.interestedStatus = "idle";
    //       state.interestedData = {};
    //       state.selectedProfiles = [];
    //     })
    //   );
    // };

    //eslint-disable-next-line
  }, [activeProfile,globalState?.profile_permissions]);

  useEffect(() => {
    function handleWindowResize() {
      if (window.scrollY > 150) {
        setShowScroll(true);
      } else if (window.scrollY === 0) {
        setShowScroll(false);
      }
    }
    window.addEventListener("scroll", handleWindowResize);

    return () => {
      window.removeEventListener("scroll", handleWindowResize);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  // const handleLoadMore = () => {
  //   // dispatch(
  //   //   updateConfig((state) => {
  //   //     //state.currentPage = state.currentPage + 1;
  //   //     state.interestedData[activeProfile].page += 1;
  //   //   })
  //   // );
  //   if (activeFilter === 0) {
  //     setPage((prev) => prev + 1);
  //     dispatch(
  //       getInterestedDetails({
  //         user_id: activeProfile,
  //         activeFilter: activeFilter,
  //         // page: page + 1,
  //         page: interestedState?.current_page + 1,
  //         hasInterestSentPermission: hasInterestSentPermission,
  //         type: 1,
  //       })
  //     );
  //   } else {
  //     setPageReceived((prev) => prev + 1);
  //     dispatch(
  //       getInterestedDetails({
  //         user_id: activeProfile,
  //         activeFilter: activeFilter,
  //         // page: pageReceived + 1,
  //         page: interestedState?.current_page + 1,
  //         hasInterestSentPermission: hasInterestSentPermission,
  //         type: 1,
  //       })
  //     );
  //   }
  // };

  const handleLoadMore = useCallback(() => {
    if (interestedState?.interestedStatus === "fulfilled") {
      if (activeFilter === 0) {
        dispatch(
          getInterestedDetails({
            user_id: activeProfile,
            activeFilter: activeFilter,
            // page: page + 1,
            page: interestedState?.current_page + 1,
            hasInterestSentPermission: hasInterestSentPermission,
            type: 1,
          })
        );
      } else {
        dispatch(
          getInterestedDetails({
            user_id: activeProfile,
            activeFilter: activeFilter,
            // page: pageReceived + 1,
            page: interestedState?.current_page + 1,
            hasInterestSentPermission: hasInterestSentPermission,
            type: 1,
          })
        );
      }
    }
    //eslint-disable-next-line
  }, [dispatch, activeProfile, interestedState]);

  const { lastElement } = useObserver({
    loading: interestedState?.interestedStatus !== "fulfilled",
    hasMore:
      interestedState?.per_page * interestedState?.current_page <
      interestedState?.total,
    callback: handleLoadMore,
  });

  const handleProfileRedirect = (id, viewStatus) => {
    const ids = params.get("id")
      ? [...params.get("id").split(","), activeProfile]
      : [activeProfile];

    const newIds = ids.filter((item) => item !== id).join(",");
    if (viewStatus === 0) {
      toast.error(`User does not have permission to view this profile`);
    } else {
      navigate({
        pathname: "/profile",
        search: `active=${id}&id=${newIds}`,
      });
    }
  };

  const handleTabChange = (item) => {
    if (item.value !== activeFilter) {
      dispatch(
        updateConfig((state) => {
          state.interestedData[activeProfile].data = [];
          state.interestedData[activeProfile].page = 1;
        })
      );
      if (item.value === 0) {
        dispatch(
          getInterestedDetails({
            user_id: activeProfile,
            activeFilter: item.value,
            // page: page,
            // page: interestedState?.current_page,
            page: state?.currentPage,
            hasInterestSentPermission: hasInterestSentPermission,
            type: 1,
          })
        );
        dispatch(
          getInterestedCount({
            user_id: activeProfile,
            activeFilter: item.value,
            // page: page,
            // page: interestedState?.current_page,
            page: state?.currentPage,
            hasInterestSentPermission: hasInterestSentPermission,
            type: 2,
          })
        );
        setPageReceived(1);
      } else {
        dispatch(
          getInterestedDetails({
            user_id: activeProfile,
            activeFilter: item.value,
            // page: pageReceived,
            // page: interestedState?.current_page,
            page: state?.currentPage,
            hasInterestSentPermission: hasInterestSentPermission,
            type: 1,
          })
        );
        dispatch(
          getInterestedCount({
            user_id: activeProfile,
            activeFilter: item.value,
            // page: pageReceived,
            // page: interestedState?.current_page,
            page: state?.currentPage,
            hasInterestSentPermission: hasInterestSentPermission,
            type: 2,
          })
        );
        setPage(1);
      }

      setActiveFilter(item.value);
    }
  };

  return {
    filters,
    reportData,
    state,
    activeProfile,
    activeFilter,
    interestedState,
    showScroll,
    handleScrollToTop,
    lastElement,
    handleProfileRedirect,
    handleTabChange,
  };
};

export default useInterested;
