import { toast } from "react-toastify";
import { deleteMember } from "./api";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  members,
  useGetMemberCreationFormDataQuery,
} from "../../../../store/queries/members";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { usersRtk } from "../../../../store/queries/users";

const useDeleteModal = ({ block, selectedUser, handleClose }) => {
  const dispatch = useDispatch();
  const [showStaffPassword, setShowStaffPassword] = useState(true);
  const { data: optionsData = {} } = useGetMemberCreationFormDataQuery({});

  let deleteValidationSchema = Yup.object({
    delete_reason_id: Yup.object().required("*Required"),
    staff_password: Yup.string().required("*Required"),
  });

  const formik = useFormik({
    initialValues: {
      delete_reason_id: "",
      staff_password: "",
    },

    validationSchema: deleteValidationSchema,

    onSubmit: (values, { resetForm }) => {
      try {
        let formData = new FormData();

        formData.append("_method", "PUT");
        formData.append("member_id", selectedUser);
        formData.append("delete_reason_id", values?.delete_reason_id?._id);
        formData.append("password", values.staff_password);

        deleteMember(formData).then((response) => {
          if (response?.data?.status_code === 200) {
            resetForm();
            dispatch(members.util.invalidateTags(["Members"]));
            dispatch(usersRtk.util.invalidateTags(["deleted-accounts"]))
            handleClose?.();
            toast.success(response?.data?.message);
          } else {
            formik.setErrors(response?.data?.message);
            toast.error(response?.data?.message);
          }
        });
      } catch (error) {
        toast.error("Something went wrong");
      }
    },
  });

  const handleShowStaffPassword = (e) => {
    e.preventDefault();
    setShowStaffPassword(!showStaffPassword);
    var x = document.getElementById("staff_password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  return {
    formik,
    optionsData: optionsData?.data,
    handleShowStaffPassword,
    showStaffPassword,
  };
};

export default useDeleteModal;
