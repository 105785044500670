import Transfers from "../components/Support/Transfers";
import DataUpdation from "../components/Support/DataUpdation";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import Messages from "../components/Support/Complaints";
import Testimonials from "../components/CMS/Testimonial";
import Deleted from "../components/Users/Deleted";
import Blocked from "../components/Users/Blocked";
import Reported from "../components/Users/Reported";
import Incomplete from "../components/Users/Incomplete";
import Verification from "../components/Users/Verification";
import Members from "../components/Users/Members";
import Staff from "../components/Configure/Staff";
import Roles from "../components/Configure/Roles";
import Packages from "../components/Configure/Packages";
import Adons from "../components/Configure/Adons";
import Branches from "../components/Configure/Branches";
import Diocese from "../components/Configure/Diocese";
import Appearance from "../components/Configure/Appearance";
import Transactions from "../components/Payment/Transactions";
import FollowUp from "../components/Users/FollowUp";
import FAQs from "../components/CMS/FAQ";
import UsersList from "../components/CMS/UsersList";
import HighlightedProfiles from "../components/CMS/HighlightedProfiles";
import Subscribers from "../components/CMS/Subscribers";
import Dashboard from "../components/Dashboard";
import Magazine from "../components/CMS/Magazine";
import States from "../components/Configure/States";
import IncompleteRegistration from "../components/Users/IncompleteRegistration";

const useRoutes = () => {
  const globalState = useSelector((state) => state.global);

  const NotificationComponents = [
    {
      component: <Transfers />,
      permission: "Transfers",
      path: "transfers",
    },
    {
      component: <DataUpdation />,
      permission: "Data Updation",
      path: "data-updation",
    },
    {
      component: <Messages />,
      permission: "Complaints",
      path: "complaints",
    },
  ];
  const DashboardComponent = [
    {
      component: <Dashboard />,
      permission: "Dashboard",
      path: "dashboard",
    },
    {
      component: <Dashboard />,
      permission: "Director Dashboard",
      path: "dashboard/director",
    },
  ];

  const PaymentsComponents = [
    {
      component: <Transactions />,
      permission: "Transactions",
      path: "transactions",
    },
  ];

  const CmsComponents = [
    {
      component: <FAQs />,
      permission: "FAQ",
      path: "faq",
    },
    {
      component: <Testimonials />,
      permission: "Testimonial",
      path: "testimonials",
    },
    {
      component: <UsersList />,
      permission: "User List",
      path: "user-list",
    },
    {
      component: <HighlightedProfiles />,
      permission: "Highlighted Profiles",
      path: "highlighted-profiles",
    },
    {
      component: <Subscribers />,
      permission: "Newsletter Subscriptions",
      path: "newsletter-subscribers",
    },
    {
      component: <Magazine />,
      permission: "List Digital Mgazine",
      path: "magazine",
    },
  ];

  const UsersComponents = [
    {
      component: <Members />,
      permission: "Member",
      path: "",
    },
    {
      component: <Verification />,
      permission: "Verification",
      path: "verification",
    },
    {
      component: <Incomplete />,
      permission: "Incomplete",
      path: "incomplete",
    },
    {
      component: <Reported />,
      permission: "Reported",
      path: "reported",
    },
    {
      component: <Blocked />,
      permission: "Blocked",
      path: "blocked",
    },
    {
      component: <Deleted />,
      permission: "Deleted Accounts",
      path: "deleted-accounts",
    },
    {
      component: <FollowUp />,
      permission: "Follow-up List",
      path: "followup",
    },
    {
      component: <IncompleteRegistration/>,
      permission: "Incomplete Registration",
      path: "in-complete-user",
    },
  ];

  const SettingsComponents = [
    {
      component: <Staff />,
      permission: "Staff",
      path: "staff",
    },
    {
      component: <Roles />,
      permission: "Roles",
      path: "roles",
    },

    {
      component: <Packages />,
      permission: "Packages",
      path: "packages",
    },
    {
      component: <Adons />,
      permission: "Adons",
      path: "adons",
    },
    {
      component: <Branches />,
      permission: "Branches",
      path: "branches",
    },
    {
      component: <Appearance />,
      permission: "Appearance",
      path: "appearance",
    },

    {
      component: <Diocese />,
      permission: "Diocese",
      path: "diocese",
    },

    {
      component: <States />,
      permission: "States",
      path: "states",
    },
  ];

  const drawerMenuPermission = useMemo(() => {
    let menus = globalState.dashboard_permissions?.flatMap?.((menu) => [
      ...menu.permissions.map((sub_menu) => sub_menu.menu_name),
    ]);
    if (!menus) {
      menus = [];
    }
    menus = [...menus, "Appearance"];
    return menus;

    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  function hasPermission(permission) {
    const user = {
      permissions: drawerMenuPermission,
    };

    return user.permissions?.includes(permission);
  }

  const allowedDashboardComponent = DashboardComponent.filter(
    ({ permission }) => hasPermission(permission)
  );

  const allowedNotificationComponent = NotificationComponents.filter(
    ({ permission }) => hasPermission(permission)
  );

  const allowedUserComponent = UsersComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedSettingsComponent = SettingsComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedPaymentsComponent = PaymentsComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedCmsComponent = CmsComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  return {
    allowedNotificationComponent,
    allowedUserComponent,
    allowedDashboardComponent,
    allowedSettingsComponent,
    allowedPaymentsComponent,
    allowedCmsComponent,
  };
};

export default useRoutes;
