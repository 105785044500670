import {
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import MemberCreationForm from "./MemberCreationForm";
import useMembers from "./useMembers";
import AddFormFieldsContainer from "./AddForm";
import TransferForm from "./TransferForm";
import AvatarCardList from "../../Global/AvatarCardList";
import EmptyData from "../../Global/EmptyData";
import Style from "./members.module.scss";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import DatePickerRsuite from "../../Global/CustomDateRangePicker";
import Select from "react-select";

const Members = ({ dashboard = false }) => {
  const {
    members,
    membersState,
    offCanvasRef,
    filters,
    currentPage,
    activeFilter,
    showCreateModal,
    showEditModal,
    hasEditPermission,
    hasCreatePermission,
    paginationOptions,
    isFetching,
    isLoading,
    selectedUser,
    branchesOption,
    staffData,
    isEditProfile,
    actionOptions,
    isUpdateDataSuccess,
    hasExportPermission,
    handleDateRangeChange,
    handleActionChange,
    handleDashboardRedirect,
    handleEditClick,
    handleEditModal,
    handlePagination,
    handlePageSize,
    handleFilter,
    handleDateChange,
    handleSort,
    handleSearch,
    handleCreateClick,
    closeModal,
    closeEditModal,
    getRow,
    updateMemberFields,
    refetch,
    handleClearClick,
    initFollowUp,
    setInitFollowUp,
    date,
    handleTransferClose,
    showTooltip,
    tooltipPosition,
    handleTableFilter,
  } = useMembers({ dashboard });

  const avatarListRef = useRef(null);
  const [avatarListHeight, setAvatarListHeight] = useState();
  useEffect(() => {
    const height = avatarListRef?.current?.getBoundingClientRect()?.height;
    setAvatarListHeight(height);
  }, [showTooltip]);

  return (
    <>
      {!dashboard ? (
        <HeadingGroup
          title={"Members"}
          buttonTitle={hasCreatePermission === 1 ? "Create" : ""}
          className={`pro-mb-4`}
          handleClick={handleCreateClick}
        />
      ) : (
        <></>
      )}

      <div className={`col-auto pro-pt-3`}>
        {!dashboard ? (
          <SearchFilters
            data={filters}
            //showDateRange
            dropDownFilter={
              <>
                <div className="col-auto">
                  <DatePickerRsuite
                    defaultDateRange={date}
                    handleDateRangeChange={handleDateRangeChange}
                  />
                </div>
                <div className="col-2 payment-filter">
                  <Select
                    id="branch"
                    placeholder={"Select Branch"}
                    className={`pro-input lg`}
                    classNamePrefix="pro-input"
                    options={branchesOption}
                    getOptionValue={(option) => option?._id}
                    getOptionLabel={(option) => option?.name}
                    onChange={(value) => {
                      handleTableFilter(value);
                    }}
                  />
                </div>
              </>
            }
            activeFilter={activeFilter}
            onDateChange={handleDateChange}
            handleButtonGroupChange={handleFilter}
            onSearchInput={handleSearch}
            loading={isLoading}
            showActions={false}
            handleActionClick={handleEditModal}
            // //showClearFilters
            handleClear={handleClearClick}
            initialDateRange={date}
            searchInputProps={{ value: membersState?.search }}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
            actionOptions={actionOptions?.filter((item) =>
              hasExportPermission !== 1 ? item.value === 0 : true
            )}
            onActionOptionChange={handleActionChange}
          />
        ) : (
          <></>
        )}

        {members?.data?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <div
            onClick={
              dashboard ? () => handleDashboardRedirect(activeFilter) : () => {}
            }
            className={
              !dashboard ? "pro-pt-3 pro-pb-6" : `${Style.dashboardTable}`
            }
          >
            <Table
              data={members?.data?.data?.data || []}
              isSelectBox={dashboard ? false : true}
              uniqueID={"_id"}
              fields={members?.data?.fields}
              multiSelect={false}
              showCheckBox={
                hasEditPermission === 1 && !dashboard ? true : false
              }
              clear={membersState.clearSelection}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              handleEdit={handleEditClick}
              perpage={membersState?.currentPageSize}
              assignable={false}
              deletable={false}
              extraClasssName={"dashboard_no_padding"}
              propStyle={{
                selectBox_table: Style.selectBox_table,
              }}
            />
          </div>
        )}

        {members?.data?.data?.data?.length > 0 && !dashboard && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              members.data.data.total / membersState.currentPageSize
            )}
            //  totalPageCount={members?.data?.data?.last_page}
            onPageChange={handlePagination}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === membersState.currentPageSize
            )}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        {!dashboard ? (
          <>
            {" "}
            <ModalLayout
              title={"Your Assign is transfering "}
              show={membersState?.showTransferModal}
              handleClose={handleTransferClose}
            >
              <div className="pro-m-5">
                <TransferForm refetch={refetch} />
              </div>
            </ModalLayout>
            <OffCanvasLayout
              show={showCreateModal}
              handleClose={closeModal}
              title={`${
                isEditProfile ? "Update Member Details" : "Member registration"
              }`}
              closeIcon={
                <span className="material-symbols-outlined"> close </span>
              }
              ref={offCanvasRef}
            >
              <MemberCreationForm
                selectedUser={selectedUser}
                isUpdateDataSuccess={isUpdateDataSuccess}
              />
            </OffCanvasLayout>
            <ModalLayout
              title={"Initiate Follow-up"}
              show={initFollowUp}
              handleClose={() => setInitFollowUp(false)}
            >
              <div className="pro-m-5">
                <AddFormFieldsContainer
                  setShowform={setInitFollowUp}
                  refetch={refetch}
                />
              </div>
            </ModalLayout>
            <ModalLayout show={showEditModal} handleClose={closeEditModal}>
              <div className="pro-m-5">
                <OrderColumn
                  title={"Choose which columns you see"}
                  refetch={refetch}
                  tableFields={members?.data?.fields}
                  moduleId={members?.data?.module_id}
                  updateData={updateMemberFields}
                />
              </div>
            </ModalLayout>
            <div
              className={`
                ${Style.avatar_list_container}  
                ${showTooltip ? Style.active : ""} 
                ${
                  tooltipPosition?.placementY === "top"
                    ? Style.top
                    : Style.bottom
                }
              `}
              ref={avatarListRef}
              style={{
                "--top": tooltipPosition.y,
                "--left": tooltipPosition.x,
                "--card-height": avatarListHeight,
              }}
            >
              <AvatarCardList staffData={staffData} />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Members;
