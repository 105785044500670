import { toast } from "react-toastify";
import { blockUser, unBlockUser } from "./api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { members } from "../../../../store/queries/members";
import { useDispatch } from "react-redux";

const useBlockModal = ({
  block,
  setShowBlockedModal,
  selectedUser,
  handleClose,
}) => {
  const dispatch = useDispatch();

  let blockValidationSchema = Yup.object({
    reason: Yup.string().required("*Required"),
    duration: Yup.number()
      .typeError("duration must be a number")
      .max(180, "The duration must not be greater than 180")
      .required("*Required"),
  });

  let unBlockValidationSchema = Yup.object({
    reason: Yup.string().required("*Required"),
  });

  const formik = useFormik({
    initialValues: {
      reason: "",
      duration: "",
    },

    validationSchema: block ? blockValidationSchema : unBlockValidationSchema,

    onSubmit: (values, { resetForm }) => {
      try {
        if (block) {
          let obj = {
            member_id: selectedUser,
            reason: values.reason,
            status: 1,
            duration: Number(values.duration),
          };

          blockUser(obj).then((response) => {
            if (response?.data?.status_code === 200) {
              dispatch(members.util.invalidateTags(["Reported"]));
              dispatch(members.util.invalidateTags(["Blocked"]));
              resetForm();
              handleClose?.();
              toast.success(response?.data?.message);
            } else if (response?.status_code === 400) {
              formik.setErrors(response?.message);
              toast.error("Failed to Block");
            } else {
              toast.error("Failed to Block");
            }
          });
        } else {
          let formData = new FormData();
          formData.append("_method", "PUT");
          formData.append("_id", selectedUser);
          formData.append("reason", values.reason);
          formData.append("status", 0);
          unBlockUser(formData).then((response) => {
            if (response?.data?.status_code === 200) {
              dispatch(members.util.invalidateTags(["Blocked"]));
              dispatch(members.util.invalidateTags(["Members"]));

              resetForm();
              handleClose?.();
              toast.success(response?.data?.message);
            } else if (response?.status_code === 400) {
              formik.setErrors(response?.message);
              toast.error("Failed to Unblock");
            } else {
              toast.error("Failed to Unblock");
            }
          });
        }
      } catch (error) {
        toast.error("Something went wrong");
      }
    },
  });
  const handleCloseModal = () => {
    setShowBlockedModal(() => false);
  };
  //   const useDataOptions = useMemo(
  //     () =>
  //       userData?.map((opt) => {
  //         return { value: opt.value, label: opt.value, id: opt._id };
  //       }),
  //     [userData]
  //   );

  //   const handleInputChange = (item) => {
  //     if (item?.length >= 4) {
  //       getUserDetails({ search: item }).then((response) => {
  //         setUserData(response?.data?.data);
  //       });
  //     }
  //   };

  return { formik, handleCloseModal };
};

export default useBlockModal;
