import { useGetOverviewDataQuery } from "../../../../store/queries/Profile";

const useExportProfileDetails = (props) => {
  const { data: profileData } = useGetOverviewDataQuery({
    user_id: props?.activeProfile,
  });

  const numberOrdinals = { 1: "", 0: "", all: "s" };

  const getOrdinalNumbers = (no) => {
    return `${numberOrdinals?.[no] ?? numberOrdinals["all"]}`;
  };
  return {
    profileData,
    getOrdinalNumbers,
  };
};

export default useExportProfileDetails;
