import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";
import { toast } from "react-toastify";
import { subDays } from "date-fns";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  startDate: subDays(new Date(), 30),
  endDate: new Date(),
  sortBy: "",
  sortOrder: "desc",
  search: "",
  profileDataStatus: "idle",
  profileData: {},
  profileRejectResponse: [],
  updateProfileStatus: "idle",
  branchId: "",
};

/**
 * @example {
 *  "user_id": "63db4a0d5b797f97f3020066",
 *  "upload_type": 1
 *  }
 */
export const getVerificationData = createAsyncThunk(
  "memberVerification/getVerificationData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/admin/members/uploads`, params);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

/**
 * @example {
 *  "upload_type":1,
 *  "upload_id":"63da14598ba3329fab0203c9",
 *  "status":2,
 *  "reason":"63eb5a77305df8b4ff078673"
 *  }
 */
export const updateVerificationDataStatus = createAsyncThunk(
  "memberVerification/updateVerificationDataStatus",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.put(
        `/admin/members/uploads/status/change`,
        params
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const membersVerificationSlice = createSlice({
  name: "memberVerification",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    [getVerificationData.pending]: (state) => {
      state.profileDataStatus = "pending";
    },
    [getVerificationData.fulfilled]: (state, action) => {
      const userID =
        JSON.parse(action.payload?.config?.data)?.user_id || "user_id";

      state.profileDataStatus = "success";

      state.profileData = {
        ...state.profileData,
        [userID]: action.payload.data?.data?.data,
      };

      state.profileRejectResponse =
        action.payload?.data?.data?.rejection_reasons || [];
    },
    [getVerificationData.rejected]: (state) => {
      state.profileDataStatus = "failed";
    },
    [updateVerificationDataStatus.pending]: (state) => {
      state.updateProfileStatus = "pending";
    },
    [updateVerificationDataStatus.fulfilled]: (state, action) => {
      state.updateProfileStatus = "success";

      const userID = JSON.parse(action.payload?.config?.data)?.user_id;
      const imageID = JSON.parse(action.payload?.config?.data)?.upload_id;
      const status = JSON.parse(action.payload?.config?.data)?.status;

      const imageIndex = state.profileData[userID].findIndex(
        (img) => img._id === imageID
      );

      state.profileData[userID][imageIndex].status = status;

      toast.success("Status updated successfully");
    },
    [updateVerificationDataStatus.rejected]: (state) => {
      state.updateProfileStatus = "failed";
    },
  },
});

export const { getCachedData, updateConfig } = membersVerificationSlice.actions;

export default membersVerificationSlice.reducer;
