import { useFormik } from "formik";
import * as Yup from "yup";
import { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useGetDioceseCommunityListDataQuery } from "../../../../store/queries/settings";
import { useSelector } from "react-redux";
import {
  saveDiocesesDetails,
  updateConfig,
  updateDiocesesDetails,
} from "../../../../store/slices/Settings/dioceseSlice";

const useAddBranchForm = (setShowform, refetch) => {
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [options, setOptions] = useState("");

  const imageFileInputRef = useRef(null);

  const dioceseListingPage = useSelector((state) => state.dioceseListingPage);

  const dispatch = useDispatch();

  let validationSchema = Yup.object({
    dioceseName: Yup.string().required("Required"),
    dioceseCommunity: Yup.object().required("Required"),
    dioceseCode: Yup.string()
      .required("Required")
      .min(3, "The shortcode must not be less than 3 characters.")
      .max(3, "The shortcode must not be greater than 3 characters.")
      .matches(/[a-zA-Z]/, "The shortcode format is invalid."),
  });

  const { data: communityList = {} } = useGetDioceseCommunityListDataQuery();

  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    setOptions(
      communityList?.data?.community?.map((item) => {
        return { value: item._id, label: item.name };
      })
    );
    // eslint-disable-next-line
  }, [communityList]);

  const formik = useFormik({
    initialValues: {
      dioceseName:
        dioceseListingPage?.editDiocese === true
          ? dioceseListingPage?.selectedDiocese?.name
          : "",
      dioceseCommunity:
        dioceseListingPage?.editDiocese === true
          ? dioceseListingPage?.selectedDiocese
          : "",
      dioceseCode:
        dioceseListingPage?.editDiocese === true
          ? dioceseListingPage?.selectedDiocese?.shortcode
          : "",
    },

    validationSchema: validationSchema,

    onSubmit: (values, { resetForm, setFieldError }) => {
      handleFormSubmit(values, resetForm, setFieldError);
    },
  });

  let selectedCommunityId = communityList?.data?.community?.filter(
    (p) =>
      p?.unique_community_id?.toString() ===
      formik.values.dioceseCommunity?.unique_community_id?.toString()
  )?.[0]?._id;

  const handleFormSubmit = async (values, resetForm, setFieldError) => {
 
    try {
      if (dioceseListingPage?.editDiocese === false) {
        try {
          let obj = {
            name: values.dioceseName,
            community_id: selectedCommunityId,
            shortcode: values.dioceseCode,
          };

          dispatch(saveDiocesesDetails(obj))
            .unwrap()
            .then((data) => {
              setShowform(false);
              refetch?.();
              toast.success("Successfully submitted");
            })
            .catch((err) => {
              if (err?.message?.shortcode?.[0] !== "") {
                setFieldError("dioceseCode", err.message.shortcode[0]);
              }
            });
        } catch (error) {}
      } else if (dioceseListingPage?.editDiocese === true) {
        try {
          let obj = {
            diocese_id: dioceseListingPage?.selectedDiocese._id,
            name: values.dioceseName,
            community_id: selectedCommunityId,
            shortcode: values.dioceseCode,
          };

          dispatch(updateDiocesesDetails(obj))
            .unwrap()
            .then((data) => {
              setShowform(false);
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );
              refetch?.();
              toast.success("Details updated Successfully.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            })
            .catch((err) => {
              if (err?.message?.shortcode?.[0] !== "") {
                setFieldError("dioceseCode", err.message.shortcode[0]);
              }
            });
        } catch (error) {}
      }
    } catch (error) {
      setErrors("Something went wrong!");
    }
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  return {
    errors,
    message,
    setMessage,
    formik,
    loading,
    communityList,
    setLoading,
    handleCloseModal,
    imageFileInputRef,
    selectedOption,
    setSelectedOption,
    options,
  };
};

export default useAddBranchForm;
