import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();

    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("start")}${getParams("end")}${getParams(
          "filter"
        )}${getParams("sort_by")}sort_order=${
          params?.sort_order || "desc"
        }&page_size=${params?.page_size || "10"}&${getParams("search")}page=${
          params?.page || 1
        }&${getParams("branch_id")}`,
        body
      );
      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const members = createApi({
  reducerPath: "membersApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "Members",
    "Verification",
    "AllStaffs",
    "Incomplete",
    "Blocked",
    "Reported",
    "FormData",
    "IncompleteUser"
  ],
  endpoints: (builder) => ({
    /***********
     * Members *
     ***********/

    getMembersListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/members/list`,
      }),

      providesTags: ["Members"],
    }),

    getMemberCreationFormData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/members/create`,
      }),

      providesTags: ["FormData"],
    }),

    createProfile: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/members/create`,
      }),
    }),

    updateMembersListData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user/fields/update`,
      }),

      invalidatesTags: ["Members"],
    }),

    /***************
     * Verification *
     ****************/

    getMembersVerificationData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/members/verification/list`,
      }),
      providesTags: ["Verification"],
    }),

    updateVerificationListData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user/fields/update`,
      }),

      invalidatesTags: ["Verification"],
    }),

    /***************
     * Incomplete *
     ****************/

    getIncompletedListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/members/incomplete`,
      }),
      providesTags: ["Incomplete"],
    }),
    updateIncompletedListData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user/fields/update`,
      }),

      invalidatesTags: ["Incomplete"],
    }),

    /***********
     * BLOCKED *
     ***********/

    getBlockedListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/members/blocked`,
      }),
      providesTags: ["Blocked"],
    }),
    updateBlockedListData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user/fields/update`,
      }),

      invalidatesTags: ["Blocked"],
    }),

    /***********
     * REPORTED *
     ***********/

    getReportedListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/members/reported`,
      }),
      providesTags: ["Reported"],
    }),
    updateReportedListData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user/fields/update`,
      }),

      invalidatesTags: ["Reported"],
    }),
    generateOtp: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/otp/generate`,
      }),
    }),
    validateOtp: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/otp/validate`,
      }),
    }),

    getAllStaffList: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/staffs/list/all` /*type=1,2*/,
      }),
      providesTags: ["AllStaffs"],
    }),

    updateMemberStaffAssignData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/staffs/assign`,
      }),
      invalidatesTags: ["Members", "AllStaffs"],
    }),
    updateMemberTransferData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/staffs/transfer`,
      }),
      invalidatesTags: ["Members"],
    }),

    getIncompleteRegistersData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/temporary-user/incomplete-registration`,
      }),
      invalidatesTags: ["Members","IncompleteUser"],
      keepUnusedDataFor:0,
    }),
    updateIncompleteMemberStaffAssignData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/temporary-user/assign`,
      }),
      invalidatesTags: ["Members", "AllStaffs"],
    }),
  }),
});

export const {
  useGetMembersListDataQuery,
  useCreateProfileMutation,
  useGetMembersVerificationDataQuery,
  useGetIncompletedListDataQuery,
  useGetBlockedListDataQuery,
  useGetReportedListDataQuery,
  useUpdateMembersListDataMutation,
  useUpdateReportedListDataMutation,
  useUpdateBlockedListDataMutation,
  useUpdateVerificationListDataMutation,
  useUpdateIncompletedListDataMutation,
  useGetMemberCreationFormDataQuery,
  useGenerateOtpMutation,
  useValidateOtpMutation,
  useUpdateMemberStaffAssignDataMutation,
  useUpdateMemberTransferDataMutation,
  useGetAllStaffListQuery,
  useGetIncompleteRegistersDataQuery,
  useUpdateIncompleteMemberStaffAssignDataMutation
} = members;
