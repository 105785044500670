import { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { getFormatedDate } from "../../../utils/functions/table";
import { subDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { getExportedData } from "./api";
import { updateConfig } from "../../../store/slices/CMS/magazineSlice";
import {
  useGetMagazineListDataQuery,
  useGetMagazineViewDataQuery,
  useUpdateMagazineListFieldsDataMutation,
} from "../../../store/queries/cms";
import { useGetMemberCreationFormDataQuery } from "../../../store/queries/members";

const useMagazine = (dashboard) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const magazineState = useSelector((state) => state.magazine);

  const [selectedId, setSelectedId] = useState([]);
  const [skip, setSkip] = useState(true);

  const { showCreateModal, magazineId } = useSelector(
    (state) => state.magazine
  );
  const { tableFields, showEditModal } = useSelector((state) => state.global);

  const [showImageModal, setShowImageModal] = useState(false);

  const [imageData, setImageData] = useState("");

  const {
    data: magazine = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetMagazineListDataQuery({
    sort_by: magazineState.sortBy,
    sort_order: magazineState.sortOrder,
    search: magazineState.search,
    start: dashboard ? "" :getFormatedDate(magazineState.startDate),
    end: dashboard ? "" :getFormatedDate(magazineState.endDate),
    page_size: magazineState.currentPageSize,
    page: magazineState.currentPage,
  });

  useEffect(() => {
    if (magazineState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [magazineState.clearSelection]);

  const { data: magazineViewData = {} } = useGetMagazineViewDataQuery(
    {
      digital_magazine_id: selectedId,
      page: 1,
    },
    { skip }
  );

  const { data: optionsData = {} } = useGetMemberCreationFormDataQuery({});

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [date, setDate] = useState({
    startDate: magazineState.startDate,
    endDate: magazineState.endDate,
    key: "selection",
  });

  const [updateMagazineFields] = useUpdateMagazineListFieldsDataMutation();

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = magazine?.data?.fields;
      })
    );

    // eslint-disable-next-line
  }, [magazine]);

  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = magazineViewData?.data ?? "";
      })
    );

    // eslint-disable-next-line
  }, [magazineViewData]);

  const hasExportPermission = useMemo(() => {
    let permission = magazine?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_export")
    );

    return permission?.[0]?.can_export ?? 0;
  }, [magazine]);

  const hasCreatePermission = useMemo(() => {
    let permission = magazine?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permission?.[0]?.can_create ?? 0;
  }, [magazine]);

  const hasEditPermission = useMemo(() => {
    let permission = magazine?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );
    return permission?.[0]?.can_edit;
    // eslint-disable-next-line
  }, [magazine]);

  const hasUpdatePermission = useMemo(() => {
    let permission = magazine?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_update")
    );
    return permission?.[0]?.can_update;
    // eslint-disable-next-line
  }, [magazine]);

  const handleSort = (label) => {
    if (magazineState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = magazineState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );

    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        // state.selectedItemsDetails = ""
      })
    );
  };

  const handleEditAction = (data) => {
    setSkip(() => false);
    setSelectedId(data?.[0]);

    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = magazineViewData?.data ?? "";
      })
    );
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    setSkip(() => true);
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      cover_photo: (feild, data) =>
        !dashboard ? (
          <p className="pro-mb-0 pro-pnt" onClick={() => handleViewImage(data)}>
            {data[feild].name}
          </p>
        ) : (
          <></>
        ),
      magazine: (feild, data) =>
        !dashboard ? (
          <a
            className="pro-mb-0 pro-pnt"
            href={`${data?.magazine?.url}`}
            target="_blank"
            rel="noreferrer"
          >
            {data[feild].name}
          </a>
        ) : (
          <></>
        ),
      month: (feild, data) =>
        !dashboard ? (
          <p className="pro-mb-0">
            {
              optionsData?.data?.months?.filter(
                (m) => data?.[feild] === m?.id
              )?.[0]?.name
            }
          </p>
        ) : (
          <></>
        ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    setImageData(data?.cover_photo?.url);
  };

  const closeImageModal = () => {
    setShowImageModal(() => false);
    setImageData("");
  };

  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = range.startDate;
        state.endDate = range.endDate;
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    } else {
      getExportedData({
        fields: Object.keys(magazine?.data?.fields),
        filter: magazineState.currentFilter,
        sort_by: magazineState.sortBy,
        sort_order: magazineState.sortOrder,
        start: getFormatedDate(magazineState.startDate),
        end: getFormatedDate(magazineState.endDate),
        search: magazineState.search,
      }).then((response) => {
        let dataType = response?.data?.type;
        let binaryData = [];
        binaryData.push(response?.data);
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute(
          "download",
          `${moment(Date.now()).format("MM_DD_YYYY_HH_mm_ss")}.xlsx`
        );
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.startDate = subDays(new Date(), 30)
        state.endDate = new Date()
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.magazineId = "";
        state.invoiceLoading = false;
      })
    );
  };

  const handleDashboardRedirect = (filter) => {
    navigate("/payment/magazine");
  };

    const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
      })
    );
  };

  return {
    magazine,
    magazineState,
    currentPage: magazineState.currentPage,
    showCreateModal,
    hasCreatePermission,
    isFetching,
    isLoading,
    date,
    optionsData,
    showEditModal,
    tableFields,
    paginationOptions,
    magazineId,
    actionOptions,
    magazineViewData,
    hasExportPermission,
    showImageModal,
    imageData,
    hasEditPermission,
    hasUpdatePermission,
    handleDateRangeChange,
    handleEditAction,
    closeImageModal,
    handleDashboardRedirect,
    handleActionChange,
    refetch,
    updateMagazineFields,
    handleSort,
    handleSearch,
    handleCreateClick,
    closeModal,
    getRow,
    handleEditClick,
    closeEditModal,
    handleDateChange,
    handlePagination,
    handlePageSize,
    handleClearClick,
  };
};

export default useMagazine;
