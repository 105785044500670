const useProfilePreview = ({ profileData }) => {
  const educationLabel = {
    education_cat: "Education Category",
    education: "Education",
    education_details: "Education Details",
    institution: "Institution",
  };

  const educationValue = {
    education_cat: `${
      profileData?.data?.data?.education_category?.name ?? "Not specified"
    }`,
    education: `${profileData?.data?.data?.education?.name ?? "Not specified"}`,
    education_details: `${
      profileData?.data?.data?.education_detail ?? "Not specified"
    }`,
    institution: `${
      profileData?.data?.data?.institution_name ?? "Not specified"
    }`,
  };

  const educationDetails = Object.keys(educationValue).map((key) => {
    return {
      label: educationLabel[key],
      value:
        typeof educationValue[key] === "string"
          ? educationValue?.[key]
          : typeof educationValue[key] === "number"
          ? educationValue?.[key]
          : educationValue?.[key]?.name,
    };
  });

  const occupationLabel = {
    emp_sector: "Employement Sector",
    occupation_cat: "Occupation Category",
    occupation: "Occupation",
    occupation_details: "Occupation Details",
    organization: "Firm Name",
    work_location: "Work Location",
    income: "Annual Income",
  };

  const workDistrict = profileData?.data?.data?.work_district?.name ?? "";
  const workState = profileData?.data?.data?.work_state?.name ?? "";
  const workCountry = profileData?.data?.data?.work_country?.name ?? "";

  const workAddressFields = [workDistrict, workState, workCountry];
  const workAddressStr = workAddressFields.filter(Boolean).join(", ");

  const occupationValue = {
    emp_sector: `${
      profileData?.data?.data?.employment_sector?.name ?? "Not specified"
    }`,
    occupation_cat: `${
      profileData?.data?.data?.occupation_category?.name ?? "Not specified"
    }`,
    occupation: `${
      profileData?.data?.data?.occupation?.name ?? "Not specified"
    }`,
    occupation_details: `${
      profileData?.data?.data?.occupation_detail ?? "Not specified"
    }`,
    organization: `${profileData?.data?.data?.organization ?? "Not specified"}`,
    work_location: workAddressStr || "Not specified",
    income: `${
      profileData?.data?.data?.annual_income?.value ?? "Not specified"
    } `,
  };

  const occupationDetails = Object.keys(occupationValue).map((key) => {
    return {
      label: occupationLabel[key],
      value:
        typeof occupationValue[key] === "string"
          ? occupationValue?.[key]
          : typeof occupationValue[key] === "number"
          ? occupationValue?.[key]
          : occupationValue?.[key]?.name,
    };
  });

  const numberOrdinals = { 1: "", 0: "", all: "s" };

  const getOrdinalNumbers = (no) => {
    return `${numberOrdinals?.[no] ?? numberOrdinals["all"]}`;
  };

  const familyLabel = {
    father_name: "Father's Name",
    father_housename: "Father’s House Name",
    father_occupation: "Father's Occupation",
    father_occupation_details: "Father’s Occupation Details",
    father_native_place:"Father’s Native place",
    mother_name: "Mother's Name",
    mother_housename: "Mother's House Name",
    mother_occupation: "Mother's Occupation",
    mother_occupation_details: "Mother's Occupation Details",
    mother_native_place:"Mother’s Native place",
    fam_status: "Family Status",
    fam_type: "Family Type",
    
    no_of_brothers: `No. of Brothers `,
    no_of_brothers_married: `Brothers Married`,
    no_of_sisters: `No. of Sisters`,
    no_of_sisters_married: `Sisters Married`,
    no_of_priests: `No. of Priests`,
    no_of_nuns: `No. of Nuns`,
  };

  const familyValue = {
    father_name: `${
      profileData?.data?.data?.father_details?.name ?? "Not specified"
    }`,
    father_housename: `${
      profileData?.data?.data?.father_details?.house_name ?? "Not specified"
    }`,
    father_occupation: `${
      profileData?.data?.data?.father_details?.occupation?.name ??
      "Not specified"
    }`,
    father_occupation_details: `${
      profileData?.data?.data?.father_details?.occupation_detail ??
      "Not specified"
    }`,
    father_native_place: `${
      profileData?.data?.data?.father_details?.native_place ??
      "Not specified"
    }`,
    mother_name: `${
      profileData?.data?.data?.mother_details?.name ?? "Not specified"
    }`,
    mother_housename: `${
      profileData?.data?.data?.mother_details?.house_name ?? "Not specified"
    }`,
    mother_occupation: `${
      profileData?.data?.data?.mother_details?.occupation?.name ??
      "Not specified"
    }`,
    mother_occupation_details: `${
      profileData?.data?.data?.mother_details?.occupation_detail ??
      "Not specified"
    }`,
    mother_native_place: `${
      profileData?.data?.data?.mother_details?.native_place ??
      "Not specified"
    }`,
    fam_status: `${
      profileData?.data?.data?.family_status?.name ?? "Not specified"
    }`,
    fam_type: `${
      profileData?.data?.data?.family_type?.name ?? "Not specified"
    }`,
    
    no_of_brothers: `${
      profileData?.data?.data?.sibling_details?.no_of_brothers ??
      "Not specified"
    }`,
    no_of_brothers_married: `${
      profileData?.data?.data?.sibling_details?.brothers_married ??
      "Not specified"
    }`,
    no_of_sisters: `${
      profileData?.data?.data?.sibling_details?.no_of_sisters ?? "Not specified"
    }`,
    no_of_sisters_married: `${
      profileData?.data?.data?.sibling_details?.sisters_married ??
      "Not specified"
    }`,
    no_of_priests: `${
      profileData?.data?.data?.no_of_priests ??
      "Not specified"
    }`,
    no_of_nuns: `${
      profileData?.data?.data?.no_of_nuns ??
      "Not specified"
    }`,
  };
  const familyDetails = Object.keys(familyValue).map((key) => {
    return {
      label: familyLabel[key],
      value:
        typeof familyValue[key] === "string"
          ? familyValue?.[key]
          : typeof familyValue[key] === "number"
          ? familyValue?.[key]
          : familyValue?.[key]?.name,
    };
  });

  const contactLabel = {
    present_address: "Present Address",
    permanent_address: "Permanent Address",
    contact_person_1: "Contact Person 1",
    contact_person_2: "Contact Person 2",
    contact_person_3: "Contact Person 3",
  };

  const presentAddress = profileData?.data?.data?.present_address;
  const permanentAddress = profileData?.data?.data?.permanent_address;
  const communicationDetails = profileData?.data?.data?.communication_details;

  const presentHouseName =  presentAddress?.house_name === "null" ? "" : presentAddress?.house_name ?? "";
  const presentStreet = presentAddress?.street === "null" ? "" : presentAddress?.street
  const presentDistrict = presentAddress?.district?.name ?? "";
  const presentState = presentAddress?.state?.name ?? "";
  const presentCountry = presentAddress?.country?.name ?? "";
  const presentPincode = presentAddress?.pincode === "null" ? "" : presentAddress?.pincode

  const permanentHouseName = permanentAddress?.house_name === "null" ? "" : permanentAddress?.house_name ?? "";
  const permanentStreet = permanentAddress?.street === "null" ? "" : permanentAddress?.street
  const permanentDistrict = permanentAddress?.district?.name ?? "";
  const permanentState = permanentAddress?.state?.name ?? "";
  const permanentCountry = permanentAddress?.country?.name ?? "";
  const permanentPincode = permanentAddress?.pincode === "null" ? "" : permanentAddress?.pincode

  const communicationName1 = communicationDetails?.[0]?.name ?? "";
  const communicationCode1 = communicationDetails?.[0]?.country_code ?? "";
  const communicationPhone1 = communicationDetails?.[0]?.phone_number ?? "";
  const communicationRelation1 =
    communicationDetails?.[0]?.relationship?.name ?? "";

  const communicationName2 = communicationDetails?.[1]?.name ?? "";
  const communicationCode2 = communicationDetails?.[1]?.country_code ?? "";
  const communicationPhone2 = communicationDetails?.[1]?.phone_number ?? "";
  const communicationRelation2 =
    communicationDetails?.[1]?.relationship?.name ?? "";

  const communicationName3 = communicationDetails?.[2]?.name ?? "";
  const communicationCode3 = communicationDetails?.[2]?.country_code ?? "";
  const communicationPhone3 = communicationDetails?.[2]?.phone_number ?? "";
  const communicationRelation3 =
    communicationDetails?.[2]?.relationship?.name ?? "";

  const presentAddressFields = [
    presentHouseName,
    presentStreet,
    presentDistrict,
    presentState,
    presentCountry,
    presentPincode,
  ];
  const permanentAddressFields = [
    permanentHouseName,
    permanentStreet,
    permanentDistrict,
    permanentState,
    permanentCountry,
    permanentPincode,
  ];

  const contactDetailsFields1 = [
    communicationName1,
    communicationCode1 + "" + communicationPhone1,
    communicationRelation1,
  ];

  const contactDetailsFields2 = [
    communicationName2,
    communicationCode2 + "" + communicationPhone2,
    communicationRelation2,
  ];

  const contactDetailsFields3 = [
    communicationName3,
    communicationCode3 + "" + communicationPhone3,
    communicationRelation3,
  ];

  const presentAddressStr = presentAddressFields.filter(Boolean).join(", ");
  const permanentAddressStr = permanentAddressFields.filter(Boolean).join(", ");
  const contactPerson1Str = contactDetailsFields1.filter(Boolean).join(", ");
  const contactPerson2Str = contactDetailsFields2.filter(Boolean).join(", ");
  const contactPerson3Str = contactDetailsFields3.filter(Boolean).join(", ");

  const contactValue = {
    present_address: presentAddressStr || "Not specified",

    permanent_address: permanentAddressStr || "Not specified",
    contact_person_1: contactPerson1Str || "Not specified",
    contact_person_2: contactPerson2Str || "Not specified",
    contact_person_3: contactPerson3Str || "Not specified",
  };
  const contactDetails = Object.keys(contactValue).map((key) => {
    return {
      label: contactLabel[key],
      value:
        typeof contactValue[key] === "string"
          ? contactValue?.[key]
          : typeof contactValue[key] === "number"
          ? contactValue?.[key]
          : contactValue?.[key]?.name,
    };
  });


  const label = {
    marital_status: "Marital Status",
    gender: "Gender",
    community: "Community",
    diocese: "Diocese",
    parish: "Parish",
    country: "Country",
    state: "State",
    district: "District",
    phone: "Mobile",
    email: "Email",
    age_and_dob: "Age & Date Of Birth",
    no_of_children: `No. of Children `,
    weight: "Weight",
    height: "Height",
    body_type: "Body Type",
    physical_status: "Physical Status",
    complexion: "Complexion",
    profile_created: "Profile Created By",
    profile_created_at: "Profile Created At",
  };

  const value = {
    phone: `${profileData?.data?.data?.phone_number ?? "Not specified"}`,
    email: `${profileData?.data?.data?.email ?? "Not specified"}`,
    gender: `${profileData?.data?.data?.gender?.name ?? "Not specified"}`,
    marital_status: `${
      profileData?.data?.data?.marital_status?.name ?? "Not specified"
    }`,
    no_of_children: "",
    age_and_dob: `${profileData?.data?.data?.age ?? "Not specified"}, ${
      profileData?.data?.data?.dob ?? ""
    }`,
 
    community: `${
      profileData?.data?.data?.community_id?.name ?? "Not specified"
    }`,
    diocese: `${profileData?.data?.data?.diocese_id?.name ?? "Not specified"}`,
    parish: `${profileData?.data?.data?.parish_id?.full_name ?? "Not specified"}`,
    country: `${profileData?.data?.data?.country?.name ?? "Not specified"}`,
    state: `${profileData?.data?.data?.state?.name ?? "Not specified"}`,
    district: `${profileData?.data?.data?.district?.name ?? "Not specified"}`,
    weight: profileData?.data?.data?.weight ?? "Not specified",
    height: profileData?.data?.data?.height ?? "Not specified",
    body_type: `${profileData?.data?.data?.body_type?.name ?? "Not specified"}`,
    physical_status:
      profileData?.data?.data?.physical_status ?? "Not specified",
    complexion: `${
      profileData?.data?.data?.body_complexion?.name ?? "Not specified"
    }`,
    profile_created:
      profileData?.data?.data?.profile_created_by ?? "Not specified",
    profile_created_at:
      profileData?.data?.data?.created_date ?? "Not specified",
  };


  if (
    profileData?.data?.data?.marital_status !== "" &&
    profileData?.data?.data?.marital_status &&
    profileData?.data?.data?.marital_status?.name !== "Unmarried"
  ) {
    value.no_of_children = `${
      profileData?.data?.data?.no_of_children ?? "Not specified"
    }`;
  } else {
    delete value.no_of_children;
  }

  const basicDetails = Object.keys(value).map((key) => {
    return {
      label: label[key],
      value:
        typeof value[key] === "string"
          ? value?.[key]
          : typeof value[key] === "number"
          ? value?.[key]
          : value?.[key]?.name,
    };
  });

  return {
    educationDetails,
    occupationDetails,
    contactDetails,
    familyDetails,
    basicDetails,
  };
};

export default useProfilePreview;
