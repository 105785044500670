import Style from './invoiceShimmer.module.scss';

const InvoiceShimmer = () => {
  return (
    <div className='pro-w-100 pro-pt-3'>
        <div className="pro-py-3 pro-px-6 shimmer pro-mb-5"></div>
        <div className="row">
            <div className="col-6 pro-d-flex pro-flex-column pro-gap-1 pro-mb-5 ">
                <div className='pro-d-inline-flex'><span className="shimmer pro-px-5 pro-py-1 "></span></div>
                <div className='pro-d-inline-flex'><span className="shimmer pro-px-6  pro-py-2"></span></div>
            </div>
            <div className="col-6 pro-d-flex pro-flex-column pro-gap-1 pro-mb-5 ">
                <div className='pro-d-inline-flex'><span className="shimmer pro-px-5 pro-py-1 "></span></div>
                <div className='pro-d-inline-flex'><span className="shimmer pro-px-6  pro-py-2"></span></div>
            </div>
            <div className="col-6 pro-d-flex pro-flex-column pro-gap-1 pro-mb-5 pro-py-1 ">
                <div className='pro-d-inline-flex'><span className="shimmer pro-px-5 pro-py-1 "></span></div>
                <div className='pro-d-inline-flex'><span className="shimmer pro-px-6  pro-py-2"></span></div>
            </div>
            <div className="col-6 pro-d-flex pro-flex-column pro-gap-1 pro-mb-5 pro-py-1 ">
                <div className='pro-d-inline-flex'><span className="shimmer pro-px-5 pro-py-1 "></span></div>
                <div className='pro-d-inline-flex'><span className="shimmer pro-px-6  pro-py-2"></span></div>
            </div>
            <div className="col-6 pro-d-flex pro-flex-column pro-gap-1 pro-mb-5 pro-py-1 ">
                <div className='pro-d-inline-flex'><span className="shimmer pro-px-5 pro-py-1 "></span></div>
                <div className='pro-d-inline-flex'><span className="shimmer pro-px-6  pro-py-2"></span></div>
            </div>
            <div className="col-6 pro-d-flex pro-flex-column pro-gap-1 pro-mb-5 pro-py-1 ">
                <div className='pro-d-inline-flex'><span className="shimmer pro-px-5 pro-py-1 "></span></div>
                <div className='pro-d-inline-flex'><span className="shimmer pro-px-6  pro-py-2"></span></div>
            </div>
        </div>
        <div className={`pro-d-flex pro-flex-column pro-pb-3 pro-mt-5 ${Style.black_border_wrap}`}>
            <div className={`pro-d-flex pro-py-4 pro-gap-5 ${Style.gray_border_wrap}`}>
                <span className='pro-px-3 pro-py-2 shimmer'></span>
                <div className="pro-d-flex shimmer"><span className='pro-px-6 pro-py-2'></span><span className='pro-px-5 pro-py-2'></span></div>
                <div className="pro-d-flex shimmer"><span className='pro-px-5 pro-py-2'></span><span className='pro-px-3 pro-py-2'></span></div>
                <div className="pro-d-flex shimmer"><span className='pro-px-5 pro-py-2'></span><span className='pro-px-3 pro-py-2'></span></div>
                <div className="pro-d-flex shimmer pro-ms-auto"><span className='pro-px-5 pro-py-2'></span><span className='pro-px-3 pro-py-2'></span></div>
                
            </div>
            <div className={`pro-d-flex pro-py-5 pro-pb-4 pro-gap-5 ${Style.gray_border_wrap} ${Style.auto_height_wrap}`}>
                <span className='pro-px-3 pro-py-1 shimmer'></span>
                <div className="pro-d-flex shimmer"><span className='pro-px-6 pro-py-1'></span><span className='pro-px-5 pro-py-1'></span></div>
                <div className="pro-d-flex shimmer"><span className='pro-px-5 pro-py-1'></span><span className='pro-px-3 pro-py-1'></span></div>
                <div className='pro-d-flex pro-flex-column'>
                    <div className="pro-d-flex shimmer pro-mb-5"><span className='pro-px-5 pro-py-1'></span><span className='pro-px-3 pro-py-1'></span></div>
                    <div className="pro-d-flex shimmer pro-mt-5"><span className='pro-px-5 pro-py-1'></span><span className='pro-px-3 pro-py-1'></span></div>
                    <div className="pro-d-flex shimmer pro-mt-3"><span className='pro-px-5 pro-py-1'></span><span className='pro-px-3 pro-py-1'></span></div>
                </div>
                <div className='pro-d-flex pro-flex-column pro-ms-auto'>
                    <div className="pro-d-flex shimmer pro-mb-5"><span className='pro-px-5 pro-py-1'></span><span className='pro-px-3 pro-py-1'></span></div>
                    <div className="pro-d-flex shimmer pro-mt-5"><span className='pro-px-5 pro-py-1'></span><span className='pro-px-3 pro-py-1'></span></div>
                    <div className="pro-d-flex shimmer pro-mt-3"><span className='pro-px-5 pro-py-1'></span><span className='pro-px-3 pro-py-1'></span></div>
                </div>
            </div>
            <div className={`pro-pt-3 pro-d-flex pro-justify-between ${Style.auto_height_wrap}`}>
                <span className='shimmer pro-py-1 pro-px-5'></span>
                <div className={`pro-ms-auto pro-d-flex pro-flex-column  ${Style.auto_height_wrap}`}>
                    <span className='pro-py-3 pro-ms-auto pro-px-6 shimmer pro-mb-2'></span>
                    <div className='pro-d-flex'><span className='pro-py-1 pro-px-6 shimmer'></span><span className='pro-py-1 pro-px-6 shimmer'></span></div>
                </div>
            </div>
        </div>
        <div className="pro-d-flex pro-pt-5">
            <div className='shimmer pro-px-6 pro-py-4 pro-me-3'></div>
            <div className='shimmer pro-px-6 pro-py-4'></div>
        </div>
    </div>
  )
}

export default InvoiceShimmer