import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "desc",
  data: [],
  showCreateModal: false,
  selectedStates: "",
  editStates: false,
  clearSelection: false,
  search: "",
  preSelectedCommunity: null,
};

export const saveStatesDetails = createAsyncThunk(
  "States/saveStatesDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/admin/state/create", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveDistrict = createAsyncThunk(
  "States/saveDistrict",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("admin/districts/create", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateStatesDetails = createAsyncThunk(
  "States/updateStatesDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.put("/admin/state/update", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



export const getDioceseDetailsByID = createAsyncThunk(
  "branches/getDioceseDetailsByID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/admin/state/edit?${new URLSearchParams(params).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteDistrictFromStates = createAsyncThunk(
  "district/deleteDistrictFromStates",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.put("admin/district/delete", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const stateSingleSlice = createSlice({
  name: "stateSingle",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    selectStates: (state, action) => {
      state.selectedDiocese = action.payload;
    },
    editStates: (state, action) => {
      state.editStates = action.payload;
    },

    setPreSelectedCommunity: (state, action) => {
      state.preSelectedCommunity = action.payload;
    },
  },
  extraReducers: {
    [getDioceseDetailsByID.pending]: (state, action) => {},
    [getDioceseDetailsByID.fulfilled]: (state, action) => {
      state.selectedDiocese = action.payload.data.data;
    },
    [getDioceseDetailsByID.rejected]: (state, action) => {},
  },
});

export const {
  updateConfig,
  selectStates,
  editStates,
  setPreSelectedCommunity,
} = stateSingleSlice.actions;

export default stateSingleSlice.reducer;
