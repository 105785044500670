import Style from './followUpCommentsShimmer.module.scss';


const FollowUpCommentsShimmer = () => {
  return (
    <div className='pro-mx-5'>
      <div className={`${Style.root} pro-py-5 pro-my-2 pro-d-flex pro-items-center pro-justify-between pro-gap-3`}>
        <div className="flex-fill">
          <div className={` pro-px-2  ${Style.badge}`}>
            <div className={`shimmer pro-ps-6 pro-pe-5 pro-py-1 `}></div>
          </div>
          <div className={` ${Style.img_wrap} pro-d-flex pro-mt-2 pro-gap-2 pro-items-center`}>
            <div className={`${Style.img} shimmer pro-rounded-circle`}></div>
            <div className='pro-d-flex shimmer'>
              <div className='pro-px-6 pro-py-2'></div>
              <div className='pro-px-6 pro-py-2'></div>
            </div>
          </div>
          <div className={`${Style.text_wrap} pro-d-flex pro-flex-column pro-mt-4`}>
            <div className={`${Style.text} pro-py-1 shimmer pro-mb-2`}></div>
            <div className={`${Style.text} pro-py-1 shimmer pro-me-6`}></div>
          </div>
        </div>
        <div className='pro-px-6 pro-py-2 shimmer'></div>
      </div>
      <div className={`${Style.root} pro-py-5 pro-my-2 pro-d-flex pro-items-center pro-justify-between pro-gap-3`}>
        <div className="flex-fill">
          <div className={` pro-px-2  ${Style.badge}`}>
            <div className={`shimmer pro-ps-6 pro-pe-5 pro-py-1 `}></div>
          </div>
          <div className={` ${Style.img_wrap} pro-d-flex pro-mt-2 pro-gap-2 pro-items-center`}>
            <div className={`${Style.img} shimmer pro-rounded-circle`}></div>
            <div className='pro-d-flex shimmer'>
              <div className='pro-px-6 pro-py-2'></div>
              <div className='pro-px-6 pro-py-2'></div>
            </div>
          </div>
          <div className={`${Style.text_wrap} pro-d-flex pro-flex-column pro-mt-4`}>
            <div className={`${Style.text} pro-py-1 shimmer pro-mb-2`}></div>
            <div className={`${Style.text} pro-py-1 shimmer pro-me-6`}></div>
          </div>
        </div>
        <div className='pro-px-6 pro-py-2 shimmer'></div>
      </div>
      <div className={`${Style.root} pro-py-5 pro-my-2 pro-d-flex pro-items-center pro-justify-between pro-gap-3`}>
        <div className="flex-fill">
          <div className={` pro-px-2  ${Style.badge}`}>
            <div className={`shimmer pro-ps-6 pro-pe-5 pro-py-1 `}></div>
          </div>
          <div className={` ${Style.img_wrap} pro-d-flex pro-mt-2 pro-gap-2 pro-items-center`}>
            <div className={`${Style.img} shimmer pro-rounded-circle`}></div>
            <div className='pro-d-flex shimmer'>
              <div className='pro-px-6 pro-py-2'></div>
              <div className='pro-px-6 pro-py-2'></div>
            </div>
          </div>
          <div className={`${Style.text_wrap} pro-d-flex pro-flex-column pro-mt-4`}>
            <div className={`${Style.text} pro-py-1 shimmer pro-mb-2`}></div>
            <div className={`${Style.text} pro-py-1 shimmer pro-me-6`}></div>
          </div>
        </div>
        <div className='pro-px-6 pro-py-2 shimmer'></div>
      </div>
      <div className={`${Style.root} pro-py-5 pro-my-2 pro-d-flex pro-items-center pro-justify-between pro-gap-3`}>
        <div className="flex-fill">
          <div className={` pro-px-2  ${Style.badge}`}>
            <div className={`shimmer pro-ps-6 pro-pe-5 pro-py-1 `}></div>
          </div>
          <div className={` ${Style.img_wrap} pro-d-flex pro-mt-2 pro-gap-2 pro-items-center`}>
            <div className={`${Style.img} shimmer pro-rounded-circle`}></div>
            <div className='pro-d-flex shimmer'>
              <div className='pro-px-6 pro-py-2'></div>
              <div className='pro-px-6 pro-py-2'></div>
            </div>
          </div>
          <div className={`${Style.text_wrap} pro-d-flex pro-flex-column pro-mt-4`}>
            <div className={`${Style.text} pro-py-1 shimmer pro-mb-2`}></div>
            <div className={`${Style.text} pro-py-1 shimmer pro-me-6`}></div>
          </div>
        </div>
        <div className='pro-px-6 pro-py-2 shimmer'></div>
      </div>
    </div>
  )
}

export default FollowUpCommentsShimmer;