import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  interestedStatus: "idle",
  interestedCount: {},
  interestedCountStatus: "idle",
  interestedData: {},
  interestedPermissions: [],
  selectedProfiles: [],
  currentPage: 1,
};

const cache = {};

export const getInterestedDetails = createAsyncThunk(
  "interested/getInterestedDetails",
  async (params, { rejectWithValue }) => {
    const cacheKey = JSON.stringify(params);

    if (cache[cacheKey]) {
      return cache[cacheKey];
    }
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        params.activeFilter === 1
          ? `/admin/members/profile/interests/received?user_id=${params.user_id}&page_size=21&page=${params?.page}&type=${params.type}`:
          params.activeFilter === 2? `/admin/members/profile/interests/accepted?user_id=${params.user_id}&page_size=21&page=${params?.page}&type=${params.type}`
          : `/admin/members/profile/interests/sent?user_id=${params.user_id}&page_size=21&page=${params?.page}&type=${params.type}`
      );
      cache[cacheKey] = response;

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getInterestedCount = createAsyncThunk(
  "interested/getInterestedCount",
  async (params, { rejectWithValue }) => {
    const cacheKey = JSON.stringify(params);

    if (cache[cacheKey]) {
      return cache[cacheKey];
    }
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        params.activeFilter === 1
          ? `/admin/members/profile/interests/received?user_id=${params.user_id}&page_size=21&page=${params?.page}&type=${params.type}`:
          params.activeFilter === 2? `/admin/members/profile/interests/accepted?user_id=${params.user_id}&page_size=21&page=${params?.page}&type=${params.type}`
          : `/admin/members/profile/interests/sent?user_id=${params.user_id}&page_size=21&page=${params?.page}&type=${params.type}`
      );
      cache[cacheKey] = response;

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const interestedSlice = createSlice({
  name: "interested",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    [getInterestedDetails.pending]: (state, action) => {
      state.interestedStatus = "pending";
    },
    [getInterestedDetails.fulfilled]: (state, action) => {
      state.interestedPermissions = action?.payload?.data?.data?.permission;
      let newArry = state.interestedData[action.meta?.arg?.user_id]
        ? current(state.interestedData[action.meta?.arg?.user_id]?.data ?? [])
        : [];

      let tempArry = [
        ...newArry,
        ...(action?.payload?.data?.data?.data
          ? action?.payload?.data?.data?.data
          : []),
      ];

      state.selectedProfiles = tempArry
        ?.filter((item) => Boolean(item.short_listed))
        ?.map((item) => item?._id);

      if (state.interestedData?.[action.meta?.arg?.user_id]) {
        state.interestedData[action.meta?.arg?.user_id] = {
          ...action.payload?.data?.data,
          interestedStatus: "fulfilled",
          data: [
            ...state.interestedData[action.meta?.arg?.user_id].data,
            ...action.payload?.data?.data?.data,
          ],
        };
      } else {
        state.interestedData[action.meta?.arg?.user_id] = {
          ...action.payload?.data?.data,
          interestedStatus: "fulfilled",
          page: 1,
        };
      }
      state.interestedStatus = "fulfilled";
    },
    [getInterestedDetails.rejected]: (state) => {
      state.interestedStatus = "failed";
    },
    [getInterestedCount.pending]: (state, action) => {
      state.interestedCountStatus = "pending";
    },
    [getInterestedCount.fulfilled]: (state, action) => {
      state.interestedCount[action.meta?.arg?.user_id] =
        action.payload?.data?.data;
      // state.interestedCount[action.meta?.arg?.user_id].interestedCountStatus =
      //   "fulfilled";

      state.interestedCountStatus = "fulfilled";
    },
    [getInterestedCount.rejected]: (state) => {
      state.interestedCountStatus = "failed";
    },
  },
});

export const { updateConfig } = interestedSlice.actions;

export default interestedSlice.reducer;
