import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useTranfserForm from "./useTranfserForm";

const TransferForm = ({ refetch }) => {
  const { handleClose, formik } = useTranfserForm({
    refetch,
  });
  
  return (
    <div>
      <div className="row">
        <div className="col-12 pro-mb-4">
          <div className="input-wrap">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Note
            </label>
            <textarea
              id="transferNote"
              rows={6}
              name="transferNote"
              className={`pro-input lg ${formik.touched.transferNote && formik.errors.transferNote && "error"}`}
              onChange={(e) =>
                formik.setFieldValue("transferNote", e.target.value)
              }
            ></textarea>
            {formik.touched.transferNote && formik.errors.transferNote && (
              <span className="error-text">{formik.errors.transferNote}</span>
            )}
          </div>
        </div>
      </div>

      <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
        <Button className={"pro-btn-link lg pro-px-5"} onClick={handleClose}>
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-mx-3"}
          onClick={formik?.handleSubmit}
        >
          {true ? (
            <i
              className="spinner-border spinner-border-sm me-3"
              role="status"
              aria-hidden="true"
            ></i>
          ) : (
            ""
          )}
          Transfer
        </Button>
      </div>
    </div>
  );
};

export default TransferForm;
