import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const usePayments = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Transactions",
      label: "Transactions",
      link: "/payment/transactions",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4">payments</span>,
      active: checkIfActiveRoute("/payment/transactions", true),
    },
   
  ];

  const drawerMenuPermission = useMemo(() => {
    let menus = globalState.dashboard_permissions?.flatMap?.((menu) => [
      menu.name,
      ...menu.permissions.map((sub_menu) => sub_menu.menu_name),
    ]);

    return drawerMenu
      .filter((menu) => menus?.includes?.(menu.label))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  return {
    drawerMenu: drawerMenuPermission,
  };
};

export default usePayments;
