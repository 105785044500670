import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";
import { subDays } from "date-fns";


const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: null,
  startDate: subDays(new Date(), 30),
  endDate: new Date(),
  sortBy: "",
  sortOrder: "desc",
  data: [],
  showCreateModal: false,
  showEditModal: false,
  selectedItemsDetails: "",
  search: "",
};

export const addItemToTable = createAsyncThunk(
  "messages/addItemToTable",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/admin/transfer/create", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteItemFromTable = createAsyncThunk(
  "messages/deleteItemFromTable",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.delete(
        `/admin/transfer/delete?${new URLSearchParams(params).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editItemFromTableByID = createAsyncThunk(
  "messages/editItemFromTableByID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/admin/transfer/edit?${new URLSearchParams(params).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateItemFromTableByID = createAsyncThunk(
  "messages/updateItemFromTableByID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/admin/transfer/update`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setEditTestimonail: (state, action) => {
      state.editTestimonial = action.payload;
    },
  },
  extraReducers: {
    [editItemFromTableByID.pending]: (state, action) => {},
    [editItemFromTableByID.fulfilled]: (state, action) => {
      state.selectedItemsDetails = action.payload.data.data;
    },
    [editItemFromTableByID.rejected]: (state, action) => {},
  },
});

export const { updateConfig, setEditTestimonail } = messagesSlice.actions;

export default messagesSlice.reducer;
