import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useRouteUtils from "../../../utils/hooks/useRouteUtils";
import { useEffect, useMemo } from "react";
import useRoutes from "../../../routes/useRoutes";
import { useDispatch } from "react-redux";
import {
  getMenuPermissions,
  getProfilePermissions,
  updateConfig,
} from "../../../store/slices/Global";
const useCommonLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { menuDrawer } = useSelector((state) => state.global);

  const globalState = useSelector((state) => state.global);

  const {
    allowedNotificationComponent,
    allowedUserComponent,
    allowedSettingsComponent,
    allowedPaymentsComponent,
    allowedCmsComponent,
    allowedDashboardComponent,
  } = useRoutes();

  useEffect(() => {
    if (
      !globalState.isLogged &&
      location.pathname === "/configure/appearance"
    ) {
      dispatch(
        updateConfig((state) => {
          state.isLogged = true;
        })
      );
    }

    //eslint-disable-next-line
  }, [dispatch, globalState.isLogged]);

  useEffect(() => {
    let sessionLogged = sessionStorage.getItem("USER_ACCESS_TOKEN");

    if (!sessionLogged) {
      sessionStorage.setItem(
        "USER_ACCESS_TOKEN",
        localStorage.getItem("USER_ACCESS_TOKEN")
      );

      dispatch(
        getMenuPermissions({ token: localStorage.getItem("USER_ACCESS_TOKEN") })
      );
      dispatch(getProfilePermissions());
    }

    //eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    if (
      globalState.isLogged === true &&
      (allowedCmsComponent?.length > 0 ||
        allowedUserComponent?.length > 0 ||
        allowedPaymentsComponent?.length > 0 ||
        allowedNotificationComponent?.length > 0 ||allowedDashboardComponent?.length > 0)
    ) {
      dispatch(
        updateConfig((state) => {
          state.isLogged = "idle";
        })
      );

      if (allowedDashboardComponent?.length > 0) {
        navigate(
          `/${allowedDashboardComponent?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedUserComponent?.length > 0) {
        navigate(
          `/users/${allowedUserComponent?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedPaymentsComponent?.length > 0) {
        navigate(
          `/payment/${allowedPaymentsComponent?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedNotificationComponent?.length > 0) {
        navigate(
          `/support/${
            allowedNotificationComponent?.map((item) => item?.path)?.[0]
          }`
        );
      } else if (allowedCmsComponent?.length > 0) {
        navigate(`/cms/${allowedCmsComponent?.map((item) => item?.path)?.[0]}`);
      } else {
        navigate(
          `/configure/${
            allowedSettingsComponent?.map((item) => item?.path)?.[0]
          }`
        );
      }
    }

    //eslint-disable-next-line
  }, [
    allowedCmsComponent,
    allowedNotificationComponent,
    allowedPaymentsComponent,
    allowedUserComponent,
    allowedDashboardComponent,
    allowedSettingsComponent,
  ]);



  const { checkIfActiveRoute } = useRouteUtils();
  const navigations = [
    {
      label: "Dashboard",
      link: `/${allowedDashboardComponent?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/dashboard"),
    },
    {
      label: "User",
      link: `/users/${allowedUserComponent?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/users`),
    },
    {
      label: "Payments",
      link: `/payment/${
        allowedPaymentsComponent?.map((item) => item?.path)?.[0]
      }`,
      isButton: false,
      active: checkIfActiveRoute("/payment"),
    },

    {
      label: "Support",
      link: `/support/${
        allowedNotificationComponent?.map((item) => item?.path)?.[0]
      }`,
      isButton: false,
      active: checkIfActiveRoute("/support"),
    },
    {
      label: "CMS",
      link: `/cms/${allowedCmsComponent?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/cms"),
    },
    {
      label: "Configure",
      link: `/configure/${
        allowedSettingsComponent?.map((item) => item?.path)?.[0]
      }`,
      isButton: false,
      active: checkIfActiveRoute("/configure"),
    },
  ];

  const handleGoBack = () => {
    navigate(menuDrawer?.backTo);
  };

  const topMenuPermission = useMemo(() => {
    let menus = globalState.dashboard_permissions?.flatMap?.((menu) => [
      menu.name,
      "Configure",
    ]);

    return navigations
      .filter((menu) => menus?.includes?.(menu.label))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  return {
    navigations: topMenuPermission,
    menuDrawer,
    globalState,
    handleGoBack,
  };
};

export default useCommonLayout;
