import { useDispatch, useSelector } from "react-redux";
import {
  useGetBlockedListDataQuery,
  useUpdateBlockedListDataMutation,
} from "../../../store/queries/members";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Users/blockedSlice";
import { getFormatedDate } from "../../../utils/functions/table";
import { ProfileNavigation } from "../../Global/ProfileNavigation";
import { useSearchParams } from "react-router-dom";
import { subDays } from "date-fns";
import { useMemo, useState } from "react";
import { SelectStatusButton } from "../../Global/SelectStatusButton";
import { getExportedData } from "./api";
import moment from "moment";

const useBlocked = () => {
  const dispatch = useDispatch();

  const blockedState = useSelector((state) => state.blocked);
  const [selectedUser, setSelectedUser] = useState("");
  const [showBlockedModal, setShowBlockedModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [params] = useSearchParams();
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");
  const { showEditModal } = useSelector((state) => state.global);

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [date, setDate] = useState({
    startDate: blockedState.startDate,
    endDate: blockedState.endDate,
    key: "selection",
  });

  const [updateBlockedFields] = useUpdateBlockedListDataMutation();

  const {
    data: blockedList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetBlockedListDataQuery({
    page_size: blockedState.currentPageSize,
    page: blockedState.currentPage,
    sort_by: blockedState.sortBy,
    sort_order: blockedState.sortOrder,
    start: getFormatedDate(blockedState.startDate),
    end: getFormatedDate(blockedState.endDate),
    search: blockedState.search,
  });

  const hasExportPermission = useMemo(() => {
    let permission = blockedList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_export")
    );

    return permission?.[0]?.can_export ?? 0;
  }, [blockedList]);

  const hasUnblockPermission = useMemo(() => {
    let permission = blockedList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_unblock")
    );

    return permission?.[0]?.can_unblock;
  }, [blockedList]);

  const hasDeletePermission = useMemo(() => {
    let permission = blockedList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_delete")
    );

    return permission?.[0]?.can_delete ?? 0;
  }, [blockedList]);

  const hasViewPermission = useMemo(() => {
    let permission = blockedList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view")
    );

    return permission?.[0]?.can_view ?? 0;
  }, [blockedList]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = range.startDate;
        state.endDate = range.endDate;
      })
    );
  };

  const handleSort = (label) => {
    if (blockedState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = blockedState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const options = [
    { value: 1, label: "Unblock" },
    { value: 0, label: "Delete" },
  ];

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      staff_id: (feild, data) => (
        <p className="pro-mb-0">{data[feild]?.name ?? ""}</p>
      ),
      name: (_, data) => (
        <ProfileNavigation
          userId={data?.member_id}
          fieldValue={data?.name}
          activeProfile={activeProfile}
          params={params}
          hasViewPermission={hasViewPermission === 1 ? true : false}
        />
      ),
      action: (feild, data) => (
        <SelectStatusButton
          options={options?.filter((item) =>
            hasUnblockPermission === 1
              ? item
              : item.value === 1 && hasDeletePermission === 1
              ? item
              : item.value === 0
          )}
          data={data}
          // label1={"Unblock"}
          // label2={"Delete"}
          type={"blocked"}
          setShowDeleteModal={setShowDeleteModal}
          setShowBlockedModal={setShowBlockedModal}
          setSelectedUser={setSelectedUser}
        />
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleClearClick = () => {
    setDate({
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.startDate = subDays(new Date(), 30);
        state.endDate = new Date()
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
      })
    );
  };
  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    } else {
      getExportedData({
        fields: Object.keys(blockedList?.data?.fields),
        filter: blockedState.currentFilter,
        sort_by: blockedState.sortBy,
        sort_order: blockedState.sortOrder,
        start: getFormatedDate(blockedState.startDate),
        end: getFormatedDate(blockedState.endDate),
        search: blockedState.search,
      }).then((response) => {
        let dataType = response?.data?.type;
        let binaryData = [];
        binaryData.push(response?.data);
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute(
          "download",
          `${moment(Date.now()).format("MM_DD_YYYY_HH_mm_ss")}.xlsx`
        );
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
    }
  };

  const closeActionModal = () => {
    setShowBlockedModal(() => false);
  };
  const closeDeleteActionModal = () => {
    setShowDeleteModal(() => false);
  };

  const handleCreateClick = () => {};

  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
      })
    );
  };

  return {
    blockedList,
    blockedState,
    showBlockedModal,
    currentPage: blockedState.currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    selectedUser,
    actionOptions,
    hasExportPermission,
    closeDeleteActionModal,
    handleDateRangeChange,
    showDeleteModal,
    handleActionChange,
    handleCreateClick,
    setShowBlockedModal,
    closeActionModal,
    handlePagination,
    handleDateChange,
    handlePageSize,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    refetch,
    updateBlockedFields,
    handleClearClick,
    date,
  };
};

export default useBlocked;
