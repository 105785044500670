import React from "react";
import Style from "./payment.module.scss";

const FAQsFormFields = ({
  formik
}) => {
  return (
    <div className={Style.root}>
      <div className="col-12 pro-mb-4">
        <div className="row">
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Question
            </label>
            <input
              type="text"
              className={`pro-input lg ${
                formik.errors.question && formik.touched.question && " error"
              }`}
              id="question"
              name="question"
              {...formik.getFieldProps("question")}
            />
            {formik.touched.question && formik.errors.question && (
              <span className="error-text">{formik.errors.question}</span>
            )}
          </div>

         
         
          <div className="col-12 pro-mb-4">
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Answer
              </label>
              <textarea
                rows={4}
                className={`pro-input lg ${
                  formik.errors.answer && formik.touched.answer && " error"
                }`}
                id="answer"
                name="answer"
                {...formik.getFieldProps("answer")}
              >
                </textarea>
              {formik.touched.answer && formik.errors.answer && (
                <span className="error-text">{formik.errors.answer}</span>
              )}
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default FAQsFormFields;
