import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useObserver from "../../../utils/hooks/useObserver";
import { useDispatch, useSelector } from "react-redux";
import {
  getFavouritesCount,
  getFavouritesDetails,
  updateConfig,
} from "../../../store/slices/Profile/Favourites";
import { toast } from "react-toastify";

const useFavorites = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [showScroll, setShowScroll] = useState(false);
  const [activeFilter, setActiveFilter] = useState();
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");
  const state = useSelector((state) => state.favourites);
  const favouritesState = state?.favouritesData?.[activeProfile];
  const globalState = useSelector((state) => state.global);
  const filters = [
    {
      type: "buttonGroup",
      buttons: [
        { name: "Favorited by User ", value: 0 },
        { name: "Favorited by Others", value: 1 },
      ],
    },
  ];
  const hasFavoritedbyMePermission = useMemo(() => {
    let permission = state?.favouritesPermissions?.filter((p) =>
      Object.keys(p).includes("favourite_by_me")
    );

    return permission?.[0]?.favourite_by_me ?? 0;
  }, [state]);
  const hasFavoritedbyOthersPermission = useMemo(() => {
    let permission = state?.favouritesPermissions?.filter((p) =>
      Object.keys(p).includes("favourite_by_other")
    );

    return permission?.[0]?.favourite_by_other ?? 0;
  }, [state]);

  if (hasFavoritedbyMePermission !== 1) {
    filters[0].buttons = filters[0].buttons.filter(
      (button) => button.value !== 0
    );
  }
  if (hasFavoritedbyOthersPermission !== 1) {
    filters[0].buttons = filters[0].buttons.filter(
      (button) => button.value !== 1
    );
    // setActiveFilter(filters?.buttons[0].value)
  }
  const reportData = [
    {
      label: "Today",
      value: `${state.favouritesCount[activeProfile]?.daily_count ?? 0}`,
    },
    {
      label: "This week",
      value: `${state.favouritesCount[activeProfile]?.weekly_count ?? 0}`,
    },
    {
      label: "This Month",
      value: `${state.favouritesCount[activeProfile]?.monthly_count ?? 0}`,
    },
    {
      label: "All time",
      value: `${state.favouritesCount[activeProfile]?.total_count ?? 0}`,
    },
  ];
  const handleActiveFilter = () => {
    const newvalue=globalState?.profile_permissions?.find(
      (item) => item?.label === "Favourites")
    const initialValue = activeFilter ?? (
      newvalue?.front_end_url.includes("others") ? 1 :0
    );
    return initialValue;
  };
  useEffect(() => {
    if (Object.keys(state.favouritesData[activeProfile] ?? {}).length === 0) {
      dispatch(
        getFavouritesDetails({
          user_id: activeProfile,
          // page: page,
          activeFilter: handleActiveFilter(),
          page: state?.currentPage,
          type: 1,
        })
      );
      dispatch(
        getFavouritesCount({
          user_id:activeProfile,
          activeFilter: handleActiveFilter(),
          // page: page,
          page: state?.currentPage,
          type: 2,
        })
      );
    } else {
      window.scrollTo(0, document.body.scrollHeight);
    }

    if (
      state?.selectedProfiles?.length ===
      favouritesState?.data
        ?.filter((item) => Boolean(item.short_listed))
        ?.map((item) => item?.member_id)?.length
    ) {
      dispatch(
        updateConfig((state) => {
          state.selectedProfiles = favouritesState?.data
            ?.filter((item) => Boolean(item.short_listed))
            ?.map((item) => item?.member_id);
        })
      );
    }

    // return () => {
    //   dispatch(
    //     updateConfig((state) => {
    //       state.favouritesStatus = "idle";
    //       state.favouritesData = {};
    //       state.selectedProfiles = [];
    //     })
    //   );
    // };

    //eslint-disable-next-line
  }, [activeProfile,globalState?.profile_permissions]);

  useEffect(() => {
    function handleWindowResize() {
      if (window.scrollY > 150) {
        setShowScroll(true);
      } else if (window.scrollY === 0) {
        setShowScroll(false);
      }
    }
    window.addEventListener("scroll", handleWindowResize);

    return () => {
      window.removeEventListener("scroll", handleWindowResize);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const handleTabChange = (item) => {
    if (item.value !== activeFilter) {
      dispatch(
        updateConfig((state) => {
          state.favouritesData[activeProfile].data = [];
          state.favouritesData[activeProfile].page = 1;
        })
      );
        dispatch(
          getFavouritesDetails({
            user_id: activeProfile,
            activeFilter: item.value,
            // page: pageReceived,
            // page: interestedState?.current_page,
            page: state?.currentPage,
            hasFavoritedbyMePermission: hasFavoritedbyMePermission,
            type: 1,
          })
        );
        dispatch(
          getFavouritesCount({
            user_id: activeProfile,
            activeFilter: item.value,
            // page: pageReceived,
            // page: interestedState?.current_page,
            page: state?.currentPage,
            hasFavoritedbyMePermission: hasFavoritedbyMePermission,
            type: 2,
          })
        );

      setActiveFilter(item.value);
    }
  };
  // const handleLoadMore = () => {
  //   // dispatch(
  //   //   updateConfig((state) => {
  //   //     //state.currentPage = state.currentPage + 1;
  //   //     state.favouritesData[activeProfile].page += 1;
  //   //   })
  //   // );
  //   setPage((prev) => prev + 1);
  //   dispatch(
  //     getFavouritesDetails({
  //       user_id: activeProfile,
  //       // page: page + 1,
  //       page: favouritesState?.current_page + 1,
  //       type: 1,
  //     })
  //   );
  // };
  const handleLoadMore = useCallback(() => {
    if (favouritesState?.favouritesStatus === "fulfilled") {
      dispatch(
        getFavouritesDetails({
          user_id: activeProfile,
          activeFilter: activeFilter,
          // page: state?.currentPage + 1,
          page: favouritesState?.current_page + 1,
          type: 1,
        })
      );
    }
    //eslint-disable-next-line
  }, [dispatch, activeProfile, favouritesState]);

  const { lastElement } = useObserver({
    loading: favouritesState?.favouritesStatus !== "fulfilled",
    hasMore:
      favouritesState?.per_page * favouritesState?.current_page <
      favouritesState?.total,
    callback: handleLoadMore,
  });

  const handleProfileRedirect = (id, viewStatus) => {
    const ids = params.get("id")
      ? [...params.get("id").split(","), activeProfile]
      : [activeProfile];

    const newIds = ids.filter((item) => item !== id).join(",");
    if (viewStatus === 0) {
      toast.error(`User does not have permission to view this profile`);
    } else {
      navigate({
        pathname: "/profile",
        search: `active=${id}&id=${newIds}`,
      });
    }
  };

  return {
    filters,
    reportData,
    state,
    activeProfile,
    favouritesState,
    showScroll,
    handleScrollToTop,
    lastElement,
    handleProfileRedirect,
    handleTabChange,
    activeFilter
  };
};

export default useFavorites;
