import DashboardHero from "../DashboardHero";
import usePartnerDashboardListing from "./usePartnerDashboardListing";
import Style from "./dashboardListing.module.scss";
import EmptyData from "../../Global/EmptyData";

const PartnerDashboardListing = () => {
  const { dashboardItems, dashboardData, isFetching, children, listingType } =
    usePartnerDashboardListing();

  return (
    <div className={Style.dashboard_wrap}>
      <div className={`pro-w-100 pro-my-5`}>
        <DashboardHero
          data={dashboardData?.data}
          obj={dashboardItems}
          loading={isFetching}
          listingType={listingType}
          shimmerCount={8}
        />
      </div>
      {dashboardData?.data && Object.keys(dashboardData?.data)?.length !== 0 ? (
        children
      ) : (
        <>
          <EmptyData />
        </>
      )}
    </div>
  );
};

export default PartnerDashboardListing;
