import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { forgotPassword, login } from "../api";
import useValidations from "../../../utils/hooks/useValidations";
import useRoutes from "../../../routes/useRoutes";
import { updateConfig } from "../../../store/slices/Global";
import { useDispatch } from "react-redux";

const useAuth = (emailRef) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { validateEmail } = useValidations();

  const [loginStatus, setLoginStatus] = useState("idle");
  const [loginInfo, setLoginInfo] = useState({});
  const [showPassword, setShowPassword] = useState(true);
  const [forgotPasswordStatus, setForgotPasswordStatus] = useState("idle");
  const [forgotPasswordInfo, setForgotPasswordInfo] = useState({});

  const { allowedSettingsComponent } = useRoutes();

  useEffect(() => {
    if (loginStatus === "success") {
      dispatch(
        updateConfig((state) => {
          state.isLogged = true;
        })
      );

      navigate(
        `/configure/${allowedSettingsComponent?.map((item) => item?.path)?.[0]}`
      );

       window.location.reload()
    } else if (loginStatus === "failed") {
      let errorFields = Object.keys(loginInfo);
      errorFields.forEach((field) => {
        formik.setFieldError(field, loginInfo[field]);
      });
    }
    // eslint-disable-next-line
  }, [loginStatus]);

  useEffect(() => {
    if (forgotPasswordStatus === "success") {
      navigate("/login/forgot-password");
    } else if (forgotPasswordStatus === "failed") {
      let errorFields = Object.keys(forgotPasswordInfo);
      errorFields.forEach((field) => {
        formik.setFieldError(field, forgotPasswordInfo[field]);
      });
    }
    // eslint-disable-next-line
  }, [forgotPasswordStatus]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate: (values) => {
      let errors = {};
      if (!values.email) {
        errors.email = "*Email Required";
      }
      if (!values.password) {
        errors.password = "*Password Required";
      }
      if (!validateEmail(values.email)) {
        errors.email = "*Enter a valid email";
      }
      return errors;
    },
    onSubmit: (values) => {
      emailRef.current = values.email;

      setLoginStatus("pending");

      login(values).then((response) => {
        if (response?.data?.status_code === 200) {
          setLoginInfo(response?.data?.data);

          localStorage.setItem(
            "USER_ACCESS_TOKEN",
            response?.data?.data?.token
          );

          localStorage.setItem("USER_DETAILS", response?.data?.data?.user?._id);
          setLoginStatus("success");
        } else if (response?.data?.status_code === 422) {
          setLoginStatus("failed");

          setLoginInfo({ password: response?.data?.message });
        } else if (response?.status_code === 400) {
          setLoginInfo({ email: response?.message?.email?.[0] });
          setLoginStatus("failed");
        } else if (response?.status_code === 422) {
          setLoginInfo({ password: response?.message });
          setLoginStatus("failed");
        } else setLoginStatus("An error occured");
      });
    },
  });

  const handleForgotPassword = () => {
    emailRef.current = formik.values.email;
    if (!formik.values.email) {
      formik.setFieldError("email", "*Provide an email to reset password");
    } else if (!formik.errors.email && formik.values.email) {
      setForgotPasswordStatus("pending");
      forgotPassword({ email: formik.values.email }).then((response) => {
        setForgotPasswordStatus("success");

        dispatch(
          updateConfig((state) => {
            state.otpGenerated = response?.data?.message;
          })
        );

        if (response?.status_code === 400) {
          setForgotPasswordInfo({
            email: response?.message?.email?.[0],
          });
          setForgotPasswordStatus("failed");
        }
      });
    }
  };

  const handleShowPassword = (e) => {
    e.preventDefault();

    setShowPassword(!showPassword);
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  return {
    formik,
    loginStatus,
    forgotPasswordStatus,
    showPassword,
    handleShowPassword,
    setForgotPasswordStatus,
    handleForgotPassword,
  };
};

export default useAuth;
