import React from "react";
import Preference from "../../Global/Preference";
import PreferenceForm from "../PreferenceForm";
import useMatches from "./useMatches";
import { UserCard } from "../../Global/UserCard";
import ErrorMessage from "../../Global/ErrorMessage";
import { DataContainer } from "@wac-ui-dashboard/wac_component_library";
import ProfileCardShimmer from "../../Global/Shimmers/ProfileCardShimmer";

const Matches = () => {
  const {
    showFilter,
    matches,
    activeProfile,
    matchesState,
    matchesPreference,
    matchesPreferenceState,
    formik,
    optionsData,
    isOptionDataSuccess,
    selectedPreferenceFilter,
    showMore,
    showScroll,
    overViewData,
    hasSearchPermission,
    hasShortlistPermission,
    handleScrollToTop,
    handleHasmore,
    handleAgeRangeChange,
    handleHeightRangeChange,
    handleWeightRangeChange,
    handleSubmit,
    handleReset,
    handleCloseClick,
    lastElement1,
    lastElement2,
    handleSearch,
    handleSelect,
    handleEditClick,
    handleProfileRedirect,
  } = useMatches();

  return (
    <>
      <div className="pro-m-4 pro-mx-5">
        <DataContainer>
          {showFilter &&
          hasSearchPermission === 1 &&
          overViewData?.data?.data?.assigned_profile === 1 ? (
            <PreferenceForm
              formik={formik}
              gender={overViewData?.data?.data?.gender?.id ?? 1}
              optionsData={optionsData}
              isOptionDataSuccess={isOptionDataSuccess}
              handleAgeRangeChange={handleAgeRangeChange}
              handleHeightRangeChange={handleHeightRangeChange}
              handleWeightRangeChange={handleWeightRangeChange}
              handleSearch={handleSearch}
              onCloseIconClick={handleCloseClick}
              handleSubmit={handleSubmit}
              handleReset={handleReset}
              selectedPreferenceFilter={selectedPreferenceFilter}
            />
          ) : (
            <>
              <Preference
                data={selectedPreferenceFilter}
                handleHasmore={handleHasmore}
                showMore={showMore}
                sliceLength={showMore ? selectedPreferenceFilter?.length : 5}
                title={"Partner Preferences"}
                EditBtnIcon={
                  hasSearchPermission === 1 &&
                  overViewData?.data?.data?.assigned_profile === 1 ? (
                    <span className="material-symbols-outlined">
                      edit_square
                    </span>
                  ) : (
                    <></>
                  )
                }
                onEditIconClick={handleEditClick}
              />
            </>
          )}
        </DataContainer>
      </div>
      {matchesState?.matchesStatus !== "fulfilled" &&
      matchesPreferenceState?.matchesPreferenceStatus === undefined &&
     
      !matchesState?.data ? (
        <>
          <ProfileCardShimmer />
          {/* <MatchPrefecenceShimmer /> */}
        </>
      ) : matchesPreferenceState?.matchesPreferenceStatus !== "fulfilled" &&
        matchesState?.matchesStatus === undefined &&
       
        !matchesPreferenceState?.data ? (
        <>
          <ProfileCardShimmer />
          {/* <MatchPrefecenceShimmer /> */}
        </>
      ) : (
        <>
          <div className="pro-m-5 pro-mb-2 pro-d-flex pro-flex-wrap">
            {showScroll && (
              <button
                className={`scoll-top`}
                onClick={() => handleScrollToTop()}
              >
                <span className="material-symbols-outlined">expand_less</span>
              </button>
            )}

            {matchesPreferenceState?.data?.map((value, index) => {
              return (
                <UserCard
                  key={index}
                  isCheckBox={
                    hasShortlistPermission === 1 &&
                    overViewData?.data?.data?.assigned_profile === 1
                      ? true
                      : false
                  }
                  isPremium={Boolean(Number(value?.profile_verified_status))}
                  age={value?.age}
                  checked={matches?.selectedProfiles?.filter?.(
                    (a) => a !== activeProfile
                  )}
                  data={value || {}}
                  handleProfileRedirect={handleProfileRedirect}
                  handleSelect={handleSelect}
                  src={value?.profile_image}
                  title={value?.first_name}
                  subtitle={value?.last_name}
                  isShortlisted={Boolean(Number(value?.short_listed))}
                  lastElement={
                    index === matchesPreferenceState?.data?.length - 10
                      ? lastElement2.lastElement
                      : false
                  }
                  ViewCount={value?.view_count}
                  graphIcon={
                    <span className="material-symbols-outlined">
                      auto_graph
                    </span>
                  }
                  GraphPercentage={value?.profile_percentage}
                  ViewIcon={
                    <span className="material-symbols-outlined">
                      visibility
                    </span>
                  }
                />
              );
            })}
            {matchesState?.data?.map((value, index) => {
              return (
                <UserCard
                  key={index}
                  isCheckBox={
                    hasShortlistPermission === 1 &&
                    overViewData?.data?.data?.assigned_profile === 1
                      ? true
                      : false
                  }
                  isPremium={Boolean(Number(value?.profile_verified_status))}
                  age={value?.age}
                  checked={matches?.selectedProfiles?.filter?.(
                    (a) => a !== activeProfile
                  )}
                  data={value || {}}
                  handleProfileRedirect={handleProfileRedirect}
                  handleSelect={handleSelect}
                  src={value?.profile_image}
                  title={value?.first_name}
                  subtitle={value?.last_name}
                  isShortlisted={Boolean(Number(value?.short_listed))}
                  lastElement={
                    index === matchesState?.data?.length - 10
                      ? lastElement1.lastElement
                      : false
                  }
                  ViewCount={value?.view_count}
                  graphIcon={
                    <span className="material-symbols-outlined">
                      auto_graph
                    </span>
                  }
                  GraphPercentage={value?.profile_percentage}
                  ViewIcon={
                    <span className="material-symbols-outlined">
                      visibility
                    </span>
                  }
                />
              );
            })}

            {((matchesState?.data?.length === undefined &&
              matchesPreferenceState?.data?.length === undefined) ||
              (matchesState?.data?.length === undefined &&
                matchesPreferenceState?.data?.length === 0) ||
              (matchesState?.data?.length === 0 &&
                matchesPreferenceState?.data?.length === undefined) ||
              (matchesState?.data?.length === 0 &&
                matchesPreferenceState?.data?.length === 0)) &&
            ((matchesPreferenceState?.matchesPreferenceStatus === "fulfilled" &&
              matchesPreference?.matchesPreferenceStatus !== "pending") ||
              (matchesState?.matchesStatus === "fulfilled" &&
                matches?.matchesStatus !== "pending")) ? (
              <ErrorMessage />
            ) : (
              <></>
            )}
          </div>
        </>
      )}

      {matches?.matchesStatus === "pending" && matchesState?.data && (
        <div className="pro-w-100 pro-mb-4">
          <ProfileCardShimmer loading />
        </div>
      )}

      {matchesPreference?.matchesPreferenceStatus === "pending" &&
        matchesPreferenceState?.data && <ProfileCardShimmer loading />}
    </>
  );
};

export default Matches;
