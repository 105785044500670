import { useCallback, useRef, useState } from "react";
import { toast } from "react-toastify";

import { getImage } from "../../../utils/functions/getCroppedImage";
import { useUpdateProfileDetailsMutation } from "../../../store/queries/Profile";

const useProfileCard = ({
  data,
  userId,
  profileData,
  setShowDeleteImageModal,
  activeImageIndex,
  setActiveImageIndex,
  setShow,
  refetch,
  handleClose,
}) => {
  const [showCrop, setShowCrop] = useState(false);
  const [fileName, setfileName] = useState({});
  const [aspectRatio, setAspectRatio] = useState(true);

  //  const [crop, setCrop] = useState({ aspect: 1 });

  const [crop, setCrop] = useState({
    unit: "px",
    width: 300,
    height: 300,
    x: 100,
    y: 90,
    aspect: 1,
  });

  const [image, setImage] = useState(null);

  const [updateProfile, { isLoading }] = useUpdateProfileDetailsMutation();

  const imgRef = useRef();
  const activeIndex = useRef(0);

  const handleThumbClick = (index) => {
    setActiveImageIndex(index);
    activeIndex.current = index;
  };

  const handleCropClick = () => {
    setShowCrop(true);
    setAspectRatio(true);
    setfileName({
      name: data[activeImageIndex].image_name,
      type: data[activeImageIndex].type,
    });
  };

  const handleCropCancel = () => {
    setShowCrop(false);
    if (image) {
      setImage(null);
      setActiveImageIndex((prev) => prev - 1);

      activeIndex.current = activeIndex.current - 1;
    }
  };

  let obj = {
    user_id: userId,
    first_name: profileData?.data?.first_name,
    last_name: profileData?.data?.last_name,
    country_code: profileData?.data?.country_code,
    phone_number: profileData?.data?.phone_number,
    gender: profileData?.data?.gender?.id,
    dob: profileData?.data?.dob,
    community_id: profileData?.data?.community_id?._id,
    diocese_id: profileData?.data?.diocese_id?._id,
  };

  const handlePrimary = async () => {
    let formData = new FormData();

    Object.keys(obj).forEach((key) => {
      return formData.append(key, obj[key]);
    });
    formData.append("_method", "PUT");

    data?.forEach((img, i) => {
      if (img?.isPrimary) {
        formData.append(
          `profile_images[${i}][is_primary]`,
          Number(Boolean(!data[i]?.isPrimary)) ?? ""
        );
        formData.append(`profile_images[${i}][_id]`, data[i]?.id ?? "");
      }
    });

    formData.append(
      `profile_images[${activeImageIndex}][is_primary]`,
      Number(Boolean(!data[activeImageIndex]?.isPrimary)) ?? ""
    );
    formData.append(
      `profile_images[${activeImageIndex}][_id]`,
      data[activeImageIndex]?.id ?? ""
    );

    updateProfile(formData)
      .then((response) => {
        if (response?.data?.status_code === 200) {
          refetch();
          handleClose?.();
          toast.success("Image set to primary!");
        } else {
          handleClose?.();
          toast.error("Couldn't set image to primary!");
        }
      })
      .catch(() => {
        toast.error("Couldn't set image to primary!");
      });
  };

  const handleNewImage = (e) => {
    setfileName({ name: e.target.files[0].name, type: e.target.files[0].type });
    if (e.target.files[0]?.size / (1024 * 1024) <= 30) {
      setImage(URL.createObjectURL(e.target.files[0]));
      activeIndex.current = data.length;
      setActiveImageIndex(data.length);
      setShowCrop(true);
    } else if (e.target.files[0]?.size / (1024 * 1024) > 30) {
      toast.error("The image must be less than 30MB in size.");
    }

    // setImage(URL.createObjectURL(e.target.files[0]));
    // activeIndex.current = data.length;
    // setActiveImageIndex(data.length);
    // setShowCrop(true);
  };

  const handleUpload = useCallback(async () => {
    try {
      // let finalCropObj;
      // if (crop.unit == "%") {
      //   const returnVal = await convertPercentageToPixel(
      //     crop,
      //     imgRef?.current?.width,
      //     imgRef?.current?.height
      //   );
      //   finalCropObj = returnVal;
      // } else {
      //   finalCropObj = crop;
      // }

      let reqImage = await getImage(imgRef.current, crop, fileName);

      const formData = new FormData();

      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });
      if (data?.[0]?.isDefault) {
        formData.append("_method", "PUT");
        formData.append(`profile_images[${activeImageIndex}][is_primary]`, 1);
        formData.append(`profile_images[${activeImageIndex}][file]`, reqImage);
      } else if (activeImageIndex <= data.length - 1) {
        formData.append("_method", "PUT");
        formData.append(
          `profile_images[${activeImageIndex}][is_primary]`,
          data.length === 1
            ? 1
            : Number(Boolean(data[activeImageIndex]?.isPrimary)) ?? 0
        );
        formData.append(
          `profile_images[${activeImageIndex}][_id]`,
          data[activeImageIndex]?.id ?? ""
        );
        formData.append(`profile_images[${activeImageIndex}][file]`, reqImage);
      } else {
        formData.append("_method", "PUT");
        formData.append(`profile_images[${activeImageIndex}][is_primary]`, 0);
        formData.append(`profile_images[${activeImageIndex}][file]`, reqImage);
      }

      updateProfile(formData)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            toast.success("Image Uploaded Successfully!");
            setImage(null);
            refetch();
            handleClose();
          } else if (response?.data?.status_code === 400) {
            Object.keys(response?.data?.message).forEach((field) => {
              toast.error(response?.data?.message?.[field]?.[0]);
            });
          } else {
            toast.error("Failed to upload image!");
          }
        })
        .catch(() => {
          toast.error("Failed to upload image!");
        });
    } catch (e) {
      toast.error("Failed to upload Image!");
    }
    // eslint-disable-next-line
  }, [crop, activeImageIndex, data.length]);

  const handleImageLimit = () => {
    toast.error("Image limit exceeded, maximum is 7");
  };

  const handleFreeCropClick = () => {
    setShowCrop(true);
    setAspectRatio(false);
  };

  return {
    activeImageIndex,
    showCrop,
    crop,
    image,
    imgRef,
    isLoading,
    aspectRatio,
    handleFreeCropClick,
    handleImageLimit,
    setCrop,
    handleThumbClick,
    handleCropClick,
    handleCropCancel,
    handlePrimary,
    handleUpload,
    handleNewImage,
  };
};

export default useProfileCard;
