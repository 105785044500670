import { useDispatch, useSelector } from "react-redux";

import { useGetOverviewDataQuery, useGetProfilePaymentListDataQuery } from "../../../store/queries/Profile";
import { useSearchParams } from "react-router-dom";
import { updateConfig } from "../../../store/slices/Profile/ProfilePayment";
import { useMemo } from "react";

const useProfilePayment = () => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const profilePaymentState = useSelector((state) => state?.profilePayment);
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const {
    data: profilePaymentList = {},
    isFetching,
    refetch,
  } = useGetProfilePaymentListDataQuery({
    user_id: activeProfile,
    page: profilePaymentState.currentPage,
    sort_by: profilePaymentState.sortBy,
    sort_order: profilePaymentState.sortOrder,
    page_size: profilePaymentState.currentPageSize,
  });

  const {
    data: overViewData,
    // refetch,
  } = useGetOverviewDataQuery({
    user_id: activeProfile,
  });

  const hasCreatePermission = useMemo(() => {
    let permission = profilePaymentList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permission?.[0]?.can_create ?? 0;
  }, [profilePaymentList]);

  

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleSort = (label) => {
    if (profilePaymentState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            profilePaymentState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      staff_id: (feild, data) => (
        <p className="pro-mb-0">{data[feild]?.name ?? ""}</p>
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  return {
    profilePaymentList,
    profilePaymentState,
    currentPage: profilePaymentState.currentPage,
    paginationOptions,
    isFetching,
    hasCreatePermission,
    activeProfile,
    overViewData,
    refetch,
    handlePagination,
    handlePageSize,
    handleSort,
    getRow,
  };
};

export default useProfilePayment;
