import React from "react";
import Select from "react-select";
import { Input } from "@wac-ui-dashboard/wac_component_library";

const RowOne = ({
  formik,
  optionsData,
  getFieldError,
  prestDistrictRef,
  prestStateRef,
}) => {
  return (
    <>
      <div className="pro-py-5">
        <h6 className="pro-ttl h6">Present Address</h6>
        <Input
          label={"House No. / name"}
          id="present_address[house_name]"
          type="text"
          name="present_address[house_name]"
          {...formik.getFieldProps("present_address[house_name]")}
          value={
            formik.values.present_address?.house_name === "null"
              ? ""
              : formik.values.present_address?.house_name
          }
          className={`pro-input  lg  ${
            formik.errors.present_address?.house_name &&
            formik.touched.present_address?.house_name &&
            " error"
          }`}
          error={
            formik.errors.present_address?.house_name &&
            formik.touched.present_address?.house_name
          }
          errorMessage={formik.errors.present_address?.house_name}
        />
        <Input
          label={"Street"}
          id="present_address[street]"
          type="text"
          {...formik.getFieldProps("present_address[street]")}
          value={
            formik.values.present_address?.street === "null"
              ? ""
              : formik.values.present_address?.street
          }
          name="present_address[street]"
          className={`pro-input lg  ${
            formik.errors.present_address?.street &&
            formik.touched.present_address?.street &&
            " error"
          }`}
          error={
            formik.errors.present_address?.street &&
            formik.touched.present_address?.street
          }
          errorMessage={formik.errors.present_address?.street}
        />

        <Input
          label={"Pincode"}
          type="text"
          id="present_address[pincode]"
          name="present_address[pincode]"
          {...formik.getFieldProps("present_address[pincode]")}
          value={
            formik.values.present_address?.pincode === "null"
              ? ""
              : formik.values.present_address?.pincode
          }
          className={`pro-input lg ${
            getFieldError("present_address.pincode") && " error"
          }`}
          error={getFieldError("present_address.pincode")}
          errorMessage={getFieldError("present_address.pincode")}
        />

        <div className={"input-wrap pro-mb-5"}>
          <label
            htmlFor="current_location"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Country
          </label>
          <Select
            id="present_address[country]"
            isClearable={true}
            placeholder={"Select"}
            className={`pro-input lg  ${
              getFieldError("present_address.country") && " error"
            }`}
            classNamePrefix="pro-input"
            options={optionsData?.country}
            getOptionValue={(option) => option?._id}
            getOptionLabel={(option) => option?.name}
            value={optionsData?.country?.filter(
              (m) => formik.values.present_address?.country?._id === m?._id
            )}
            onChange={(value) => {
              formik.setFieldValue("present_address[country]", value || null);
              prestStateRef.current.setValue([], "clear");
              prestDistrictRef.current.setValue([], "clear");
            }}
          />
        </div>
        {getFieldError("present_address.country") && (
          <span className="error-text">
            {getFieldError("present_address.country")}
          </span>
        )}

        <div className={"input-wrap pro-mb-5"}>
          <label
            htmlFor="current_location"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            State
          </label>
          <Select
            id="present_address[state]"
            isClearable={true}
            ref={prestStateRef}
            isDisabled={
              formik.values.present_address?.country?._id ? false : true
            }
            placeholder={"Select"}
            className={`pro-input lg  ${
              getFieldError("present_address.state") && " error"
            }`}
            classNamePrefix="pro-input"
            options={optionsData?.state?.filter(
              (p) =>
                p?.country_id ===
                formik.values.present_address?.country?.unique_country_id
            )}
            getOptionValue={(option) => option?._id}
            getOptionLabel={(option) => option?.name}
            value={optionsData?.state?.filter(
              (m) => formik.values.present_address?.state?._id === m?._id
            )}
            onChange={(value) => {
              formik.setFieldValue("present_address[state]", value || null);
              prestDistrictRef.current.setValue([], "clear");
            }}
          />
        </div>
        {getFieldError("present_address.state") && (
          <span className="error-text">
            {getFieldError("present_address.state")}
          </span>
        )}

        <div className={"input-wrap pro-mb-2"}>
          <label
            htmlFor="current_location"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            District
          </label>
          <Select
            id="present_address[district]"
            ref={prestDistrictRef}
            isDisabled={
              formik.values.present_address?.state?._id ? false : true
            }
            isClearable={true}
            placeholder={"Select"}
            className={`pro-input lg  ${
              getFieldError("present_address.district") && " error"
            }`}
            classNamePrefix="pro-input"
            options={optionsData?.district?.filter(
              (p) =>
                p?.state_id ===
                formik.values.present_address?.state?.unique_state_id
            )}
            getOptionValue={(option) => option?._id}
            getOptionLabel={(option) => option?.name}
            value={optionsData?.district?.filter(
              (m) => formik.values.present_address?.district?._id === m?._id
            )}
            onChange={(value) =>
              formik.setFieldValue("present_address[district]", value || null)
            }
          />
        </div>
      </div>

      {getFieldError("present_address.district") && (
        <span className="error-text">
          {getFieldError("present_address.district")}
        </span>
      )}
    </>
  );
};

export default RowOne;
