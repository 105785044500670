import { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTransactionDetails, updateConfig } from "../../../store/slices/payment/transactionsSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  useGetTransactionsListDataQuery,
  useUpdateTransactionsListDataMutation,
} from "../../../store/queries/payment";
import { getFormatedDate } from "../../../utils/functions/table";
import { useGetProfilePaymentListDataQuery } from "../../../store/queries/Profile";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import {
  getExportedData,
  getTransactionStatusData,
  updatePaymentType,
} from "./api";
import { subDays } from "date-fns";
import { ProfileNavigation } from "../../Global/ProfileNavigation";
import StatusButton from "./StatusButton";
import { useGetMemberCreationFormDataQuery } from "../../../store/queries/members";
import { getPaymentModes, getTypesData } from "../AddPaymentForm/api";
import Select from "react-select";
import Style from "./transactions.module.scss";
import { toast } from "react-toastify";

const useTransactions = (dashboard) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const TransactionsState = useSelector((state) => state.transactions);
  const [selectedUser, setSelectedUser] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [transactionStatesData, setTransactionStatesData] = useState();
  const [activeTab, setActiveTab] = useState("payment");
  const { showCreateModal, transactionId, showInvoiceModal, selectedUserId } =
    useSelector((state) => state.transactions);
  const [skip, setSkip] = useState(true);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const { data: branchOptionsData = {} } = useGetMemberCreationFormDataQuery(
    {}
  );

  const [params] = useSearchParams();
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");
  const {
    data: transactions = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetTransactionsListDataQuery({
    sort_by: TransactionsState.sortBy,
    sort_order: TransactionsState.sortOrder,
    filter: TransactionsState.currentFilter,
    search: TransactionsState.search,
    start: dashboard ? "" : getFormatedDate(TransactionsState.startDate),
    end: dashboard ? "" : getFormatedDate(TransactionsState.endDate),
    page_size: TransactionsState.currentPageSize,
    page: TransactionsState.currentPage,
    branch_id: TransactionsState.branch,
    payment_type: TransactionsState.payment,
  });

  useEffect(() => {
    if (TransactionsState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [TransactionsState.clearSelection]);

  const { data: profilePaymentHistory = {} } =
    useGetProfilePaymentListDataQuery(
      {
        user_id: selectedUser,
        page: 1,
      },
      { skip }
    );

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [date, setDate] = useState({
    startDate: TransactionsState.startDate,
    endDate: TransactionsState.endDate,
    key: "selection",
  });

  const [updateTransactionFields] = useUpdateTransactionsListDataMutation();

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = transactions?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [transactions]);

  useEffect(() => {
    getTransactionStatusData().then((response) => {
      setTransactionStatesData(response?.data?.data?.payment_status);
    });
    getTypesData().then((response) => {
      setPaymentTypes(response?.data?.data?.payment_type);
    });
  }, []);

  useEffect(() => {
    if (!dashboard) {
      setDate({
        startDate: subDays(new Date(), 30),
        endDate: new Date(),
        key: "selection",
      });
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.currentPageSize = 10;
          state.currentFilter = "PURCHASED";
          state.startDate = subDays(new Date(), 30);
          state.endDate = new Date();
          state.sortBy = "";
          state.sortOrder = "";
          state.search = "";
          state.showCreateModal = false;
          state.showInvoiceModal = false;
          state.transactionId = "";
          state.invoiceLoading = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [dashboard]);

  const filters = useMemo(() => {
    if (transactions?.data?.filters) {
      const buttonGroups = transactions?.data?.filters?.filter((d) => !d?.type);
      return [{ type: "buttonGroup", buttons: buttonGroups }];
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [isLoading, transactions]);

  const useDataOptions = useMemo(
    () =>
      transactionStatesData?.map((opt) => {
        return { value: opt.name, label: opt.name, id: opt.id };
      }),
    [transactionStatesData]
  );

  const handleFilter = (filter) => {
    if (filter.value === "PURCHASED") {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;

          state.sortBy = "";
          state.sortOrder = "";
          state.currentPage = 1;
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.currentPage = 1;
        })
      );
    }
  };

  const hasExportPermission = useMemo(() => {
    let permission = transactions?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_export")
    );

    return permission?.[0]?.can_export ?? 0;
  }, [transactions]);

  const hasCreatePermission = useMemo(() => {
    let permission = transactions?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permission?.[0]?.can_create ?? 0;
  }, [transactions]);

  const hasEditPermission = useMemo(() => {
    let permission = transactions?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );

    return permission?.[0]?.can_edit ?? 0;
  }, [transactions]);

  const hasUpdatePermission = useMemo(() => {
    let permission = transactions?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_update")
    );

    return permission?.[0]?.can_update ?? 0;
  }, [transactions]);

  const hasViewPermission = useMemo(() => {
    let permission = transactions?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view")
    );
    return permission?.[0]?.can_view ?? 0;
    // eslint-disable-next-line
  }, [transactions]);

  const hasStatusUpdatePermission = useMemo(() => {
    let permission = transactions?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_status")
    );
    return permission?.[0]?.can_status ?? 0;
    // eslint-disable-next-line
  }, [transactions]);
  const hasPaymentMethodChangePermission = useMemo(() => {
    let permission = transactions?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_change_method")
    );
    return permission?.[0]?.can_change_method ?? 0;
    // eslint-disable-next-line
  }, [transactions]);

  const handleSort = (label) => {
    if (TransactionsState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder =
            TransactionsState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };
  const closeModal = () => {
    setActiveTab("payment");
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.selectedUserId = "";
        state.is_edit = false;
        state.transactionId = "";
        state.selectedItemsDetails = "";
        state.clearSelection = true;
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };
  const paymentsOptions = useMemo(
    () =>
      paymentTypes?.map((opt) => {
        return { value: opt.id, label: opt.name };
      }),
    [paymentTypes]
  );

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      profile_id: (_, data) => (
        <ProfileNavigation
          userId={data?.user_id}
          fieldValue={data?.profile_id}
          activeProfile={activeProfile}
          params={params}
          hasViewPermission={hasViewPermission === 1 ? true : false}
        />
      ),
      payment_status: (feild, data) =>
        hasStatusUpdatePermission === 1 &&
        TransactionsState.currentFilter === "PURCHASED" ? (
          <StatusButton
            data={data}
            refetch={refetch}
            useDataOptions={useDataOptions}
          />
        ) : (
          <p className="pro-mb-0">{data[feild] ?? ""}</p>
        ),
      payment_type: (field, data) =>
        hasPaymentMethodChangePermission === 1 ? (
          <div className="col-12 no-border" style={{ minWidth: "222px" }}>
            <Select
              options={paymentsOptions}
              value={paymentsOptions?.filter(
                (item) => item?.label == data?.payment_type
              )}
              menuPlacement="auto"
              menuPosition="fixed"
              onChange={(e) => handlePaymentMethodChange(data, e)}
              className={Style.reactSelectContainer}
              classNamePrefix="pro-input"
            />
          </div>
        ) : (
          <p className="pro-mb-0"> {data[field] ?? ""}</p>
        ),
      action: (feild, data) =>
        hasViewPermission === 1 && !dashboard ? (
          <p
            className="pro-mb-0 pro-pnt"
            onClick={() => handleInvoiceClick(feild, data)}
          >
            Print Invoice
          </p>
        ) : (
          <></>
        ),
      amount: (feild, data) => <p className="pro-mb-0">₹ {data[feild]}</p>,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };
  const handlePaymentMethodChange = async (data, e) => {
    let formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("payment_transaction_id", data?._id);
    formData.append("payment_type", e?.value);
    const response = await updatePaymentType(formData);
    if (response?.data?.status_code === 200) {
      toast.success(`Payment method changed`);
      refetch();
    } else if (response?.status_code === 422 && response?.errors) {
      Object.keys(response?.errors).forEach((field) => {
        toast.error(response?.errors[field]);
      });
    } else {
      toast.error(`Payment method change failed,Please try again`);
    }
  };

  const handleInvoiceClick = (feild, data) => {
    if (hasViewPermission) {
      dispatch(
        updateConfig((state) => {
          state.showInvoiceModal = true;
          state.invoiceLoading = true;
          state.transactionId = data?._id;
        })
      );
    }
  };

  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const closeInvoiceModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showInvoiceModal = false;
      })
    );
  };

  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    } else {
      getExportedData({
        fields: Object.keys(transactions?.data?.fields),
        filter: TransactionsState.currentFilter,
        sort_by: TransactionsState.sortBy,
        sort_order: TransactionsState.sortOrder,
        start: getFormatedDate(TransactionsState.startDate),
        end: getFormatedDate(TransactionsState.endDate),
        search: TransactionsState.search,
        branch_id: TransactionsState.branch,
        payment_type: TransactionsState.payment,
      }).then((response) => {
        let dataType = response?.data?.type;
        let binaryData = [];
        binaryData.push(response?.data);
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute(
          "download",
          `${moment(Date.now()).format("MM_DD_YYYY_HH_mm_ss")}.xlsx`
        );
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.currentFilter = "PURCHASED";
        state.startDate = subDays(new Date(), 30);
        state.endDate = new Date();
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.transactionId = "";
        state.invoiceLoading = false;
      })
    );
  };

  const handleActiveTab = (switchTab) => {
    if (switchTab === "history" && selectedUserId) {
      setActiveTab(switchTab);
    }
    if (switchTab === "payment") {
      setActiveTab(switchTab);
      // setSelectedUser([]);
      // dispatch(
      //   updateConfig((state) => {
      //     state.selectedUserId = [];
      //   })
      // );
    }
  };

  let TransactionItems = {
    total_collection: {
      label: "total_collection",
      title: "Total Collection",
      icon: "payments",
    },
    today_collection: {
      label: "today_collection",
      title: "Today",
      prev: "Yesterday",
      icon: "savings",
    },
    weekly_collection: {
      label: "weekly_collection",
      title: "This week",
      prev: "Last week",
      icon: "account_balance_wallet",
    },
    monthly_collection: {
      label: "monthly_collection",
      title: "This month",
      prev: "Last month",
      icon: "savings",
    },
  };
  const handleDashboardRedirect = (filter) => {
    navigate("/payment/transactions");
  };

  const handleClose = () => {
    dispatch(
      updateConfig((state) => {
        state.statusModal = false;
      })
    );
  };

  const handleTableFilter = (value) => {
    dispatch(
      updateConfig((state) => {
        state.branch = value?._id;
      })
    );
  };
  const handlePaymentFilter = (value) => {
    dispatch(
      updateConfig((state) => {
        state.payment = value?.id;
      })
    );
  };

  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
      })
    );
  };

  const headerLabel = {
    amount_received: "Amount Received",
    transactions_count: "Transactions Count",
  };

  const HeaderValue = {
    transactions_count: `${
      transactions?.data?.collection_summary?.transactions_count ?? "0"
    }`,
    amount_received: `${
      transactions?.data?.collection_summary?.amount_received ?? "0"
    }`,
  };

  const headerDetails = Object.keys(HeaderValue).map((key) => {
    return {
      label: headerLabel[key],
      value:
        typeof HeaderValue[key] === "string"
          ? HeaderValue?.[key]
          : typeof HeaderValue[key] === "number"
          ? HeaderValue?.[key]
          : HeaderValue?.[key]?.name,
    };
  });
  const allPaymentOption = {
    id: "",
    name: "All Payment Type",
  };

  const paymentOption = [allPaymentOption, ...(paymentTypes ?? [])];

  const handleEditAction = (data) => {
    dispatch(getTransactionDetails(data?.[0]))
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.is_edit = true;
        state.transactionId = data?.[0];
      })
    );
  };

  return {
    transactions,
    TransactionsState,
    TransactionItems,
    currentPage: TransactionsState.currentPage,
    showCreateModal,
    hasCreatePermission,
    isFetching,
    isLoading,
    filters,
    activeFilter: TransactionsState.currentFilter,
    activeTab,
    date,
    showEditModal,
    tableFields,
    paginationOptions,
    showInvoiceModal,
    transactionId,
    profilePaymentHistory: profilePaymentHistory?.data,
    selectedUser,
    actionOptions,
    selectedUserId,
    hasExportPermission,
    headerDetails,
    paymentOption,
    handleDateRangeChange,
    handleTableFilter,
    branchOptionsData,
    handleClose,
    setSkip,
    handleDashboardRedirect,
    handleActionChange,
    setSelectedUser,
    refetch,
    handleFilter,
    updateTransactionFields,
    handleSort,
    handleSearch,
    handleCreateClick,
    closeModal,
    getRow,
    handleEditClick,
    closeEditModal,
    handleDateChange,
    handlePagination,
    handlePageSize,
    handleActiveTab,
    closeInvoiceModal,
    handleClearClick,
    handlePaymentFilter,
    handleEditAction,
    hasEditPermission,
    hasUpdatePermission
  };
};

export default useTransactions;
