import React from "react";
import Style from "./switchableNav.module.scss";

const SwitchableNav = ({ activeTab, selectedUserId, handleActiveTab, is_edit }) => {
  return (
    <div className={Style.root}>
      <div className={`${Style.root_inner} pro-d-flex`}>
        <div
          className={`${Style.root_item} ${
            activeTab === "payment" ? Style.active : ""
          } `}
        >
          <button
            className={`pro-btn-link pro-text-center pro-w-100 full pro-p-4`}
            onClick={() => handleActiveTab("payment")}
          >
            Add Payment
          </button>
        </div>
        {!is_edit && (
          <div
          className={`${Style.root_item} ${
            !selectedUserId ? " pro-no-point" : ""
          } ${activeTab === "history" ? Style.active : ""}`}
        >
          <button
            className={`pro-btn-link pro-text-center pro-w-100 full pro-p-4`}
            onClick={() => handleActiveTab("history")}
          >
            History
          </button>
        </div>
        )}
        
      </div>
    </div>
  );
};

export default SwitchableNav;
