import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "desc",
  data: [],
  showCreateModal: false,
  formEditMode: false,
  selectedItemDetails: "",
  search: "",
  clearSelection: false,
};

export const deleteItemFromTable = createAsyncThunk(
  "faqs/deleteItemFromTable",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.delete(
        `/admin/faq/delete?${new URLSearchParams(params).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateItemFromTableByID = createAsyncThunk(
  "faqs/updateItemFromTableByID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/admin/faq/update`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const faqsSlice = createSlice({
  name: "faqs",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setSelectedItemDetails: (state, action) => {
      state.selectedItemDetails = action.payload;
    },
    editModeToggle: (state, action) => {
      state.formEditMode = action.payload;
    },
  },
});

export const { updateConfig, setSelectedItemDetails, editModeToggle } =
  faqsSlice.actions;

export default faqsSlice.reducer;
