import React from "react";
import Style from "./addStaffForm.module.scss";
import {
  DatePickerWithInput,
  IconText,
  Image,
} from "@wac-ui-dashboard/wac_component_library";
import {
  getFormatedDate,
  limitStrLength,
} from "../../../../utils/functions/table";

const AddFormFields = ({
  formik,
  optionsWithIcons,
  staffList,
  selectedOption,
  setSelectedOption,
  options,

  handleFileChange,
  imagePreview,
  testimonialsState,
}) => {
  // const handleRemoveProfileImage = (e) => {

  //   e.preventDefault();
  //   document.getElementById("image").value = "";
  //   formik.setFieldValue("image", "");
  // };
  return (
    <div className={Style.root}>
      <div className="col-12 pro-mb-4">
        <div className="row">
          <div className="col-4 pro-mb-4">
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Bride Name
              </label>
              <input
                type="text"
                className={`pro-input lg ${
                  formik.errors.brideName &&
                  formik.touched.brideName &&
                  " error"
                }`}
                id="brideName"
                name="brideName"
                {...formik.getFieldProps("brideName")}
              />
              {formik.touched.brideName && formik.errors.brideName && (
                <span className="error-text">{formik.errors.brideName}</span>
              )}
            </div>
          </div>
          <div className="col-4 pro-mb-4">
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Groom Name
              </label>
              <input
                type="text"
                className={`pro-input lg ${
                  formik.errors.groomName &&
                  formik.touched.groomName &&
                  " error"
                }`}
                id="groomName"
                name="groomName"
                {...formik.getFieldProps("groomName")}
              />
              {formik.touched.groomName && formik.errors.groomName && (
                <span className="error-text">{formik.errors.groomName}</span>
              )}
            </div>
          </div>
          <div className="col-4 pro-mb-4">
            <div className={`input-wrap pro-mb-4 ${Style.date_input}`}>
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Date of marriage
              </label>
              <DatePickerWithInput
                // ref={dobRef}
                // initialValue={new Date()}
                CalendarOutlineIcon={
                  <span className="material-symbols-outlined ">
                    {" "}
                    calendar_month{" "}
                  </span>
                }
                id="dateOfMarrige"
                placeholder={formik?.values?.dateOfMarrige ?? "Select Date"}
                className={`pro-input lg`}
                onDateChange={(value) => {
                  formik.setFieldValue("dateOfMarrige", getFormatedDate(value));
                }}
                // isError={
                //   formik.touched.dateOfMarrige &&
                //   formik.errors.dateOfMarrige && (
                //     <span className="error-text">
                //       {formik.errors.dateOfMarrige}
                //     </span>
                //   )
                // }
                
                // errorMessage={
                //   formik.touched.dateOfMarrige &&
                //   formik.errors.dateOfMarrige && (
                //     <span className={`error-text ${Style.error_text}`}>
                //       {formik.errors.dateOfMarrige}
                //     </span>
                //   )
                // }
                maxDate={new Date()}
              />
              {formik.touched.dateOfMarrige && formik.errors.dateOfMarrige && (
                <span className={`error-text ${Style.error_text}`}>
                  {formik.errors.dateOfMarrige}
                </span>
              )}
            </div>
          </div>
          {/* <div className="col pro-mb-4">
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Date of marriage
              </label>
              <input
                type="text"
                className={`pro-input lg ${
                  formik.errors.dateOfMarrige &&
                  formik.touched.dateOfMarrige &&
                  " error"
                }`}
                id="dateOfMarrige"
                name="dateOfMarrige"
                {...formik.getFieldProps("dateOfMarrige")}
                placeholder="15-03-2023"
              />

              {formik.touched.dateOfMarrige && formik.errors.dateOfMarrige && (
                <span className="error-text">
                  {formik.errors.dateOfMarrige}
                </span>
              )}
            </div>
          </div> */}
        </div>
        <div className="row">
          <div className="col-12 pro-mb-4">
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Testimonial
              </label>
              <textarea
                rows={5}
                className={`pro-input lg ${
                  formik.errors.testimonialText &&
                  formik.touched.testimonialText &&
                  " error"
                }`}
                id="testimonialText"
                name="testimonialText"
                {...formik.getFieldProps("testimonialText")}
              ></textarea>
              {formik.touched.testimonialText &&
                formik.errors.testimonialText && (
                  <span className="error-text">
                    {formik.errors.testimonialText}
                  </span>
                )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 pro-mb-4">
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Image
              </label>
              <div className="input-drag">
                <input
                  type="file"
                  accept=".jpeg, .jpg , .png"
                  className={`pro-input lg ${
                    formik.errors.image && formik.touched.image && " error"
                  }`}
                  id="image"
                  name="image"
                  onChange={(e) => handleFileChange(e)}
                />

                <span className="input-drag-box">
                  <IconText
                    title={
                      formik?.values?.image?.name !== undefined
                        ? // <>
                          // {
                          limitStrLength(formik?.values?.image?.name, 30)
                        : // }
                        //   <span
                        //     className="material-symbols-outlined"
                        //     onClick={(e) => handleRemoveProfileImage(e)}
                        //   >
                        //     close
                        //   </span>
                        // </>
                        testimonialsState?.selectedItemsDetails?.image
                        ? // <>
                          // {
                          testimonialsState?.selectedItemsDetails?.image
                        : // }
                          //   <span
                          //     className="material-symbols-outlined"
                          //     onClick={(e) => handleRemoveProfileImage(e)}
                          //   >
                          //     close
                          //   </span>
                          // </>
                          `Drop files to attach or browse`
                    }
                  />
                </span>

                {formik.touched.image && formik.errors.image && (
                  <span className="error-text">{formik.errors.image}</span>
                )}
              </div>
              <div className={`col-2 pro-my-2`}>
                <div className={`${Style.root_image_inner}`}>
                  <Image
                    width={100}
                    height={100}
                    src={imagePreview}
                    alt={`image - 01`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFormFields;
