import { useRef } from "react";
import { Outlet } from "react-router-dom";
import Style from "./Auth.module.scss";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../assets/Assets";
import { useSelector } from "react-redux";

const AuthModule = () => {
  const emailRef = useRef("");
  const globalState = useSelector((state) => state.global);

  return (
    <div className={Style.root}>
      <div className={Style.root_inner}>
        <div className={`${Style.logoWrapper} pro-pb-5`}>
          <Image
            src={
              globalState.currentTheme === "light" ||
              globalState.currentTheme === null
                ? Assets.HEADERLOGO
                : Assets.HEADERLOGODARK
            }
            width={201}
            height={50}
            alt={`Matrimony Logo`}
          />
        </div>
        <div className={`${Style.auth} pro-pt-3`}>
          <Outlet context={{ emailRef }} />
        </div>
      </div>
    </div>
  );
};

export default AuthModule;
