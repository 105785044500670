import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useUpdateProfileDetailsMutation } from "../../../../store/queries/Profile";

const useAboutMyselfForm = ({
  userId,
  handleCancel,
  data,
  profileData,
  refetch,
}) => {
  const [sendRequest] = useUpdateProfileDetailsMutation();

  const formik = useFormik({
    initialValues: {
      about_me: data,
    },

    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = (values) => {
    let obj = {
      user_id: userId,
      first_name: profileData?.data?.first_name,
      last_name: profileData?.data?.last_name,
      country_code: profileData?.data?.country_code,
      phone_number: profileData?.data?.phone_number,
      gender: profileData?.data?.gender?.id,
      dob: profileData?.data?.dob,
      community_id: profileData?.data?.community_id?._id,
      diocese_id: profileData?.data?.diocese_id?._id,
    };

    sendRequest({ ...obj, ...values })
      .then((response) => {
        formik.setErrors("");
        if (response?.data?.status_code === 200) {
          refetch();
          toast.success("Profile Updated Successfully!");
          handleCancel();
        } else if (response?.error?.data?.status_code === 422) {
          formik.setErrors(response?.error?.data?.errors);
        } else {
          toast.error("Failed to Update Profile!");
          handleCancel();
        }
      })
      .catch(() => {
        toast.error("Failed to Update Profile!");
      });
  };
  return { formik };
};

export default useAboutMyselfForm;
