import React from "react";
import Select from "react-select";
import Style from "../preferenceForm.module.scss";
import "react-input-range/lib/css/index.css";
import { Input } from "@wac-ui-dashboard/wac_component_library";
import ReactSlider from "react-slider";

const FirstPart = ({
  formik,
  optionsData,
  gender,
  filteredDioceses,
  handleAgeRangeChange,
  handleHeightRangeChange,
  handleWeightRangeChange,
}) => {


  return (
    <>
      <div className="col-12">
        <div className="row row-cols-lg-2 row-cols-xl-3 row-cols-xxl-4">
          <div>
            <div className={"input-wrap pro-mb-5"}>
              <Input
                label={"Name or userID"}
                type="text"
                placeholder={"Name or userID"}
                id="user_search"
                name="user_search"
                className={`pro-input lg`}
                value={formik.values.user_search}
                {...formik.getFieldProps("user_search")}
              />
            </div>
          </div>
        </div>

        <div className="row row-cols-lg-2 row-cols-xl-3 row-cols-xxl-4">
          <div>
            <div className={"input-wrap pro-mb-5 pro-fw-medium"}>
              <label
                htmlFor="age"
                className="pro-font-sm pro-mb-2 pro-fw-medium"
              >
                Age Range
              </label>

              <ReactSlider
                defaultValue={[formik.values.age.min, formik.values.age.max]}
                className={`${Style.horizontalSlider}`}
                thumbClassName={`examplethumb`}
                trackClassName={`exampleTrack`}
                renderThumb={(props, state) => (
                  <div {...props}>{state.valueNow}</div>
                )}
                min={gender === 1 ? 18 : 21}
                max={50}
                value={[formik.values.age.min, formik.values.age.max]}
                onChange={(e) => {
                  handleAgeRangeChange(e);
                }}
              />
            </div>
          </div>

          <div>
            <div className={"input-wrap pro-mb-5"}>
              <label
                htmlFor="height"
                className="pro-font-sm pro-mb-2 pro-fw-medium"
              >
                Height
              </label>

              <ReactSlider
                defaultValue={[
                  formik.values.height.min,
                  formik.values.height.max,
                ]}
                className={`${Style.horizontalSlider}`}
                thumbClassName={`examplethumb`}
                trackClassName={`exampleTrack`}
                renderThumb={(props, state) => (
                  <div {...props}>{state.valueNow}</div>
                )}
                min={100}
                max={250}
                value={[formik.values.height.min, formik.values.height.max]}
                onChange={(e) => {
                  handleHeightRangeChange(e);
                }}
              />
            </div>
          </div>

          <div>
            <div className={"input-wrap pro-mb-5 pro-fw-medium"}>
              <label
                htmlFor="weight"
                className="pro-font-sm pro-mb-2 pro-fw-medium"
              >
                Weight
              </label>
              <ReactSlider
                defaultValue={[
                  formik.values.weight.min,
                  formik.values.weight.max,
                ]}
                className={`${Style.horizontalSlider}`}
                thumbClassName={`examplethumb`}
                trackClassName={`exampleTrack`}
                renderThumb={(props, state) => (
                  <div {...props}>{state.valueNow}</div>
                )}
                min={20}
                max={150}
                value={[formik.values.weight.min, formik.values.weight.max]}
                onChange={(e) => {
                  handleWeightRangeChange(e);
                }}
              />
            </div>
          </div>

          <div>
            <div className={"input-wrap pro-mb-5"}>
              <label
                htmlFor="marital_status"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Marital status
              </label>
              <Select
                id="marital_status"
                placeholder={"Select"}
                className={"pro-input lg"}
                classNamePrefix="pro-input"
                isMulti
                options={optionsData?.marital_status}
                getOptionValue={(option) => option?.id}
                getOptionLabel={(option) => option?.name}
                value={optionsData?.marital_status?.filter((m) =>
                  formik.values.marital_status?.includes?.(m.id)
                )}
                onChange={(value) =>
                  formik.setFieldValue(
                    "marital_status",
                    value.map((v) => v.id)
                  )
                }
              />
            </div>
          </div>
          
          <div>
            <div className={"input-wrap pro-mb-5 pro-fw-medium"}>
              <label
                htmlFor="Complexion"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Complexion
              </label>
              <Select
                id="body_complexion"
                placeholder={"Select"}
                className={"pro-input lg"}
                classNamePrefix="pro-input"
                isMulti
                options={optionsData?.body_complexion}
                getOptionValue={(option) => option?.id}
                getOptionLabel={(option) => option?.name}
                value={optionsData?.body_complexion?.filter((m) =>
                  formik.values.body_complexion?.includes?.(m.id)
                )}
                onChange={(value) =>
                  formik.setFieldValue(
                    "body_complexion",
                    value.map((v) => v.id)
                  )
                }
              />
            </div>
          </div>
          <div>
            <div className={"input-wrap pro-mb-5"}>
              <label
                htmlFor="language"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Mother Tongue
              </label>
              <Select
                id="language"
                placeholder={"Select"}
                className={"pro-input lg"}
                classNamePrefix="pro-input"
                isMulti
                options={optionsData?.language}
                getOptionValue={(option) => option?._id}
                getOptionLabel={(option) => option?.name}
                value={optionsData?.language?.filter((m) =>
                  formik.values.language?.includes?.(m._id?.toString?.())
                )}
                onChange={(value) =>
                  formik.setFieldValue(
                    "language",
                    value.map((v) => v._id?.toString?.())
                  )
                }
              />
            </div>
          </div>
          <div>
            <div className={"input-wrap pro-mb-5"}>
              <label
                htmlFor="community"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Community
              </label>
              <Select
                id="community"
                placeholder={"Select"}
                className={"pro-input lg"}
                classNamePrefix="pro-input"
                isMulti
                getOptionValue={(option) => option?._id}
                getOptionLabel={(option) => option?.name}
                options={optionsData?.community}
                value={optionsData?.community?.filter((item) =>
                  formik.values.community?.includes(item?._id)
                )}
                onChange={(value) => {
                  formik.setFieldValue(
                    "community",
                    value.map((v) => v?._id)
                  );
                }}
              />
            </div>
          </div>

          <div>
            <div className={"input-wrap pro-mb-5"}>
              <label
                htmlFor="diocese"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Diocese
              </label>
              <Select
                id="diocese"
                options={filteredDioceses}
                isDisabled={formik.values.community?.length > 0 ? false : true}
                isMulti
                placeholder={"Select"}
                className={"pro-input lg"}
                classNamePrefix="pro-input"
                getOptionValue={(option) => option?._id}
                getOptionLabel={(option) => option?.name}
                onBlur={formik.handleBlur("diocese")}
                value={filteredDioceses?.filter(obj => formik.values.diocese?.includes(obj._id))}
                onChange={(value) =>
                  formik.setFieldValue(
                    "diocese",
                    value?.map((v) => v?._id)
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FirstPart;
