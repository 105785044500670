import React from "react";
import Style from "./activityMonitor.module.scss";
export function ActivityMonitor({
  icon,
  title,
  index,
  children,
  lastElement,
  className,
}) {

  return (
    <div
      className={`${Style.root} ${className}`}
      ref={lastElement || null}
      key={index}
    >
      {icon && (
        <div
          className={`${Style.icon} pro-d-flex pro-justify-center pro-items-center`}
        >
          {icon}
        </div>
      )}
      <div className={`${Style.text} pro-ps-5 pro-pt-2`}>
        {title && (
          <p className={`${Style.title} pro-fw-medium pro-mb-0`}>{title}</p>
        )}
        {children && <div className={`${Style.body} pro-pt-2`}>{children}</div>}
      </div>
    </div>
  );
}
