import { getAxiosInstance } from "../../../../api";

export const UpdateData = async (formData) => {
  const api = await getAxiosInstance();

  try {
    const response = await api.post(
      "admin/message/change-status",
      formData
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};
