import React from "react";
import Style from "./preference.module.scss";

const Preference = ({
  data,
  title,
  EditBtnIcon,
  onEditIconClick,
  showMore,
  hasMore,
  sliceLength,
  handleHasmore,
}) => {

  return (
    <div className={Style.root}>
      <div className={Style.heading}>
        {title && <h6>{title}</h6>}
        <div className={Style.actionBtn}>
          <button className={`pro-btn-link pro-p-2`} onClick={onEditIconClick}>
            {EditBtnIcon}
          </button>
        </div>
      </div>
      <div className={Style.body}>
        <div className={Style.itemWrapper}>
          {data &&
            data?.slice(0, sliceLength ?? 5).map((item) => {
              return (
                <span
                  key={item.id}
                  className={`pro-badge badge-grey-outline lg pro-px-4 pro-py-1 pro-m-1`}
                >
                  <p className={`pro-mb-0`}>{item.title}</p>
                </span>
              );
            })}

          <span
            className={`pro-badge badge-grey-outline lg pro-py-1 ${Style.readMore}`}
          >
            {!showMore && (data?.length > sliceLength || data?.length > 5) ? (
              <p className={`pro-mb-0`} onClick={handleHasmore}>
                {data?.length - 5 || ""} more
              </p>
            ) : (
              <p className={`pro-mb-0`} onClick={handleHasmore}>
                {data?.length > 5 ? "show less" : ""}
              </p>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Preference;
