import { getAxiosInstance } from "../../../../api";

export const getInvoiceData = async (transactionId) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `admin/payments/receipt?payment_transaction_id=${transactionId}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};
