import { IconText } from "@wac-ui-dashboard/wac_component_library";
import React from "react";

export const Dashboard = ({ data, onClick }) => {
  if (!data?.dashboard) {
    return "";
  }
  return <IconText icon={<img src={data?.dashboard ?? ""} alt={"img"} />} />;
};

export const UserRow = ({ data, onClick }) => {
  if (!data?.user) {
    return "";
  }
  return <IconText icon={<img src={data?.user ?? ""} alt={"img"} />} />;
};

export const PaymentIcon = ({ data, onClick }) => {
  if (!data?.payments) {
    return "";
  }
  return <IconText icon={<img src={data?.payments ?? ""} alt={"img"} />} />;
};

export const CMSIcon = ({ data, onClick }) => {
  if (!data?.cms) {
    return "";
  }
  return <IconText icon={<img src={data?.cms ?? ""} alt={"img"} />} />;
};

export const SettingsIcon = ({ data, onClick }) => {
  if (!data?.configure) {
    return "";
  }
  return <IconText icon={<img src={data?.configure ?? ""} alt={"img"} />} />;
};

export const NotificationIcon = ({ data, onClick }) => {
  if (!data?.support) {
    return "";
  }
  return <IconText icon={<img src={data?.support ?? ""} alt={"img"} />} />;
};
