import { useDispatch, useSelector } from "react-redux";

import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { useSearchParams } from "react-router-dom";
import { ProfileNavigation } from "../../Global/ProfileNavigation";
import {
  useGetSubscribersListDataQuery,
  useUpdateSubscribersListFieldsDataMutation,
} from "../../../store/queries/cms";
import { updateConfig } from "../../../store/slices/CMS/subscribersSlice";
import { useMemo } from "react";

const useSubscribers = () => {
  const dispatch = useDispatch();

  const subscribersState = useSelector((state) => state.subscribers);
  const [params] = useSearchParams();
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");
  const { showEditModal } = useSelector((state) => state.global);

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [updateSubscribersFields] =
    useUpdateSubscribersListFieldsDataMutation();

  const {
    data: subscribers = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetSubscribersListDataQuery({
    page_size: subscribersState.currentPageSize,
    page: subscribersState.currentPage,
    sort_by: subscribersState.sortBy,
    sort_order: subscribersState.sortOrder,
    search: subscribersState.search,
  });

  const hasExportPermission = useMemo(() => {
    let permission = subscribers?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_export")
    );

    return permission?.[0]?.can_export ?? 0;
  }, [subscribers]);


  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const handleSort = (label) => {
    if (subscribersState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            subscribersState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      name: (_, data) => (
        <ProfileNavigation
          userId={data?.member_id}
          fieldValue={data?.name}
          activeProfile={activeProfile}
          params={params}
        />
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    } else {
    }
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.data = [];
        state.showCreateModal = false;
        state.showEditModal = false;
        state.selectedItemsDetails = "";
        state.search = "";
      })
    );
  };

  return {
    subscribers,
    handleClearClick,
    subscribersState,
    currentPage: subscribersState.currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    hasExportPermission,
    actionOptions,
    handleActionChange,
    handlePagination,
    handlePageSize,
    handleSort,
    handleSearch,
    getRow,
    closeEditModal,
    showEditModal,
    refetch,
    handleEditClick,
    updateSubscribersFields,
  };
};

export default useSubscribers;
