import { useDispatch, useSelector } from "react-redux";
import {
  useTestimonialsDeleteMultipleMutation,
  useTestimonialsDeleteSingleMutation,
} from "../../../store/queries/settings";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  deleteItemFromTable,
  setSelectedDetails,
  editItemFromTableByID,
  updateConfig,
} from "../../../store/slices/payment/followUpSlice";
import { getFormatedDate } from "../../../utils/functions/table";
import { useEffect, useMemo, useState } from "react";
import {
  useGetFollowupsListDataQuery,
  useUpdateFollowupsFieldsUpdateDataMutation,
} from "../../../store/queries/payment";
import { Button } from "react-bootstrap";
import { useGetOverviewDataQuery } from "../../../store/queries/Profile";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getExportedData } from "./api";
import { ProfileNavigation } from "../../Global/ProfileNavigation";
import { subDays } from "date-fns";
import moment from "moment";

const useFollowUp = (dashboard) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const followup = useSelector((state) => state.followup);

  const { showEditModal } = useSelector((state) => state.global);
  const [showform, setShowform] = useState(false);
  const [viewProfilePreview, setViewProfilePreview] = useState(false);
  const [skip, setSkip] = useState(true);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");
  const [selectedUserId, setSelectedUserId] = useState("");

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  useEffect(() => {
    if (followup.selectedFollowUpID !== "" && !showform) {
      dispatch(
        updateConfig((state) => {
          state.selectedFollowUpID = "";
        })
      );
    }
    // eslint-disable-next-line
  }, [showform]);

  useEffect(() => {
    if (!dashboard) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.currentPageSize = 10;
          state.startDate = subDays(new Date(), 30);
          state.endDate = new Date();
          state.sortBy = "";
          state.sortOrder = "";
          state.data = [];
          state.showCreateModal = false;
          state.showEditModal = false;
          state.selectedItemsDetails = [];
          state.selectedFollowUpID = "";
          state.search = "";
        })
      );
    }
    // eslint-disable-next-line
  }, [dashboard]);

  const [params] = useSearchParams();
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  const [updateFollowupsFields] = useUpdateFollowupsFieldsUpdateDataMutation();

  const [transfersDeleteSingle, { isLoading1 }] =
    useTestimonialsDeleteSingleMutation();

  const [transfersDeleteMultiple, { isLoading2 }] =
    useTestimonialsDeleteMultipleMutation();

  const {
    data: FollowUpList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetFollowupsListDataQuery({
    page_size: followup.currentPageSize,
    page: followup.currentPage,
    filter: followup.currentFilter,
    start: dashboard ? "" :getFormatedDate(followup.startDate),
    end: dashboard ? "" :getFormatedDate(followup.endDate),
    sort_by: followup.sortBy,
    sort_order: followup.sortOrder,
    search: followup.search,
  });

  const filters = useMemo(() => {
    if (FollowUpList?.data?.filters) {
      const buttonGroups = FollowUpList?.data?.filters?.filter((d) => !d?.type);
      return [{ type: "buttonGroup", buttons: buttonGroups }];
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [isLoading,FollowUpList]);

  const [date, setDate] = useState({
    startDate: followup.startDate,
    endDate: followup.endDate,
    key: "selection",
  });

  const hasExportPermission = useMemo(() => {
    let permission = FollowUpList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_export")
    );

    return permission?.[0]?.can_export ?? 0;
  }, [FollowUpList]);

  const hasUpdatePermission = useMemo(() => {
    let permission = FollowUpList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_update")
    );

    return permission?.[0]?.can_update ?? 0;
  }, [FollowUpList]);

  const hasPreviewPermission = useMemo(() => {
    let permission = FollowUpList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_preview")
    );
    return permission?.[0]?.can_preview;
    // eslint-disable-next-line
  }, [FollowUpList]);

  const hasEditPermission = useMemo(() => {
    let permission = FollowUpList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );
    return permission?.[0]?.can_edit ?? 0;
    // eslint-disable-next-line
  }, [FollowUpList]);

  const hasViewPermission = useMemo(() => {
    let permission = FollowUpList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view")
    );

    return permission?.[0]?.can_view ?? 0;
  }, [FollowUpList]);

  const {
    data: profileData,
    // isFetching,
    // isSuccess,
    // isError,
    // refetch,
  } = useGetOverviewDataQuery(
    {
      user_id: selectedUserId,
    },
    { skip }
  );

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handleFilter = (filter) => {
    if (filter.value === "all") {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
        
          state.sortBy = "";
          state.sortOrder = "";
          state.currentPage = 1;
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.currentPage = 1;
        })
      );
    }
  };

  const handleDateChange = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = range.startDate
        state.endDate = range.endDate
      })
    );
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };

  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteItemFromTable({ testimonial_id: selectedItemID[0] }))
        .unwrap()
        .then((result) => {
          if (result.data.data) {
            refetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
          }
        })
        .catch((err) => {
          setShowDeleteConfirm(false);
          
        });
    } else {
      setShowDeleteConfirm(false);
     ;
    }
  };
  const handleSort = (label) => {
    if (followup.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = followup.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

 
  const handleEditAction = (ids) => {
    if (Array.isArray(ids)) {
      dispatch(editItemFromTableByID({ testimonial_id: ids[0] }))
        .unwrap()
        .then((data) => {
          setShowform(true);
        });
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      note: (feild, data) => (
        <p className="pro-mb-0 pro-d-flex pro-justify-between pro-gap-5">
          <span className="pro-align-self-center">{data[feild]}</span>
          {hasEditPermission === 1 && !dashboard ? (
            <span className="pro-align-self-center">
              <Button
                onClick={() => {
                  dispatch(setSelectedDetails(data._id));
                  setShowform(true);
                }}
                className="pro-btn pro-btn-primary min"
              >
                Update
              </Button>
            </span>
          ) : (
            <></>
          )}
        </p>
      ),
      name: (feild, data) => (
        <span className="pro-d-flex pro-gap-4">
          {/* <p className="pro-mb-0 pro-align-self-center">{data[feild]}</p> */}
          <ProfileNavigation
            userId={data?.member_id}
            fieldValue={data?.name}
            activeProfile={activeProfile}
            params={params}
            hasViewPermission={
              hasPreviewPermission === 1 && !dashboard ? true : false
            }
          />
          {hasPreviewPermission === 1 && !dashboard ? (
            <Button
              className="pro-btn pro-btn-secondary pro-ms-auto pro-align-self-center"
              onClick={() => handlePreviewCanvas(data?.member_id)}
            >
              Preview
            </Button>
          ) : (
            <></>
          )}
        </span>
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handlePreviewCanvas = (id) => {
    setSelectedUserId(() => id);
    setSkip(() => false);
    setViewProfilePreview(() => true);
  };
  const closePreviewClick = () => {
    setViewProfilePreview(() => false);
    setSkip(() => true);
  };

  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    } else {
      getExportedData({
        fields: Object.keys(FollowUpList?.data?.fields),
        filter: followup.currentFilter,
        sort_by: followup.sortBy,
        start: getFormatedDate(followup.startDate),
        end: getFormatedDate(followup.endDate),
        sort_order: followup.sortOrder,
        search: followup.search,
      }).then((response) => {
        let dataType = response?.data?.type;
        let binaryData = [];
        binaryData.push(response?.data);
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute(
          "download",
          `${moment(Date.now()).format("MM_DD_YYYY_HH_mm_ss")}.xlsx`
        );
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.startDate = subDays(new Date(), 30);
        state.endDate = new Date();
        state.sortBy = "";
        state.sortOrder = "";
        state.data = [];
        state.showCreateModal = false;
        state.showEditModal = false;
        state.selectedItemsDetails = [];
        state.selectedFollowUpID = "";
        state.search = "";
      })
    );
  };

  const handleDashboardRedirect = (filter) => {
    navigate("/users/followup");
  };

  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
      })
    );
  };

  return {
    FollowUpList,
    handleClearClick,
    followup,
    activeFilter: followup.currentFilter,
    currentPage: followup.currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    actionOptions,
    profileData,
    viewProfilePreview,
    hasEditPermission,
    filters,
    date,
    hasUpdatePermission,
    hasViewPermission,
    handleDashboardRedirect,
    closePreviewClick,
    handleActionChange,
    handlePagination,
    handleDateChange,
    handlePageSize,
    handleSort,
    handleSearch,
    handleFilter,
    getRow,
    hasExportPermission,
    handleDateRangeChange,
    handleEditClick,
    closeEditModal,
    showEditModal,
    refetch,
    handleDelete,
    showform,
    setShowform,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleEditAction,
    updateFollowupsFields,
    transfersDeleteSingle,
    isLoading1,
    transfersDeleteMultiple,
    isLoading2,
  };
};

export default useFollowUp;
