import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "desc",
  data: [],
  showCreateModal: false,
  clearSelection: false,
  selectedState: "",
  editState: false,
  preSelectedCommunity: null,
  search: "",
};

export const saveStatesDetails = createAsyncThunk(
  "dioceses/saveStatesDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/admin/states/create", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteStates = createAsyncThunk(
  "states/deleteStates",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.put("admin/state/delete", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateStatesDetails = createAsyncThunk(
  "dioceses/updateStatesDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.put("/admin/states/update", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



export const getStateDetailsUpdate = createAsyncThunk(
  "states/getStateDetailsUpdate",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/admin/states/edit?${new URLSearchParams(params).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getStatesDetailsByID = createAsyncThunk(
  "states/getStatesDetailsByID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/admin/states/view?${new URLSearchParams(params).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const stateSlice = createSlice({
  name: "state",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    selectStates: (state, action) => {
      state.selectedStates = action.payload;
    },
    editStates: (state, action) => {
      state.editState = action.payload;
    },

    setPreSelectedCommunity: (state, action) => {
      state.preSelectedCommunity = action.payload;
    },
  },
  extraReducers: {
    [getStatesDetailsByID.pending]: (state, action) => {},
    [getStatesDetailsByID.fulfilled]: (state, action) => {
      state.selectedState = action.payload.data.data;
    },
    [getStatesDetailsByID.rejected]: (state, action) => {},

    [getStateDetailsUpdate.pending]: (state, action) => {},
    [getStateDetailsUpdate.fulfilled]: (state, action) => {
      state.selectedState = action.payload.data.data;
    },
    [getStateDetailsUpdate.rejected]: (state, action) => {},
  },
});

export const {
  updateConfig,
  selectStates,
  editStates,
  setPreSelectedCommunity,
} = stateSlice.actions;

export default stateSlice.reducer;
