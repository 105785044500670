import React from "react";
import {
  Button,
  HeadingGroup,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";
import Style from "../settingsForm.module.scss";

const RowOne = ({
  formik,
  otp,
  overViewData,
  phoneVerified,
  handleMenuOpen,
  handleMenuClose,
  handlePhoneNumberChange,
  settingsData,
  menuIsOpen,
  cuntryCodeOptions,
  hasUpdateContactPermission,
  handleContactUpdateClick,
  handleVerifyPhone,
  handleValidatePhone,
}) => {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <HeadingGroup
            title={"Contact info"}
            buttonTitle={
              hasUpdateContactPermission === 1 &&
              overViewData?.data?.data?.assigned_profile === 1
                ? "Update"
                : ""
            }
            extraClassName={`pro-mb-4 ${Style.heading}`}
            handleClick={handleContactUpdateClick}
            buttonProps={{
              disabled:
                overViewData?.data?.data?.assigned_profile === 1
                  ? settingsData?.data?.email === formik.values?.email &&
                    settingsData?.data?.phone_number ===
                      formik.values?.phone_number
                  : true,
            }}
          ></HeadingGroup>
        </div>

        <div className="col-4">
          <Input
            label={"Email"}
            type="text"
            autoComplete="new-password"
            id="email"
            disabled={
              formik.values?.phone_number !==
                settingsData?.data?.phone_number ||
              !(
                hasUpdateContactPermission === 1 &&
                overViewData?.data?.data?.assigned_profile === 1
              )
            }
            name="email"
            placeholder="name@example.com"
            className={`pro-input lg ${
              formik.errors.email && formik.touched.email && " error"
            }`}
            {...formik.getFieldProps("email")}
            error={formik.errors.email && formik.touched.email}
            errorMessage={formik.errors.email}
          />
        </div>

        <div className="row">
          <div className="col-4">
            <div className="input-wrap pro-mb-4 ">
              <div className="input-country-wrap ">
                <label
                  htmlFor=""
                  className="pro-font-sm pro-mb-1 pro-fw-medium"
                >
                  Mobile number
                </label>
                <div className="input-main-wrap verify-btn-wrap">
                  <div className="code-wrap pro-mb-4 ">
                    <Select
                      id="country_code"
                      options={cuntryCodeOptions}
                      isDisabled={
                        formik.values?.email !== settingsData?.data?.email ||
                        !(
                          hasUpdateContactPermission === 1 &&
                          overViewData?.data?.data?.assigned_profile === 1
                        )
                      }
                      // getOptionLabel={(option) => `${option.label}`}
                      getOptionValue={(option) => `${option.value}`}
                      onMenuOpen={handleMenuOpen}
                      onMenuClose={handleMenuClose}
                      menuIsOpen={menuIsOpen}
                      formatOptionLabel={({ label, title }) =>
                        menuIsOpen ? title : label
                      }
                      className={`country-select multi-select ${
                        formik.touched.country_code &&
                        formik.errors.country_code &&
                        " error"
                      }`}
                      value={cuntryCodeOptions?.filter(
                        (p) =>
                          p.value?.toString() ===
                          formik.values.country_code?.toString()
                      )}
                      classNamePrefix="pro-input"
                      onBlur={() => formik.handleBlur("country_code")}
                      onChange={(value) =>
                        formik.setFieldValue("country_code", value?.value)
                      }
                    ></Select>
                  </div>

                  <input
                    type="text"
                    id="phone_number"
                    name="phone_number"
                    value={formik.values.phone_number}
                    disabled={
                      formik.values?.email !== settingsData?.data?.email ||
                      phoneVerified?.status ||
                      !(
                        hasUpdateContactPermission === 1 &&
                        overViewData?.data?.data?.assigned_profile === 1
                      )
                    }
                    className={`pro-input lg ${
                      formik.errors.phone_number &&
                      formik.touched.phone_number &&
                      " error"
                    }`}
                    onBlur={() => formik.handleBlur("phone_number")}
                    onChange={(e) => handlePhoneNumberChange(e.target.value)}
                  />
                  {overViewData?.data?.data?.assigned_profile === 1 &&
                  hasUpdateContactPermission === 1 &&
                  formik.values.phone_number !==
                    settingsData?.data?.phone_number ? (
                    <Button
                      className={"pro-btn pro-btn-primary"}
                      onClick={
                        !phoneVerified?.verified
                          ? () => handleVerifyPhone()
                          : () => {}
                      }
                    >
                      {phoneVerified?.status
                        ? `Resend OTP`
                        : phoneVerified?.verified
                        ? `Verified`
                        : `Verify`}
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
                {formik.errors.phone_number && formik.touched.phone_number && (
                  <span className="error-text">
                    {formik.errors.phone_number}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="col-4">
            {phoneVerified?.status ? (
              <div>
                <label
                  htmlFor=""
                  className="pro-font-sm pro-mb-1 pro-fw-medium"
                >
                  OTP
                </label>
                <div className="verify-btn-wrap">
                  <input
                    type="text"
                    id="otp"
                    name="otp"
                    className={`pro-input lg ${
                      formik.errors.otp && formik.touched.otp && " error"
                    }`}
                    {...formik.getFieldProps("otp")}
                  />
                  <Button
                    className={"pro-btn pro-btn-primary"}
                    onClick={() => handleValidatePhone()}
                  >
                    validate
                  </Button>
                </div>
                {formik.errors.otp && formik.touched.otp && (
                  <span className="error-text">{formik.errors.otp}</span>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* {otp !== "" && !phoneVerified?.verified ? (
            <div className="pro-my-2 pro-pnt">OTP : {otp}</div>
          ) : (
            <></>
          )} */}
      </div>
    </>
  );
};

export default RowOne;
