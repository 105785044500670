import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddParishFormContainer from "./useAddParishFormContainer";
import AddParishForm from "../AddParishForm";

const AddParishFormContainer = ({ setShowform, refetch }) => {
  const { formik, loading, optionsWithIcons, staffList } =
    useAddParishFormContainer(setShowform, refetch);

  return (
    <div className={`pro-p-4`}>
      <AddParishForm
        optionsWithIcons={optionsWithIcons}
        staffList={staffList}
        formik={formik}
        setShowform={setShowform}
      />
      <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => setShowform(false)}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-mx-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {loading ? (
            <i
              className="spinner-border spinner-border-sm me-3"
              role="status"
              aria-hidden="true"
            ></i>
          ) : (
            ""
          )}
          Save
        </Button>
      </div>
    </div>
  );
};

export default AddParishFormContainer;
