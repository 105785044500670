export const ProfileNavigation = ({
  hasViewPermission,
  userId,
  fieldValue,
  activeProfile,
  params,
}) => {
  const ids = params.get("id")
    ? [...params.get("id").split(","), activeProfile]
    : [activeProfile];

  const newIds = ids.filter((id) => id !== userId).join(",");
  if (!fieldValue) {
    return "";
  }

  return (
    <p
      className={`pro-mb-0 ${hasViewPermission ? "pro-pnt" : ""} `}
      onClick={() => {
        if (hasViewPermission) {
          window.open(
            `${
              window.location.origin
            }/${`profile?active=${userId}&id=${newIds}`}`
          );
        }
      }}
    >
      {fieldValue}
    </p>
  );
};
