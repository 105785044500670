import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import { ProfileCard } from "./ProfileCard";
import OrderColumn from "../../Global/OrderColumn";
import useVerifications from "./useVerifications";
import { DocumentCard } from "./DocumentCard";
import { ProfileCard as IdImageCard } from "../../CMS/UsersList/ProfileCard";
import EmptyData from "../../Global/EmptyData";
import Style from "./verification.module.scss";
import DatePickerRsuite from "../../Global/CustomDateRangePicker";
import Select from "react-select";

const Verification = ({ dashboard = false }) => {
  const {
    verification,
    verificationState,
    filters,
    activeProfileID,
    currentPage,
    activeFilter,
    showImageVerificationModal,
    showDocumentVerificationModal,
    paginationOptions,
    isFetching,
    showEditModal,
    isLoading,
    viewId,
    imageData,
    actionOptions,
    hasExportPermission,
    handleDateRangeChange,
    handleDashboardRedirect,
    handleActionChange,
    setViewId,
    setImageData,
    closeIdModal,
    setShowDocumentVerificationModal,
    setShowImageVerificationModal,
    refetch,
    closeDocumentModal,
    handlePagination,
    handlePageSize,
    handleFilter,
    handleDateChange,
    handleSort,
    handleSearch,
    getRow,
    closeModal,
    handleEditClick,
    closeEditModal,
    updateVerificationFields,
    handleClearClick,
    handleTableFilter,
    branchesOption,
    date,
    hasUploadViewPermission,
    // hasViewPermission,
    // hasEditPermission,
  } = useVerifications(dashboard);

  return (
    <>
      {!dashboard ? (
        <HeadingGroup title={"Verification"} className={`pro-mb-4`} />
      ) : (
        <></>
      )}
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        {!dashboard ? (
          <SearchFilters
            data={filters}
            //showDateRange
            dropDownFilter={
              <>
                <div className="col-auto">
                  <DatePickerRsuite
                    defaultDateRange={date}
                    handleDateRangeChange={handleDateRangeChange}
                  />
                </div>
                <div className="col-2 payment-filter">
                  <Select
                    id="branch"
                    placeholder={"Select Branch"}
                    className={`pro-input lg`}
                    classNamePrefix="pro-input"
                    options={branchesOption}
                    getOptionValue={(option) => option?._id}
                    getOptionLabel={(option) => option?.name}
                    onChange={(value) => {
                      handleTableFilter(value);
                    }}
                  />
                </div>
              </>
            }
            activeFilter={activeFilter}
            onDateChange={handleDateChange}
            handleButtonGroupChange={handleFilter}
            onSearchInput={handleSearch}
            showActions={false}
            handleActionClick={handleEditClick}
            loading={isLoading}
            //showClearFilters
            handleClear={handleClearClick}
            initialDateRange={date}
            searchInputProps={{ value: verificationState?.search }}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
            actionOptions={actionOptions?.filter((item) =>
              hasExportPermission !== 1 ? item.value === 0 : true
            )}
            onActionOptionChange={handleActionChange}
          />
        ) : (
          <></>
        )}
        {verification?.data?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <div
            onClick={dashboard ? () => handleDashboardRedirect() : () => {}}
            className={!dashboard ? "pro-pt-3" : Style.dashboardTable}
          >
            <Table
              data={verification?.data?.data?.data || []}
              uniqueID={"user_unique_id"}
              fields={verification?.data?.fields}
              showCheckBox={false}
              SortIcon={<FaSort />}
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={verificationState.currentPageSize}
              extraClasssName={"dashboard_no_padding"}
            />
          </div>
        )}

        {verification?.data?.data?.data?.length > 0 && !dashboard && (
          <Pagination
            currentPage={currentPage}
            // totalPageCount={verification?.data?.data?.last_page}
            totalPageCount={Math.ceil(
              verification.data.data.total / verificationState.currentPageSize
            )}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === verificationState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        {!dashboard ? (
          <>
            <ModalLayout
              show={showImageVerificationModal}
              handleClose={closeModal}
            >
              <ProfileCard
                data={verificationState}
                activeID={activeProfileID}
                refetch={refetch}
                verificationState={verificationState}
                hasUploadViewPermission={hasUploadViewPermission}
                setShowImageVerificationModal={setShowImageVerificationModal}
              />
            </ModalLayout>

            <ModalLayout
              show={showDocumentVerificationModal}
              handleClose={closeDocumentModal}
            >
              <DocumentCard
                data={verificationState}
                activeID={activeProfileID}
                refetch={refetch}
                verificationState={verificationState}
                setViewId={setViewId}
                hasUploadViewPermission={hasUploadViewPermission}
                setImageData={setImageData}
                setShowDocumentVerificationModal={
                  setShowDocumentVerificationModal
                }
              />
            </ModalLayout>

            <ModalLayout show={viewId} handleClose={closeIdModal}>
              <IdImageCard
                data={imageData}
                handleClose={closeIdModal}
                hasUploadViewPermission={hasUploadViewPermission}
              />
            </ModalLayout>

            <ModalLayout show={showEditModal} handleClose={closeEditModal}>
              <div className="pro-m-5">
                <OrderColumn
                  title={"Choose which columns you see"}
                  refetchData={refetch}
                  tableFields={verification?.data?.fields}
                  moduleId={verification?.data?.module_id}
                  updateData={updateVerificationFields}
                />
              </div>
            </ModalLayout>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Verification;
