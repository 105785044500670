import React from "react";
import Style from "./addStaffForm.module.scss";
import Select from "react-select";

const AddFormFields = ({
  formik,
  optionsWithIcons,
  staffList,
  selectedOption,
  setSelectedOption,
  options,
  handleFileChange,
  singleFollowUpDetails,
}) => {


  return (
    <div className={Style.root}>
      <div className="col-12 pro-mb-4">
        <div className="row">
          <div className="col-12 pro-mb-4">
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Select status
              </label>
              <Select
                id="status"
                options={options}
                placeholder="Select"
                getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => `${option.value}`}
                className={`pro-input lg multi-select ${
                  formik.touched.status && formik.errors.status && " error"
                }`}
                // value={option?.filter(
                //   (p) =>
                //     p.value?.toString() === formik.values.status?.toString()
                // )}
                classNamePrefix="pro-input"
                onBlur={() => formik.handleBlur("status")}
                onChange={(value) =>
                  formik.setFieldValue("status", value?.value)
                }
              ></Select>
              {formik.touched.status && formik.errors.status && (
                <span className="error-text">{formik.errors.status}</span>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 pro-mb-4">
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Comment
              </label>
              <textarea
                id="comment"
                rows={6}
                name="comment"
                className={`pro-input lg ${
                  formik.touched.comment && formik.errors.comment && " error"
                }`}
                {...formik.getFieldProps("comment")}
              ></textarea>
              {formik.touched.comment && formik.errors.comment && (
                <span className="error-text">{formik.errors.comment}</span>
              )}
            </div>
          </div>
        </div>

          <div className={Style.followUp_list_wrap}>
            
            {singleFollowUpDetails?.map((data) => {
              return (
                <div className={Style.item}>
                  <div className={`${Style.list_item}`}>
                      <p className={`pro-font-sm pro-fw-medium pro-mb-0 ${Style.date}`}>{data?.date}</p>
                    <div className={`${Style.list_item_wrap} pro-px-5 pro-py-4`}>
                      <div className="pro-badge badge-success-outline">{data?.status}</div>{/* badge-success-outline || badge-warning-outline || badge-danger-outline */}
                      <p className="pro-font-sm pro-mb-0">{data?.note}</p>
                    </div> 
                  </div>
                  <span className={`${Style.line_wrap }`}></span>
                </div>
              );
            })}
            <div className={Style.followUpWrapper}></div>
          </div>
      </div>
    </div>
  );
};

export default AddFormFields;
