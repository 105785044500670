import { useFormik } from "formik";
import * as Yup from "yup";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Settings/faqsSlice";
import { saveDistrict } from "../../../../store/slices/Settings/stateSingleSlice";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const useAddDistrictFormContainer = (setShowform, refetch) => {
  const imageFileInputRef = useRef(null);

  const dispatch = useDispatch();
  const { statesID } = useParams();

  let validationSchema = Yup.object({
    districtName: Yup.string().required("Required"),
    // state: Yup.string().required("Required"),
  });

  const optionsWithIcons = [];

  const formik = useFormik({
    initialValues: {
      districtName: "",
      // state: "",
    },

    validationSchema: validationSchema,

    onSubmit: (values, { resetForm, setFieldError }) => {
      handleFormSubmit(values, resetForm, setFieldError);
    },
  });

  
  const handleFormSubmit = async (values, resetForm, setFieldError) => {
    try {
      try {
        let obj = {
          // diocestates,
          name: values.districtName,
          state_id: statesID,
        };

        dispatch(saveDistrict(obj))
          .unwrap()
          .then((res) => {
            if (res?.data?.status_code === 200) {
              refetch();
              setShowform(false);
              toast.success(res?.data?.message);
            }
            else{
              toast.error("Failed to Add District!");
            }
          });
      } catch (error) {}
    } catch (error) {
      toast.error("Failed to Add District!");
    }
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  return {
    formik,
    handleCloseModal,
    imageFileInputRef,
    optionsWithIcons,
  };
};

export default useAddDistrictFormContainer;
