import React from "react";
import useProfileSettings from "./useProfileSettings";
import SettingsForm from "./SettingsForm";

const ProfileSettings = () => {
  const {
    handleVerifyPhone,
    handleValidatePhone,
    handleContactUpdateClick,
    handleMenuOpen,
    handleMenuClose,
    handlePhoneNumberChange,
    hasBanPermission,
    hasDeletePermission,
    hasUpdateContactPermission,
    hasUpdatePasswordPermission,
    optionsData,
    otp,
    showPassword,
    overViewData,
    showStaffPassword,
    showConfirmPassword,
    validatePhoneStatus,
    handleShowStaffPassword,
    handleChangePasswordClick,
    handleBanUserClick,
    handleDeleteUserClick,
    handleShowPassword,
    handleShowConfirmPassword,
    settingsData,
    cuntryCodeOptions,
    menuIsOpen,
    phoneVerified,
    formik,
  } = useProfileSettings();
  return (
    <>
      <SettingsForm
        handleVerifyPhone={handleVerifyPhone}
        handleValidatePhone={handleValidatePhone}
        phoneVerified={phoneVerified}
        cuntryCodeOptions={cuntryCodeOptions}
        formik={formik}
        otp={otp}
        handlePhoneNumberChange={handlePhoneNumberChange}
        validatePhoneStatus={validatePhoneStatus}
        optionsData={optionsData}
        showConfirmPassword={showConfirmPassword}
        settingsData={settingsData}
        handleMenuOpen={handleMenuOpen}
        handleMenuClose={handleMenuClose}
        menuIsOpen={menuIsOpen}
        overViewData={overViewData}
        handleContactUpdateClick={handleContactUpdateClick}
        showPassword={showPassword}
        handleShowPassword={handleShowPassword}
        handleShowConfirmPassword={handleShowConfirmPassword}
        showStaffPassword={showStaffPassword}
        handleShowStaffPassword={handleShowStaffPassword}
        handleChangePasswordClick={handleChangePasswordClick}
        handleBanUserClick={handleBanUserClick}
        handleDeleteUserClick={handleDeleteUserClick}
        hasBanPermission={hasBanPermission}
        hasDeletePermission={hasDeletePermission}
        hasUpdateContactPermission={hasUpdateContactPermission}
        hasUpdatePasswordPermission={hasUpdatePasswordPermission}
      />
    </>
  );
};

export default ProfileSettings;
