import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useHighlightedProfiles from "./useHighlightedProfiles";
import EmptyData from "../../Global/EmptyData";
import DatePickerRsuite from "../../Global/CustomDateRangePicker";

const HighlightedProfiles = () => {
  const {
    highlightedProfilesList,
    highlightedProfiles,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    actionOptions,
    date,
    hasExportPermission,
    closeRemoveHightlightedModal,
    handleRemoveHighlighted,
    showDeleteConfirm,
    handleDateRangeChange,
    handleActionChange,
    refetch,
    handlePagination,
    handlePageSize,
    handleDateChange,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    updateHighlightedProfilesFields,
    handleClearClick,
  } = useHighlightedProfiles();

  return (
    <>
      <HeadingGroup title={"Highlighted Profiles"} className={`pro-mb-4`} />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <SearchFilters
          data={highlightedProfilesList?.data?.filters}
          //showDateRange
          dropDownFilter={
            <div className="col-auto">
              <DatePickerRsuite
                defaultDateRange={date}
                handleDateRangeChange={handleDateRangeChange}
              />
            </div>
          }
          onDateChange={handleDateChange}
          initialDateRange={date}
          onSearchInput={handleSearch}
          showActions={false}
          handleActionClick={handleEditClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          
          //showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: highlightedProfiles?.search }}
          actionOptions={actionOptions?.filter((item) =>
            hasExportPermission !== 1 ? item.value === 0 : true
          )}
          onActionOptionChange={handleActionChange}
        />
        {highlightedProfilesList?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            multiSelect={false}
            data={highlightedProfilesList?.data?.data || []}
            uniqueID={"_id"}
            fields={highlightedProfilesList?.data?.fields}
            showCheckBox={false}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={highlightedProfiles?.currentPageSize}
            assignable={false}
          />
        )}

        {highlightedProfilesList?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              highlightedProfilesList?.data?.total /
                highlightedProfiles.currentPageSize
            )}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === highlightedProfiles?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout
          centered={false}
          show={showDeleteConfirm}
          handleClose={closeRemoveHightlightedModal}
        >
          <div className="pro-m-3">
            <ConfirmationBox
              title={`Do you want to remove this profile from highlighted list?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={closeRemoveHightlightedModal}
              submitAction={handleRemoveHighlighted}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>

        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={highlightedProfilesList?.data?.fields}
              moduleId={highlightedProfilesList?.data?.module_id}
              updateData={updateHighlightedProfilesFields}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default HighlightedProfiles;
