import { getAxiosInstance } from "../../../api";

export const getSettingsData = async (id) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/admin/members/profile/settings?member_id=${id}`);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };

  export const updateContactDetails = async (obj) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("admin/members/profile/settings/contact/update", obj);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };

  export const updatePassword = async (obj) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("admin/members/profile/settings/password/update", obj);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };

  export const deleteMember = async (obj) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("admin/members/profile/settings/delete", obj);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };