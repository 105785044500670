import React from "react";
import "../../../assets/scss/date-rang-picker.scss";
import { DateRangePicker } from "rsuite";
import predefinedRanges from "./predefinedDates";

const CustomDateRangePicker = ({ defaultDateRange, handleDateRangeChange }) => {
  const customLocale = {
    sunday: "Su",
    monday: "Mo",
    tuesday: "Tu",
    wednesday: "We",
    thursday: "Th",
    friday: "Fr",
    saturday: "Sa",
    ok: "Apply",
    today: "Today",
    yesterday: "Yesterday",
    last7Days: "Last 7 Days",
    thisMonth: "This Month",
    lastMonth: "Last Month",
  };

  return (
    <DateRangePicker
      format="dd-MM-yyyy"
      ranges={predefinedRanges}
      character=" - "
      style={{ width: "100%" }}
      locale={customLocale}
      value={[defaultDateRange?.startDate, defaultDateRange?.endDate]}
      onChange={handleDateRangeChange}
    />
  );
};

export default CustomDateRangePicker;
