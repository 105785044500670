import { createSlice } from "@reduxjs/toolkit";
import { subDays } from "date-fns";


const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  startDate: subDays(new Date(), 30),
  endDate: new Date(),
  sortBy: "",
  sortOrder: "desc",
  search: "",
};

const reportedSlice = createSlice({
  name: "reported",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = reportedSlice.actions;

export default reportedSlice.reducer;
