import Style from './followUpOverviewShimmer.module.scss';


const FollowUpOverviewShimmer = () => {
  return (
    <div className='pro-mx-5'>
      <div className={`${Style.root} pro-py-5 pro-my-2 pro-d-flex pro-items-center pro-justify-between pro-gap-3`}>
        <div className={`${Style.title} pro-py-2 shimmer`}></div>
        <div className={`pro-py-2 ${Style.date} shimmer`}></div>
      </div>
      <div className={`${Style.root} pro-py-5 pro-my-2 pro-d-flex pro-items-center pro-justify-between pro-gap-3`}>
        <div className={`${Style.title} pro-py-2 shimmer`}></div>
        <div className={`pro-py-2 ${Style.date} shimmer`}></div>
      </div>
      <div className={`${Style.root} pro-py-5 pro-my-2 pro-d-flex pro-items-center pro-justify-between pro-gap-3`}>
        <div className={`${Style.title} pro-py-2 shimmer`}></div>
        <div className={`pro-py-2 ${Style.date} shimmer`}></div>
      </div>
      <div className={`${Style.root} pro-py-5 pro-my-2 pro-d-flex pro-items-center pro-justify-between pro-gap-3`}>
        <div className={`${Style.title} pro-py-2 shimmer`}></div>
        <div className={`pro-py-2 ${Style.date} shimmer`}></div>
      </div>
    </div>
  )
}

export default FollowUpOverviewShimmer;