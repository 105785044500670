import { Button } from "@wac-ui-dashboard/wac_component_library";
import InputShimmer from "../../../Global/Shimmers/InputShimmer";
import useAboutMyselfForm from "./useAboutMyselfForm";
import style from "./aboutMyselfForm.scss";

const AboutMyselfForm = ({
  handleCancel,
  userId,
  isFetching,
  data,
  profileData,
  refetch,
}) => {
  const { formik } = useAboutMyselfForm({
    handleCancel,
    userId,
    data,
    profileData,
    refetch,
  });

  return (
    <div className={`pro-p-5 pro-w-100`}>
      <div className={style.heading}>
        <h4 className="pro-ttl h4 pro-mb-2">About Myself</h4>
      </div>

      {!isFetching ? (
        <>
          <textarea
            id="about_me"
            rows={6}
            name="about_me"
            className={`pro-input lg ${
              formik.touched.about_me && formik.errors.about_me && " error"
            }`}
            {...formik.getFieldProps("about_me")}
          ></textarea>

          {formik.touched.about_me && formik.errors.about_me && (
            <span className="error-text">{formik.errors.about_me}</span>
          )}

          <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
            <Button
              className={"pro-btn-link lg pro-px-5"}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>

            <Button
              className={"pro-btn-primary lg pro-mx-3"}
              onClick={formik.handleSubmit}
              type="submit"
            >
              Update
            </Button>
          </div>
        </>
      ) : (
        [...Array(1)].map((_, i) => <InputShimmer key={i} />)
      )}
    </div>
  );
};

export default AboutMyselfForm;
