import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFormData } from "../../../../../store/slices/Users/createMemberSlice";
import { updateConfig } from "../../../../../store/slices/Users/membersSlice";
import { toast } from "react-toastify";
import { profile, useUpdateProfileDetailsMutation } from "../../../../../store/queries/Profile";
import { members } from "../../../../../store/queries/members";

const useEducation = ({ isOptionDataSuccess, isEditProfile,profileRefetch }) => {
  const educationRef = useRef(" ");

  const { currentFormData,backendErrors } = useSelector(
    (state) => state.createMembers
  );

  const dispatch = useDispatch();

  let createMemberVal = Yup.object({
     education_detail: Yup.string().max(30, "max 30 characters allowed"),
    // education: Yup.object().required("*Required"),
    // education_category: Yup.object().required("*Required"),
     institution_name: Yup.string().max(60, "max 60 characters allowed")
  });
  const [sendRequest,{isLoading}] = useUpdateProfileDetailsMutation();

  let editRequiredData = {
    user_id: currentFormData?.user_id,
    first_name: currentFormData?.first_name,
    last_name: currentFormData?.last_name,
    email: currentFormData?.email,
    country_code: currentFormData?.country_code,
    phone_number: currentFormData?.phone_number,
    gender: currentFormData?.gender?.id,
    dob: currentFormData?.dob,
    community_id: currentFormData?.community_id?._id,
    diocese_id: currentFormData?.diocese_id?._id,
  };

  const formik = useFormik({
    initialValues: {
      education: "",
      education_category: "",
      education_detail:"",
      institution_name: "",
    },

    validationSchema: !isEditProfile ? createMemberVal : "",
    enableReinitialize: true,

    onSubmit: (values, { resetForm }) => {
      try {
        let obj = {
          ...values
        };

        let editObj = {
          education_category: values?.education_category?._id,
          education: values?.education?._id,
          education_detail:values.education_detail,
          institution_name: values?.institution_name,
          type:3
        };

        let newObj = Object.entries(editObj);

        newObj = newObj
          .filter(
            (item) =>
              item[1] !== undefined && item[1] !== "" && item[1] !== null
          )
          .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
        if (isEditProfile) {
          let formData = new FormData();
          formData.append("_method", "PUT");

          Object.keys(newObj).forEach((key) => {
            return formData.append(key, newObj[key]);
          });
          Object.keys(editRequiredData).forEach((key) => {
            return formData.append(key, editRequiredData[key]);
          });

          sendRequest(formData).then((response) => {
            dispatch(
              updateFormData((state) => {
                state.backendErrors = "";
              })
            );

            if (response?.data?.status_code === 200) {
              resetForm();

              dispatch(
                updateFormData((state) => {
                  state.currentFormData = {};
                  state.isEditProfile = false;
                  state.activeTab = "Basic";
                  state.completedTabs = {
                    ...state.completedTabs,
                    Basic: false,
                    Family: false,
                    Job: false,
                    Edu: false,
                    Contact: false,
                  };
                  state.CompletedPercentage = {
                    ...state.CompletedPercentage,
                    Basic: 0,
                    Family: 0,
                    Job: 0,
                    Edu: 0,
                    Contact: 0,
                  };
                })
              );

              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                  state.clearSelection = true;
                })
              );
              dispatch(members.util.invalidateTags(["Members", "Incomplete"]));
              dispatch(profile.util.invalidateTags(["Overview"]));

              toast.success("Successfully submitted");
            } else if (response?.data?.status_code === 400) {
              let message = Object.values(response?.data?.message)?.[0]?.[0];

              // formik.setErrors(response?.data?.message);
              toast.error(`Failed to  update, ${message}`);
              dispatch(
                updateFormData((state) => {
                  // formik.setErrors(response?.data?.message);
                   state.backendErrors = response?.data?.message;
                })
              );
            }
            else{
              toast.error("Failed to  update");
            }
          });
        } else {
          dispatch(
            updateFormData((state) => {
              state.currentFormData = { ...state.currentFormData, ...obj };
              state.activeTab = "Job";
              state.completedTabs = { ...state.completedTabs, Edu: true };
            })
          );
        }
      } catch (error) {
        toast.error("Failed to  submit");
      }
    },
  });

  useEffect(() => {
    if (isOptionDataSuccess) {
      Object.keys(currentFormData || {}).forEach((key) => {
        formik.setFieldValue(key, currentFormData?.[key]);
      });
      let filteredTouchedData = Object.entries(currentFormData || {});

      filteredTouchedData = filteredTouchedData
        .filter(
          (item) => item[1] === undefined || item[1] === "" || item[1] === null
        )
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});

      Object.keys(filteredTouchedData || {}).forEach((key) => {
        formik.setFieldTouched(key, true);
      });
    }
    // eslint-disable-next-line
  }, [isOptionDataSuccess, currentFormData]);

  useEffect(() => {
    if (backendErrors) {
      formik.setErrors(backendErrors);
    }
     // eslint-disable-next-line
  }, [backendErrors]);


  useEffect(() => {
    let totalFields = Object.values(formik.initialValues).length;

    const filterDataFromTab=(fullObject, tabObject)=> {
      const filteredData = {};
      
      for (const key in tabObject) {
        if (fullObject.hasOwnProperty(key)) {
          filteredData[key] = fullObject[key];
        }
      }
      
      return filteredData;
    }
  
    const filteredData = filterDataFromTab(formik.values
      , formik.initialValues);
  
  const countNonEmptyKeys=(data)=> {
    let count = 0;
    for (let key in data) {
      if (data.hasOwnProperty(key) && data[key] !== null && data[key] !== "") {
        count++;
      }
    }
    return count;
  }
  
  let numberOfNonEmptyKeys = countNonEmptyKeys(filteredData);
  
    
    

    dispatch(
      updateFormData((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Edu: Math.ceil(
            (numberOfNonEmptyKeys / totalFields) * 100
            ),
        };
      })
    );
    // eslint-disable-next-line
  }, [formik.values]);

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
    dispatch(
      updateFormData((state) => {
        state.currentFormData = {};
        state.activeTab = "Basic";
        state.isEditProfile = false;
        state.backendErrors = "";
        state.phoneVerified = {
          verified: false,
          status: false,
        };
        state.completedTabs = {
          ...state.completedTabs,
          Basic: false,
          Family: false,
          Job: false,
          Edu: false,
          Contact: false,
        };
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Basic: 0,
          Family: 0,
          Job: 0,
          Edu: 0,
          Contact: 0,
        };
      })
    );
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }

    return "";
  };

  // const getFieldError = (fieldName) => {
  //   if (backendErrors && backendErrors[fieldName]) {
  //     return backendErrors[fieldName];
  //   }
  //   if (formik.touched[fieldName] && formik.errors[fieldName]) {
  //     return formik.errors[fieldName];
  //   }
  //   return "";
  // };

  return {
    educationRef,
    formik,
    isLoading,
    currentFormData,
    handleCloseModal,
    getFieldError,
  };
};

export default useEducation;
