import React from "react";
import Style from "./deleteModal.module.scss";
import {
  Button,
  HeadingGroup,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";
import useDeleteModal from "./useDeleteModal";

const DeleteModal = ({ block = false, selectedUser, handleClose }) => {
  const { formik, optionsData, handleShowStaffPassword, showStaffPassword } =
    useDeleteModal({
      block,
      selectedUser,
      handleClose,
    });

  return (
    <div className="row">
      <HeadingGroup
        title={"Delete User"}
        extraClassName={`pro-mb-4 ${Style.heading}`}
      />
      <div className="col-12">
        <div className={"input-wrap pro-mb-5"}>
          <label
            htmlFor="current_location"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Reason
          </label>
          <Select
            id="delete_reason_id"
            isClearable={true}
            menuPlacement={"auto"}
            placeholder={"Select"}
            className={`pro-input lg  ${
              formik.errors.staff_password &&
              formik.touched.staff_password &&
              " error"
            }`}
            classNamePrefix="pro-input"
            options={optionsData?.account_delete_reason}
            getOptionValue={(option) => option?._id}
            getOptionLabel={(option) => option?.name}
            value={optionsData?.account_delete_reason?.filter(
              (m) => formik.values.delete_reason_id?._id === m?._id
            )}
            onChange={(value) => {
              formik.setFieldValue("delete_reason_id", value || null);
            }}
          />
          {formik.errors.delete_reason_id &&
            formik.touched.delete_reason_id && (
              <span className="error-text">
                {formik.errors.delete_reason_id}
              </span>
            )}
        </div>
      </div>

      <div className="col-12">
        <div className="p-re">
          <Input
            label={"Password"}
            type="password"
            id="staff_password"
            autoComplete="new-password"
            name="staff_password"
            className={`pro-input lg ${
              formik.errors.staff_password &&
              formik.touched.staff_password &&
              " error"
            } hide-icon-input`}
            {...formik.getFieldProps("staff_password")}
            error={
              formik.errors.staff_password && formik.touched.staff_password
            }
            errorMessage={formik.errors.staff_password}
          />
          {showStaffPassword ? (
            <button
              className={`hide-icon`}
              onClick={(e) => handleShowStaffPassword(e)}
            >
              <span className="material-symbols-outlined">
                {" "}
                visibility_off{" "}
              </span>
            </button>
          ) : (
            <button
              className={`hide-icon`}
              onClick={(e) => handleShowStaffPassword(e)}
            >
              <span className="material-symbols-outlined"> visibility </span>
            </button>
          )}
        </div>
      </div>
      <div className={`pro-d-flex  col-6`}>
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleClose()}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-mx-3"}
          onClick={formik.handleSubmit}
          type="submit"
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default DeleteModal;
