import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { updateVerificationDataStatus } from "../../../../store/slices/Users/verificationSlice";

const useDocumentCard = ({
  data,
  activeID,
  refetch,
  setImageData,
  setViewId,
  setShowDocumentVerificationModal,
}) => {
  const dispatch = useDispatch();
  const [showReasonSelect, setShowReasonSelect] = useState(false);
  const [activeDocumentIndex, setActiveDocumentIndex] = useState(0);
  const [activeReason, setActiveReason] = useState([{}]);
  const [reasonError, setReasonError] = useState(false);

  const [checked, setChecked] = useState({ index: null, checked: false });
  const reasons = useMemo(() => {
    return data.profileRejectResponse.map((r) => {
      return { label: r.reason, value: r._id };
    });
  }, [data]);

  const DocumentType = [
    { name: "Aadhar Card", type: 1 },
    { name: "Driving License", type: 2 },
    { name: "PAN Card", type: 3 },
    { name: "Passport", type: 4 },
    { name: "SSLC", type: 5 },
    { name: "VotersID", type: 6 },
  ];

  useEffect(() => {
    data.profileData[activeID] &&
      // eslint-disable-next-line
      data.profileData[activeID].map((profile, index) => {
        profile?.status === null
          ? setChecked((state) => ({
              ...state,
              [index.toString()]: {
                index: index,
                checked: false,
              },
            }))
          : setChecked((state) => ({
              ...state,
              [index.toString()]: {
                index: index,
                checked: true,
              },
            }));
      });

    // eslint-disable-next-line
  }, [data]);

  const handleReject = () => {
    setShowReasonSelect(true);
  };

  const cancelRejectClick = () => {
    setShowReasonSelect(false);
  };

  const handleThumbClick = (index) => {
    setActiveDocumentIndex(index);

    setChecked((state) => ({
      ...state,
      [index.toString()]: { checked: !state[index.toString()].checked },
    }));
  };

  const handleReasonChange = (reason) => {
    setReasonError(false);
    setActiveReason(reason);
  };

  const handleApprove = () => {
    dispatch(
      updateVerificationDataStatus({
        user_id: activeID,
        upload_id: data.profileData[activeID][activeDocumentIndex]._id,
        upload_type: 2,
        status: 1,
      })
    )
      .unwrap()
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setShowDocumentVerificationModal(false);
          refetch?.();
        }
      });
  };

  const handleRejectSubmit = () => {
    if (activeReason?.value) {
      dispatch(
        updateVerificationDataStatus({
          user_id: activeID,
          upload_id: data.profileData[activeID][activeDocumentIndex]._id,
          reason: activeReason.value,
          upload_type: 2,
          status: 2,
        })
      );
    } else {
      setReasonError(true);
    }
  };

  const handleViewIdProofModal = (url) => {
    setShowDocumentVerificationModal(() => false);
    setImageData(url);
    setViewId(() => true);
  };

  return {
    showReasonSelect,
    activeDocumentIndex,
    activeReason,
    reasons,
    reasonError,
    checked,
    DocumentType,
    handleViewIdProofModal,
    handleReject,
    cancelRejectClick,
    handleThumbClick,
    handleReasonChange,
    handleApprove,
    handleRejectSubmit,
  };
};

export default useDocumentCard;
