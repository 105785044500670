import { getAxiosInstance } from "../../../api";

export const getPackageCreationData = async () => {
  const api = await getAxiosInstance();

  try {
    const response = await api.get("admin/packages/all");

    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getBasicData = async () => {
  const api = await getAxiosInstance();

  try {
    const response = await api.get("admin/packages/data");

    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const createPackage = async (formData) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("admin/packages/create", formData);

    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const createAddon = async (formData) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("admin/add-on/create", formData);

    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const UpdateAddon = async (formData) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("/admin/add-on/update", formData);

    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const updatePackage = async (formData) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("admin/packages/update", formData);

    return response;
  } catch (error) {
    return error.response.data;
  }
};
