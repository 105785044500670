import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { subDays } from "date-fns";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "PURCHASED",
  startDate: subDays(new Date(), 30),
  endDate: new Date(),
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  showInvoiceModal: false,
  transactionId: "",
  selectedUserId: "",
  userData: [],
  invoiceLoading: false,
  branch: "",
  payment: "",
  IDs: {},
  statusModal: false,
  is_edit: false,
  selectedItemsDetails: "",
  clearSelection: false
};
export const getTransactionDetails = createAsyncThunk(
  "/admin/payments/edit",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/admin/payments/edit?payment_transaction_id=${params}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    
    builder.addCase(getTransactionDetails.pending, (state) => {
       state.isLoading = true;
       state.error = null;
     })
     .addCase(getTransactionDetails.fulfilled, (state, action) => {
       state.selectedItemsDetails = action.payload.data.data;
     })
     .addCase(getTransactionDetails.rejected, (state, action) => {
       state.isLoading = false;
       state.error = action.payload;
     });
     
   },
});

export const { updateConfig } = transactionsSlice.actions;

export default transactionsSlice.reducer;
