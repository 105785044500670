import React from "react";
import ProfileLayout from "../Layouts/ProfileLayout";
import { Outlet } from "react-router-dom";
import useProfile from "./useProfile";

const Profile = () => {
  const { navigation } = useProfile();
  return (
    <>
      <ProfileLayout navigation={navigation}>
        <Outlet />
      </ProfileLayout>
    </>
  );
};

export default Profile;
