import { useFormik } from "formik";
import * as Yup from "yup";
import { useMemo, useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateConfig as updateConfigPackage } from "../../../store/slices/Settings/packageSlice";
import { updateConfig as updateConfigAdon } from "../../../store/slices/Settings/adonsSlice";
import {
  getPackageCreationData,
  createPackage,
  updatePackage,
  createAddon,
  getBasicData,
  UpdateAddon,
} from "./api";
import { useSelector } from "react-redux";

const usePackageCreationForm = (refetch) => {
  const [formData, setFormData] = useState([]);
  const [rules, setRules] = useState([]);

  const packageState = useSelector((state) => state.package);
  const adonsState = useSelector((state) => state.adons);

  const [imagePreview, setImagePreview] = useState(
    packageState?.selectedItemsDetails !== ""
      ? packageState?.selectedItemsDetails?.photo
      : ""
  );

  const [fieldsSetCount, setFieldsSetCount] = useState([
    {
      OptionOne: "",
      OptionTwo: "",
    },
  ]);

  useEffect(() => {
    var data = [];
    if (packageState.selectedItemsDetails !== null) {
      packageState?.selectedItemsDetails?.rules?.map((item) => {
        if (packageState?.selectedItemsDetails !== null) {
          var temp = packageState?.rules?.filter((rule) => {
            return rule.id === item.id;
          });

          data.push({
            OptionOne: Array.isArray(temp)
              ? {
                  id: temp?.[0]?.id,
                  value: temp?.[0]?.value?.toUpperCase(),
                  label: temp?.[0]?.value,
                  type: temp?.[0]?.type,
                }
              : [],
            OptionTwo:
              temp?.[0]?.type === "boolean"
                ? item?.value === 1
                  ? { id: 1, value: 1, label: "Yes" }
                  : { id: 2, value: 0, label: "No" }
                : item.value,
          });
        }
        return item;
      });
    }

    if (adonsState.selectedItemsDetails !== null) {
      adonsState?.selectedItemsDetails?.rules?.map((item) => {
        if (adonsState?.selectedItemsDetails !== null) {
          var temp = adonsState?.rules?.filter((rule) => {
            return rule.id === item.id;
          });

          data.push({
            OptionOne: Array.isArray(temp)
              ? {
                  id: temp?.[0]?.id,
                  value: temp?.[0]?.value?.toUpperCase(),
                  label: temp?.[0]?.value,
                  type: temp?.[0]?.type,
                }
              : [],
            OptionTwo:
              temp?.[0]?.type === "boolean"
                ? item?.value === 1
                  ? { id: 1, value: 1, label: "Yes" }
                  : { id: 2, value: 0, label: "No" }
                : item.value,
          });
        }
        return item;
      });
    }

    if (data.length > 0) {
      setFieldsSetCount(data);
    }
    // return () => {
    //   dispatch(
    //     updateConfig((state) => {
    //       state.selectedItemsDetails = null;
    //     })
    //   );
    // };
    // eslint-disable-next-line
  }, [
    packageState.selectedItemsDetails,
    packageState.rules,
    adonsState.selectedItemsDetails,
    adonsState.rules,
  ]);

  useEffect(() => {
    getPackageCreationData().then((response) => {
      setFormData(response.data);
    });

    getBasicData()
      .then((result) => {
        const rulesArray = result.data.data.rules?.map((option) => {
          let id = option.id;
          let value = option.value;
          let type = option.type;

          // Perform operations with the object properties or return a transformed object
          return {
            id: id,
            value: value.toUpperCase(),
            label: value,
            type: type,
          };
        });

        dispatch(
          updateConfigPackage((state) => {
            state.rules = result.data.data.rules;
          })
        );
        dispatch(
          updateConfigAdon((state) => {
            state.rules = result.data.data.rules;
          })
        );
        setRules(rulesArray);
      })
      .catch((err) => {});
    // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);

  const imageFileInputRef = useRef(null);

  const dispatch = useDispatch();

  let createPackageVal = Yup.object({
    name: Yup.string().required("*Required"),
    price: Yup.number()
      .typeError("*Required")
      .positive("Price must be a positive number")
      .required("*Required")
      .test("is-valid-price", "Invalid price format", (value) =>
        /^\d+(\.\d{1,2})?$/.test(String(value))
      ),
    duration: Yup.number()
      .typeError("*Required")
      .positive("Duration must be a positive number")
      .required("*Required"),
    offer_price: Yup.string().test(
      "offerPrice",
      "*Offer price cannot be greater than price",
      function (value) {
        const { price } = this.parent;
        if (value && price) {
          return parseFloat(value) <= parseFloat(price);
        }
        return true;
      }
    ),

    image: Yup.mixed().test("fileType", "Invalid file type", function (value) {
      if (!value || !value.name) {
        // If value is not provided or does not have a name (no file selected), the validation passes
        return true;
      }
      // Get the file extension
      const fileExtension = value.name.split(".").pop().toLowerCase();
      // Define allowed file types
      const allowedFileTypes = ["jpg", "jpeg", "png", "gif"];
      // Check if the file extension is in the allowed types
      return allowedFileTypes.includes(fileExtension);
    }),
    // isChecked:Yup.string().required("*Required"),
    // description: Yup.string().required("*Required"),
    // image: Yup.string().required("*Required"),
    rules: Yup.array().required("At least one row is required"),
  });

  let createAddonsVal = Yup.object({
    name: Yup.string().required("*Required"),
    price: Yup.number()
      .typeError("*Required")
      .positive("Price must be a positive number")
      .required("*Required")
      .test("is-valid-price", "Invalid price format", (value) =>
        /^\d+(\.\d{1,2})?$/.test(String(value))
      ),
    offer_price: Yup.string().test(
      "offerPrice",
      "*Offer price cannot be greater than price",
      function (value) {
        const { price } = this.parent;
        if (value && price) {
          return parseFloat(value) <= parseFloat(price);
        }
        return true;
      }
    ),

    image: Yup.mixed().test("fileType", "Invalid file type", function (value) {
      if (!value || !value.name) {
        // If value is not provided or does not have a name (no file selected), the validation passes
        return true;
      }
      // Get the file extension
      const fileExtension = value.name.split(".").pop().toLowerCase();
      // Define allowed file types
      const allowedFileTypes = ["jpg", "jpeg", "png"];
      // Check if the file extension is in the allowed types
      return allowedFileTypes.includes(fileExtension);
    }),
    // isChecked:Yup.string().required("*Required"),
    // description: Yup.string().required("*Required"),
    // image: Yup.string().required("*Required"),
    rules: Yup.array().required("At least one row is required"),
  });

  const formik = useFormik({
    initialValues: {
      name:
        packageState.selectedItemsDetails != null
          ? packageState.selectedItemsDetails.name
          : adonsState?.selectedItemsDetails != null
          ? adonsState?.selectedItemsDetails?.name
          : "",
      price:
        packageState.selectedItemsDetails != null
          ? packageState.selectedItemsDetails.price
          : adonsState?.selectedItemsDetails != null
          ? adonsState?.selectedItemsDetails?.price
          : "",
      duration:
        packageState.selectedItemsDetails != null
          ? packageState.selectedItemsDetails.duration
          : adonsState?.selectedItemsDetails != null
          ? adonsState?.selectedItemsDetails?.duration
          : "",
      offer_price:
        packageState.selectedItemsDetails != null
          ? packageState.selectedItemsDetails.offer_price
          : adonsState?.selectedItemsDetails != null
          ? adonsState?.selectedItemsDetails?.offer_price
          : "",
      isChecked:
        packageState.selectedItemsDetails != null
          ? packageState.selectedItemsDetails.is_recommended === 0
            ? false
            : true
          : adonsState?.selectedItemsDetails != null
          ? adonsState?.selectedItemsDetails.is_recommended === 0
            ? false
            : true
          : "",

      description:
        packageState.selectedItemsDetails != null
          ? packageState.selectedItemsDetails.description
          : adonsState?.selectedItemsDetails != null
          ? adonsState?.selectedItemsDetails?.description
          : "",
      image: "",
      rules: null,
    },

    validationSchema:
      packageState?.formType === "package" ? createPackageVal : createAddonsVal,
    enableReinitialize: true,
    onSubmit: (values, { resetForm, setFieldError }) => {
      try {
        let obj = {
          name: values.name,
          thumbnail: values.image,
          price: values.price,
          duration: values.duration,
          description: values.description,
          rules: values.rules,
        };

        if (packageState.formType === "package") {
          obj.offer_price = values.offer_price;
          obj.is_recommended = values.isChecked ? 1 : 0;
        }
        if (adonsState.formType === "addon") {
          obj.offer_price = values.offer_price;
          obj.is_recommended = values.isChecked ? 1 : 0;
        }

        if (
          packageState.selectedItemsDetails != null &&
          packageState.formType === "package"
        ) {
          obj.package_id = packageState?.selectedItemsDetails?._id;
        }

        if (
          adonsState.selectedItemsDetails != null &&
          adonsState.formType === "addon"
        ) {
          obj.add_on_id = adonsState?.selectedItemsDetails?._id;
        }

        let formData = new FormData();

        Object.keys(obj).forEach((key) => {
          if (key === "rules") {
            obj[key]?.map((item, index) => {
              formData.append(`rules[${index}][id]`, item.id);
              formData.append(`rules[${index}][value]`, item.value);
              return item;
            });
          } else {
            if (obj[key] !== "" && obj[key] !== undefined) {
              formData.append(key, obj[key]);
            }
          }
        });

        if (packageState.selectedItemsDetails !== null) {
          formData.append("_method", "PUT");
        }

        (packageState.formType === "package"
          ? packageState.selectedItemsDetails === null
            ? createPackage(formData)
            : updatePackage(formData)
          : adonsState.selectedItemsDetails === null
          ? createAddon(formData)
          : UpdateAddon(formData)
        ) // update addon
          .then((response) => {
            if (response?.data?.status_code === 200) {
              submitForm(resetForm);
              if (packageState.formType === "package") {
                dispatch(
                  updateConfigPackage((state) => {
                    state.clearSelection = true;
                  })
                );
              }
              else{
                dispatch(
                  updateConfigAdon((state) => {
                    state.clearSelection = true;
                  })
                );
              }
              refetch();
            } else if (response?.status_code === 400) {
              const message = response.message;
              packageState.formType === "package"
                ? toast.error("Failed to Create Package")
                : toast.error("Failed to Create Addon");
              // Loop through the error messages
              for (const fieldName in message) {
                if (message.hasOwnProperty(fieldName)) {
                  const errorMessages = message[fieldName];

                  // Check if the error messages is an array
                  if (Array.isArray(errorMessages)) {
                    // Loop through the error message array
                    errorMessages.forEach((errorMessage) => {
                      // Set the error message using setFieldError("Field name", "Message")
                      setFieldError(fieldName, errorMessage);
                    });
                  }
                }
              }
            } else {
              if (packageState.formType === "package") {
                packageState.selectedItemsDetails === null
                  ? toast.error("Failed to Create Package")
                  : toast.error("Failed to Update Package");
              } else {
                adonsState.selectedItemsDetails === null
                  ? toast.error("Failed to Create Addon")
                  : toast.error("Failed to Update Addon");
              }
            }
          });
      } catch (error) {}
    },
  });

  const submitForm = (resetForm) => {
    resetForm();
    setLoading(false);

    dispatch(
      updateConfigAdon((state) => {
        state.showCreateModal = false;
      })
    );
    dispatch(
      updateConfigPackage((state) => {
        state.showCreateModal = false;
      })
    );

    toast.success("Successfully submitted ", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const ruleOptions = useMemo(
    () =>
      formData?.data?.map((opt) => {
        return { value: opt.name, label: opt.name, id: opt._id };
      }),
    [formData]
  );

  const handleCloseModal = () => {
    dispatch(
      updateConfigAdon((state) => {
        state.showCreateModal = false;
      })
    );
    dispatch(
      updateConfigPackage((state) => {
        state.showCreateModal = false;
      })
    );
  };
  const handlePackageImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik.setFieldValue("image", e?.target?.files?.[0]);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setImagePreview(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
      formik.setFieldError("image", "The image must be less than 5MB in size.");
    }
  };

  return {
    rules,
    setRules,
    ruleOptions,
    formik,
    loading,
    imagePreview,
    handlePackageImage,
    handleCloseModal,
    imageFileInputRef,
    fieldsSetCount,
    setFieldsSetCount,
    packageState,
    adonsState,
  };
};

export default usePackageCreationForm;
